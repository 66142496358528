import * as Yup from 'yup';

const ignoreColumns = ['id', 'createdBy', 'createdDate', 'active'];

// Validation: ensures no fields can be empty.
// Note: does not ensure that no fields can be empty.
const validation = Yup.lazy((values) => {
  // Validation: go through every row.
  // If it's a new row, all fields (other than ignore) must be filled in.
  // If it's a modified row, fields cannot go from having a value to lacking a value.

  const everyColumnName = values.definitions.reduce(
    (paths, table, tableIndex) => {
      const initialTable = values.initialState.definitions[tableIndex];
      return paths.concat(
        table.rows?.reduce((rowPaths, row, rowIndex) => {
          // rowPaths.concat(
          //   Object.keys(row).filter(
          //     (columnName) =>
          //       !ignoreColumns.includes(columnName) &&
          //       !rowPaths.includes(columnName),
          //   ),
          // ),

          const initialRow = initialTable.rows[rowIndex] || {};
          const isNewRow = rowIndex >= initialTable.rows.length;

          return rowPaths.concat(
            Object.entries(table.header)
              .filter(([columnName, type]) => {
                const value = row[columnName];
                const initialValue = initialRow[columnName];
                // Checkboxes are always considered defined
                const wasDefined =
                  !isNewRow && initialValue != null && initialValue !== '';

                const isDefined = value != null && value !== '';

                return (
                  !ignoreColumns.includes(columnName) &&
                  type !== 'BOOLEAN' &&
                  (isNewRow ? !isDefined : wasDefined && !isDefined)
                );
              })
              .map(
                (columnName) =>
                  `definitions[${tableIndex}].rows[${rowIndex}].${columnName}`,
              ),
          );
        }, []) || [],
      );
    },
    [],
  );

  return Yup.object().shape(
    everyColumnName.reduce(
      (schemaProps, path) => ({
        ...schemaProps,
        [path]: Yup.mixed().test(path, 'required', () => false),
      }),
      {},
    ),
  );
});

export default validation;
