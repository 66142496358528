import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ReadOnlyTable from 'src/components/ReadOnlyTable';

function CaseSearchTable({ caseItems, handleClose }) {
  const navigate = useNavigate();

  return (
    <ReadOnlyTable
      clickable
      onClick={(rowDetail) => {
        handleClose();
        navigate(`/case/${rowDetail.id}/referral/summary`);
      }}
      data={caseItems}
      tableInformation={[
        {
          name: 'Donor ID',
          fieldName: 'donorId',
          fieldType: 'text',
        },
        {
          name: 'Last Name',
          fieldName: 'donorLastName',
          fieldType: 'text',
        },
        {
          name: 'First Name',
          fieldName: 'donorFirstName',
          fieldType: 'text',
        },
        {
          name: 'DOB',
          fieldName: 'donorDateOfBirth',
          fieldType: 'text',
        },
        {
          name: 'Referring Org',
          fieldName: 'referringOrg',
          fieldType: 'text',
        },
        {
          name: 'Date of Death',
          fieldName: 'donorDateOfDeath',
          fieldType: 'text',
        },
        {
          name: 'Referral Taken By',
          fieldName: 'referralTakenBy',
          fieldType: 'text',
        },
        {
          name: 'Referral Date',
          fieldName: 'referralDate',
          fieldType: 'text',
        },
        {
          name: 'Desig',
          fieldName: 'donorDesignation',
          fieldType: 'text',
        },
        {
          name: 'Case Status',
          fieldName: 'caseStatus',
          fieldType: 'text',
        },
      ]}
    />
  );
}

CaseSearchTable.propTypes = {
  caseItems: PropTypes.array,
  handleClose: PropTypes.func,
};

CaseSearchTable.defaultProps = {
  caseItems: [],
};

export default CaseSearchTable;
