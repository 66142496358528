export const isIdentical = (() => {
  const getType = (value) => {
    const type = typeof value;
    if (type === 'number') return 'number';
    if (type === 'string') return 'string';
    if (type === 'function') return 'function';
    if (value instanceof Array) return 'array';
    if (value instanceof Object) return 'object';
    if (value === null) return 'null';
    return '';
  };
  return (base, compare) => {
    if (base === compare) return true;
    const type = getType(base);
    if (type !== getType(compare)) {
      return false;
    }
    if (type === 'array') {
      if (base.length !== compare.length) {
        return false;
      }
    }
    if (type === 'object' || type === 'array') {
      const keys = Object.keys(base);
      for (let i = keys.length - 1; i >= 0; i--) {
        if (!isIdentical(base[keys[i]], compare[keys[i]])) return false;
      }
      return true;
    }
    return false;
  };
})();

export const stripNull = (o) =>
  o instanceof Object
    ? Object.entries(o).reduce(
        (result, [key, value]) =>
          value != null ? { ...result, [key]: value } : result,
        {},
      )
    : o;

export const clone = (value) => {
  if (value instanceof Array) {
    return value.map(clone);
  }
  if (value instanceof Object) {
    return Object.entries(value).reduce((o, [prop, val]) => {
      o[prop] = clone(val); // eslint-disable-line no-param-reassign
      return o;
    }, {});
  }
  return value;
};

export const getPropInObject = (object, ...props) => {
  if (!props.length) return object;
  return props.reduce((innerObject, p) => innerObject[p], object);
};

export const deepApply = (object, ...props) => {
  if (!props.length) return object;
  if (props.length === 1) {
    return props[0];
  }
  const newObject = clone(object);
  if (props.length === 2) {
    const [prop, value] = props;
    if (newObject[prop] === value) {
      return object;
    }
    newObject[prop] = value;
    return newObject;
  }

  const lastProp = props[props.length - 2];
  const newValue = props[props.length - 1];

  const innermostObject = props
    .slice(0, -2)
    .reduce((innerObject, p) => innerObject[p], newObject);
  if (innermostObject[lastProp] !== newValue) {
    innermostObject[lastProp] = newValue;
    return newObject;
  }
  return object;
};

export const removeFromArray = (array, ...items) =>
  items.reduce((currentArray, item) => {
    const newArray = [...currentArray];
    const index = newArray.indexOf(item);
    if (index === -1) return newArray;
    newArray.splice(index, 1);
    return newArray;
  }, array);

export const toggleItemInArray = (array, item) => {
  return array.includes(item) ? removeFromArray(array, item) : [item, ...array];
};

export const addItemToArray = (array, item) => {
  if (array.includes(item)) {
    removeFromArray(array, item);
  }
  return [item, ...array];
};
