import * as Yup from 'yup';
import { REQUIRED_FIELD } from 'src/constants';

type FieldVersionsType = {
  formVersion: number;
};
export const eyeOnlyDraiLe12Schema = (fieldVersions: FieldVersionsType) => {
  const tbQuestions =
    fieldVersions.formVersion > 2
      ? {
          eyeDraiLe12q29: Yup.boolean().nullable().required(REQUIRED_FIELD),
          eyeDraiLe12q29a: Yup.string()
            .nullable()
            .when('eyeDraiLe12q29', {
              is: true,
              then: Yup.string().nullable().required(REQUIRED_FIELD),
            }),
          eyeDraiLe12q29b: Yup.boolean()
            .nullable()
            .when('eyeDraiLe12q29', {
              is: true,
              then: Yup.boolean().nullable().required(REQUIRED_FIELD),
            }),
          eyeDraiLe12q29bi: Yup.string()
            .nullable()
            .when('eyeDraiLe12q29b', {
              is: true,
              then: Yup.string().nullable().required(REQUIRED_FIELD),
            }),
          eyeDraiLe12q29bii: Yup.string()
            .nullable()
            .when('eyeDraiLe12q29b', {
              is: true,
              then: Yup.string().nullable().required(REQUIRED_FIELD),
            }),
        }
      : undefined;
  return Yup.object().shape({
    eOLe12Questions: Yup.object()
      .nullable()
      .when('signatureBool', {
        is: true,
        then: Yup.object()
          .nullable()
          .shape({
            ...tbQuestions,
            eyeDraiLe12q1: Yup.string().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q1a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q1ai: Yup.string()
              .nullable()
              .when('eyeDraiLe12q1a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q3c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q3ci: Yup.string()
              .nullable()
              .when('eyeDraiLe12q3c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q3d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q3di: Yup.string()
              .nullable()
              .when('eyeDraiLe12q3d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q3dii: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q3d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q3diia: Yup.string()
              .nullable()
              .when('eyeDraiLe12q3dii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q4a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q4ai: Yup.string()
              .nullable()
              .when('eyeDraiLe12q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q4aii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q4aiii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q4b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q4bi: Yup.string()
              .nullable()
              .when('eyeDraiLe12q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q4bii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q4biii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q5a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q5ai: Yup.string()
              .nullable()
              .when('eyeDraiLe12q5a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q5b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q5bi: Yup.string()
              .nullable()
              .when('eyeDraiLe12q5b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6ai: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6aii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6bi: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6bii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6ci: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6cii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6di: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6dii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6e: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6ei: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6eii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6f: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6fi: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6fii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6g: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6gi: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6gii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6h: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6hi: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6hii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6i: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6ii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6iii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6j: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6ji: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6j', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6jii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6j', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6k: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q6ki: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6k', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q6kii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q6k', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q7: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q7a: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q7', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q7ai: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q7a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q7aia: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q7ai', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q7aiai: Yup.string()
              .nullable()
              .when('eyeDraiLe12q7aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q8: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q8a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q8', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q8b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q8', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q8c: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q8ci: Yup.string()
              .nullable()
              .when('eyeDraiLe12q8c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q8d: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q8e: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q8d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q8ei: Yup.string()
              .nullable()
              .when('eyeDraiLe12q8e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q8eii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q8e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q9: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q9a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q9', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q10: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q10a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q10b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q10c: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q10', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q10bi: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q10c', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q10bia: Yup.string()
              .nullable()
              .when('eyeDraiLe12q10bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q10bii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q10c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q10biia: Yup.string()
              .nullable()
              .when('eyeDraiLe12q10c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q11: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q11a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q11', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q11b: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q11d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q12: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q12a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q12', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q12b: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q12d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q13a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q13ai: Yup.string()
              .nullable()
              .when('eyeDraiLe12q13a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q13aii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q13a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q13aiii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q13a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q14: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q14a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q14', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q14b: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q14', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q15: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q15a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q15', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q15b: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q15', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q16: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q16a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q16c: Yup.string()
              .nullable()
              .when('eyeDraiLe12q16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q16d: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q16', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q16di: Yup.string()
              .nullable()
              .when('eyeDraiLe12q16d', {
                is: false,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q17: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q17a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q17', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q17b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q17', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q17c: Yup.string()
              .nullable()
              .when('eyeDraiLe12q17', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q18: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q18a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q18', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q18b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q18', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q18c: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q18', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q18ci: Yup.string()
              .nullable()
              .when('eyeDraiLe12q18c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q18cii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q18c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q19a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q19ai: Yup.string()
              .nullable()
              .when('eyeDraiLe12q19a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q19b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q19bi: Yup.string()
              .nullable()
              .when('eyeDraiLe12q19b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q20b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q20bi: Yup.string()
              .nullable()
              .when('eyeDraiLe12q20b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q20c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q20ci: Yup.string()
              .nullable()
              .when('eyeDraiLe12q20c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q20d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q20di: Yup.string()
              .nullable()
              .when('eyeDraiLe12q20d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q21: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q21a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q21b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q23: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q23a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q23b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q23c: Yup.string()
              .nullable()
              .when('eyeDraiLe12q23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q23d: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q23', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q23di: Yup.string()
              .nullable()
              .when('eyeDraiLe12q23d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q24: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q24a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q24', {
                is: true,
                then: Yup.string()
                  .nullable()
                  .when('eyeDraiLe12q24ana', {
                    is: false,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('eyeDraiLe12q24ana', {
                    is: undefined,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('eyeDraiLe12q24ana', {
                    is: null,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  }),
              }),
            eyeDraiLe12q24b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q24', {
                is: true,
                then: Yup.string()
                  .nullable()
                  .when('eyeDraiLe12q24bna', {
                    is: false,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('eyeDraiLe12q24bna', {
                    is: undefined,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('eyeDraiLe12q24bna', {
                    is: null,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  }),
              }),
            eyeDraiLe12q24c: Yup.string()
              .nullable()
              .when('eyeDraiLe12q24', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q24d: Yup.string()
              .nullable()
              .when('eyeDraiLe12q24', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q25: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q25a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q25ai: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q25', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q25aii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q25ai', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q25b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q26: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q26a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q26b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q26c: Yup.string()
              .nullable()
              .when('eyeDraiLe12q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q27a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q27', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27b: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27c: Yup.number()
              .nullable()
              .when('eyeDraiLe12q27', {
                is: true,
                then: Yup.number().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27ci: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q27c', {
                is: 2,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27cii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q27ci', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27d: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27di: Yup.string()
              .nullable()
              .when('eyeDraiLe12q27d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27e: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27ei: Yup.string()
              .nullable()
              .when('eyeDraiLe12q27e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27g: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27gi: Yup.string()
              .nullable()
              .when('eyeDraiLe12q27g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27gii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q27g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27h: Yup.boolean()
              .nullable()
              .when('eyeDraiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27i: Yup.number()
              .nullable()
              .when('eyeDraiLe12q27', {
                is: true,
                then: Yup.number().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q27ii: Yup.string()
              .nullable()
              .when('eyeDraiLe12q27i', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q28: Yup.number().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q28a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q28', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q28b: Yup.string()
              .nullable()
              .when('eyeDraiLe12q28', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q28c: Yup.string()
              .nullable()
              .when('eyeDraiLe12q28', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q33: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q33a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q33', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q34: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q34a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q34', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q35: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q35a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q35', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeDraiLe12q36: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeDraiLe12q36a: Yup.string()
              .nullable()
              .when('eyeDraiLe12q36', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
          }),
      }),
  });
};
