import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public/authForm';

class AuthFormService {
  /* AUTH FORM */
  getAuthForm = async (caseId, authFormId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/${authFormId}`);
      if (request.status === 200) {
        return request.data.authForm;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getAuthFormList = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}`);
      if (request.status === 200) {
        return request.data.authForms;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getBlankAuthForm = async ({ caseId, type }) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/blank/${type}`);
      if (request.status === 200) {
        return request.data.authForm;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createAuthForm = async (caseId, payload) => {
    try {
      const request = await axios.post(`${baseURL}/${caseId}`, payload);
      if (request.status === 200) {
        return request.data.authForm;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateAuthForm = async (caseId, authFormId, payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/${caseId}/${authFormId}`,
        payload,
      );
      if (request.status === 200) {
        return request.data.authForm;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const authFormService = new AuthFormService();

export default authFormService;
