export const SET_CONFIG = '@config/set-config';

export function setConfigData(config) {
  return (dispatch) =>
    dispatch({
      type: SET_CONFIG,
      payload: {
        config,
      },
    });
}
