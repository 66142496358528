import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public/cases';
export const AUDIT_READ_ALL = 'read:caseAuditLogAll';
export const AUDIT_READ_USERS = 'read:caseAuditLogUsers';
class AuditLogService {
  getByCase = async (caseId, user) => {
    if (user.permissions.includes(AUDIT_READ_ALL)) {
      return this.getByCaseAll(caseId);
    }
    return this.getByCaseUserId(caseId);
  };

  getByCaseUserId = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/audit`);
      if (request.status === 200) {
        return request;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getByCaseAll = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/auditAll`);
      if (request.status === 200) {
        return request;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}

const auditLogService = new AuditLogService();

export default auditLogService;
