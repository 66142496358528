import axios from 'src/utils/axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useGetCaseBannerInfoQuery,
  useGetCaseSummaryQuery,
} from 'src/store/serverState';

export const CREATE_CASE = '@case/create-case';
export const DUPLICATE_CASE_OPEN = '@case/duplicate-case-open';
export const DUPLICATE_CASE_CLOSE = '@case/duplicate-case-close';
export const DUPLICATE_CASE_OVERRIDE = '@case/duplicate-case-override';
export const UPDATE_CASE = '@case/update-case';
export const GET_CASE = '@case/get-case';
export const GET_CASE_SUMMARY = '@case/get-case-summary';
export const GET_CASE_STATUS_LIST = '@case/get-case-status-list';
export const GET_REFERRAL_STATUS_LIST = '@case/get-referral-status-list';
export const GET_FAMILY_STATUS_LIST = '@case/get-family-status-list';
export const CREATE_CASE_PARTNERS = '@case/create-case-partners';
export const OPEN_CASE_SEARCH = '@case/open-case-search';
export const CLOSE_CASE_SEARCH = '@case/close-case-search';
export const GET_OUTCOME_LIST = '@case/get-outcome-list';
export const GET_OUTCOME_TYPE_LIST = '@case/get-outcome-type-list';
export const GET_OUTCOME_DETAIL_LIST = '@case/get-outcome-detail-list';
export const CLEAR_CASE = '@case/clear-case';
export const CREATE_CASE_OUTCOMES = '@case/create-case-outcomes';
export const CREATE_CASE_SUMMARY = '@case/create-case-summary';
export const GET_NEXT_STEPS_LIST = '@case/get-case-next-steps-list';
export const GET_TITLE_LIST = '@case/get-title-list';
export const CREATE_DONOR_SCREENING = '@case/create-donor-screening';
export const GET_DONOR_SCREENING = '@case/get-donor-screening';
export const GET_CASE_BANNER_INFORMATION = '@case/get-case-banner-information';
export const GET_CASE_CONTACTS = '@case/get-case-contacts';
export const CREATE_CASE_CONTACTS = '@case/create-case-contacts';
export const UPDATE_CASE_CONTACTS = '@case/update-case-contacts';
export const DELETE_CASE_CONTACTS_KIN = '@case/delete-case-contacts-kin';
export const CLEAR_CASE_CONTACTS = '@case/clear-case-contacts';
export const CREATE_TISSUE_RECOVERY = '@case/create-tissue-recovery';
export const GET_TISSUE_RECOVERY = '@case/get-tissue-recovery';
export const SET_CASE_JURISDICTION_ASSUMED =
  '@case/set-case-jurisdiction-assumed';
export const GET_CASE_CONTACTS_KINS = '@case/get-case-contacts-kins';
export const GET_RECOVERY_DONOR_INFORMATION =
  '@case/get-recovery-donor-information';
export const UPDATE_RECOVERY_DONOR_INFORMATION =
  '@case/update-recovery-donor-information';
export const SET_CASE_ID = '@case/set-case-id';
export const OPEN_RECENT_CASES = '@case/open-recent-cases';
export const CLOSE_RECENT_CASES = '@case/close-recent-cases';

export function createCase(create) {
  const request = axios.post('/public/cases', { ...create });

  return (dispatch) => {
    request.then((response) => {
      if (response && response.data.caseItem) {
        dispatch({
          type: CREATE_CASE,
          payload: response.data,
        });
      } else {
        dispatch({
          type: DUPLICATE_CASE_OPEN,
          payload: response.data,
        });
      }
    });
  };
}

export function createCaseSummary(caseId, values) {
  const request = axios.put(`/public/cases/${caseId}/summaries`, { ...values });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: CREATE_CASE_SUMMARY,
        payload: response.data,
      }),
    );
  };
}

export function updateCase(caseId, values) {
  // donor object contains old data
  delete values.donor; //  eslint-disable-line no-param-reassign
  const request = axios.put(`/public/cases/${caseId}`, { ...values });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: UPDATE_CASE,
        payload: response.data,
      }),
    );
  };
}

export function updateCaseContacts(caseId, values) {
  const request = axios.put(`/public/caseContact/${caseId}`, { ...values });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: CREATE_CASE_CONTACTS,
        payload: response.data,
      }),
    );
  };
}

export function deleteCaseContactsKin(contactId, kinId) {
  const request = axios.delete(`/public/caseContact/${contactId}/kin/${kinId}`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: DELETE_CASE_CONTACTS_KIN,
        payload: response.data,
      }),
    );
  };
}

export function clearCaseContacts() {
  return {
    type: CLEAR_CASE_CONTACTS,
  };
}

export function openCaseSearch() {
  return {
    type: OPEN_CASE_SEARCH,
  };
}

export function closeCaseSearch() {
  return {
    type: CLOSE_CASE_SEARCH,
  };
}

export function getCase(caseId) {
  const request = axios.get(`/public/cases/${caseId}`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_CASE,
        payload: response.data,
      }),
    );
  };
}

export function setCaseId(caseId) {
  return (dispatch) => {
    dispatch({
      type: SET_CASE_ID,
      payload: caseId,
    });
  };
}

export function useCaseBannerInformation(caseId) {
  const banner = useGetCaseBannerInfoQuery(caseId);
  const summary = useGetCaseSummaryQuery(caseId);

  const dispatch = useDispatch();
  useEffect(() => {
    if (banner.isFetching || summary.isFetching) {
      return;
    }
    dispatch({
      type: GET_CASE_SUMMARY,
      payload: { caseItem: summary.data },
    });
    dispatch({
      type: GET_CASE_BANNER_INFORMATION,
      payload: banner.data,
    });
  }, [banner.isSuccess, summary.isSuccess]);
}

export function getCaseSummary(caseId) {
  const request = axios.get(`/public/cases/${caseId}/summaries`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_CASE_SUMMARY,
        payload: response.data,
      }),
    );
  };
}

export function getDonorScreening(caseId) {
  const request = axios.get(`/public/cases/${caseId}/screenings`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_DONOR_SCREENING,
        payload: response.data,
      }),
    );
  };
}

export function createOutcomes(caseId, values) {
  const request = axios.post(`/public/cases/${caseId}/outcomes`, { ...values });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: CREATE_CASE_OUTCOMES,
        payload: response.data,
      }),
    );
  };
}

export function clearCase() {
  return {
    type: CLEAR_CASE,
  };
}

export function getCaseStatusList() {
  const request = axios.get('/public/lookups/caseStatus');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_CASE_STATUS_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getTitleList() {
  const request = axios.get('/public/lookups/title');
  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_TITLE_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getFamilyStatusList() {
  const request = axios.get('/public/lookups/familyStatus');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_FAMILY_STATUS_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getNextStepsList() {
  const request = axios.get('/public/lookups/nextStep?order=nameAsc');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_NEXT_STEPS_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getOutcomeList() {
  const request = axios.get('/public/lookups/outcome?order=nameAsc');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_OUTCOME_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getOutcomeTypeList() {
  const request = axios.get('/public/lookups/outcomeType?order=nameAsc');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_OUTCOME_TYPE_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getOutcomeDetailList() {
  const request = axios.get('/public/lookups/outcomeDetail?order=nameAsc');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_OUTCOME_DETAIL_LIST,
        payload: response.data,
      }),
    );
  };
}

export function createCasePartners(caseId, create) {
  const request = axios.post(`/public/cases/${caseId}/partners`, { ...create });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: CREATE_CASE_PARTNERS,
        payload: response.data,
      }),
    );
  };
}

export function closeDuplicateCaseWarning() {
  return {
    type: DUPLICATE_CASE_CLOSE,
  };
}

export function openDuplicateCaseWarning(duplicateCase) {
  return {
    type: DUPLICATE_CASE_OPEN,
    payload: duplicateCase,
  };
}

export function overrideDupDetector() {
  return {
    type: DUPLICATE_CASE_OVERRIDE,
  };
}

export function getCaseContactsKins(caseId) {
  const request = axios.get(`/public/caseContact/${caseId}/kins`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_CASE_CONTACTS_KINS,
        payload: response.data,
      }),
    );
  };
}

export function setCaseJurisdictionAssumed(jurisdictionAssumed) {
  return {
    type: SET_CASE_JURISDICTION_ASSUMED,
    payload: { jurisdictionAssumed },
  };
}

export function getRecoveryDonorInformation(caseId) {
  const request = axios.get(`/public/recovery/donorInformation/${caseId}`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_RECOVERY_DONOR_INFORMATION,
        payload: response.data,
      }),
    );
  };
}

export function updateRecoveryDonorInformation(caseId, values) {
  const request = axios.put(`/public/recovery/donorInformation/${caseId}`, {
    ...values,
  });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: UPDATE_RECOVERY_DONOR_INFORMATION,
        payload: response.data,
      }),
    );
  };
}

export function openRecentCases() {
  return {
    type: OPEN_RECENT_CASES,
  };
}
export function closeRecentCases() {
  return {
    type: CLOSE_RECENT_CASES,
  };
}
