import * as Sentry from '@sentry/browser';
import axios from 'src/utils/axios';
import socket from 'src/utils/socket';

import {
  setNotificationTypes,
  setNotifications,
} from 'src/actions/messageNotificationActions';

let store = null;

let lastUserId = null;
let lastNotifications = null;

const hasNotificationTypes = () => {
  return (
    Object.keys(store?.getState()?.messageNotification?.notificationTypes || {})
      .length > 0
  );
};

const getNotificationTypes = async () => {
  try {
    const request = await axios.get('/public/notificationTypes');
    if (request.status === 200) {
      const data = request.data.reduce(
        (obj, prop) => ({ ...obj, [prop]: prop }),
        {},
      );
      store.dispatch(setNotificationTypes(data));
      return data;
    }
    throw new Error(request.data.message);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const setStore = (reduxStore) => {
  store = reduxStore;

  socket.on('notification', (notifications) => {
    lastNotifications = notifications;
    if (hasNotificationTypes()) {
      store.dispatch(setNotifications(notifications));
    }
  });

  // Whenever the user id changes, alert the server of the user id.
  // This helps filter updates to only relevant users.
  store.subscribe(() => {
    const userId = store.getState()?.account?.user?.id ?? null;
    if (lastUserId !== userId) {
      lastUserId = userId;
      socket.emit('userIdSet', userId);
      if (userId) {
        getNotificationTypes().then(() => {
          if (lastNotifications) {
            store.dispatch(setNotifications(lastNotifications));
          }
        });
      }
    }
  });
};

export default setStore;
