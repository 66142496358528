import axios from 'src/utils/axios';
import lookupService from 'src/services/lookupService';

export const GET_LABELS = '@checklist/get-labels';
export const GET_CHECKLIST = '@checklist/get-checklist';
export const OPEN_CHECKLIST = '@checklist/open-checklist';
export const CLOSE_CHECKLIST = '@checklist/close-checklist';
export const SET_CHECKLIST = '@checklist/set-checklist';
export const RELOAD_CHECKLIST = '@checklist/reload-checklist';

export function setChecklist(checkItem) {
  return {
    type: SET_CHECKLIST,
    payload: checkItem,
  };
}

export function getChecklist(caseId) {
  const lookupRequest = lookupService.getLookup(
    'checklistItem',
    'id,name,active',
    'idAsc',
  );
  const checklistRequest = axios.get(`/public/caseChecklist/${caseId}`);

  return (dispatch) => {
    Promise.all([lookupRequest, checklistRequest]).then(
      ([response1, response2]) => {
        dispatch({
          type: GET_LABELS,
          payload: response1,
        });
        dispatch({
          type: GET_CHECKLIST,
          payload: response2.data.record,
        });
      },
    );
  };
}

export function openChecklist() {
  return {
    type: OPEN_CHECKLIST,
  };
}

export function closeChecklist() {
  return {
    type: CLOSE_CHECKLIST,
  };
}

export function saveChecklist(checklist, caseId) {
  const request = axios.post(`/public/caseChecklist/${caseId}`, checklist);

  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: RELOAD_CHECKLIST,
        payload: response.data.record,
      });
    });
  };
}

export function updateChecklist(checklist, caseId) {
  const request = axios.put(`/public/caseChecklist/${caseId}`, checklist);

  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: RELOAD_CHECKLIST,
        payload: response.data.record,
      });
    });
  };
}
