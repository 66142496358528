import { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Popover, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import LoadingScreen from 'src/components/LoadingScreen';
import { closeRecentCases } from 'src/actions/caseActions';
import axios from 'src/utils/axios';
import { serverState } from 'src/store/serverState';

const useStyles = makeStyles((theme) => ({
  popover: {
    borderRadius: 0,
    minWidth: 300,
  },
  itemLeft: {
    backgroundColor: theme.palette.background.dark,
  },
  itemRight: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    color: theme.palette.alternative.main,
  },
  title: {
    color: theme.palette.primary.main,
    padding: '5px 0',
    overflowWrap: 'break-word',
  },
  link: {
    textDecoration: 'none',
  },
  pageName: {
    color: 'white',
    overflowWrap: 'break-word',
    textAlign: 'center',
  },
  donorId: {
    color: 'black',
  },
}));

function RecentCases({ anchorEl }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [recentCases, setCases] = useState([]);
  const { caseItem, recentCasesOpen } = useSelector((state) => state.caseItem);

  const loadRecentCases = useCallback(async () => {
    try {
      if (recentCasesOpen) {
        setLoading(true);

        const {
          data: { record },
        } = await axios.get('/public/recentCases');

        record.splice(
          record.findIndex((e) => e.href.includes(caseItem.id)),
          1,
        );

        setCases(record);
        setLoading(false);
      }
    } catch (error) {
      throw new Error(error);
    }
  }, [recentCasesOpen]);

  useEffect(() => {
    loadRecentCases();
  }, [loadRecentCases, caseItem.id]);

  const handleClose = () => {
    dispatch(closeRecentCases());
  };

  // closes recent cases and clears state when case id changes (navigating to page w no case banner while recent cases is open)
  const handleCloseCallback = useCallback(() => {
    dispatch(closeRecentCases());
  }, [dispatch]);

  useEffect(() => {
    handleCloseCallback(); // close recent cases whenever case ID changes
  }, [caseItem.id, handleCloseCallback]);

  const id = recentCasesOpen ? 'recent-cases' : undefined;

  const renderItem = (item) => {
    return (
      <Box
        display="flex"
        marginBottom={1}
      >
        <Box
          width="60%"
          className={classes.itemLeft}
          p={1}
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="caption"
            className={classes.name}
          >
            {`${item.donor.firstName} ${item.donor.lastName}`}
          </Typography>
          <Typography
            variant="caption"
            className={classes.donorId}
          >
            {item.donor.entityId}
          </Typography>
        </Box>
        <Box
          width="40%"
          className={classes.itemRight}
          p={1}
        >
          <Typography
            variant="caption"
            color="white"
            className={classes.pageName}
          >
            {item.title}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Popover
      id={id}
      open={recentCasesOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorPosition={{
        left: -10,
        top: 10,
      }}
      classes={{
        paper: classes.popover,
      }}
    >
      {loading ? (
        <Box
          display="flex"
          marginBottom={1}
          width={300}
        >
          <LoadingScreen />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="caption"
            align="center"
            className={classes.title}
          >
            Pick up where you left off!
          </Typography>
          {recentCases.map((item) => (
            <Link
              key={item.title}
              to={item.href}
              className={classes.link}
              onClick={() => {
                handleClose();
                dispatch(
                  serverState.util.invalidateTags(['CaseSummary', 'Case']),
                );
              }}
            >
              {renderItem(item)}
            </Link>
          ))}
        </Box>
      )}
    </Popover>
  );
}

export default RecentCases;
