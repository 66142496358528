import axios from 'src/utils/axios';

export const GET_AVAILABLE_CASE_DATA = '@transmit/get-available-case-data';
export const GET_AVAILABLE_RECIPIENTS = '@transmit/get-available-recipients';
export const SET_IS_TRANSMITTING = '@transmit/set-is-transmitting';

export const getAvailableCaseData = (caseId) => {
  const request = axios.get(`/public/transmit/availableCaseData/${caseId}`);

  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: GET_AVAILABLE_CASE_DATA,
        payload: response.data,
      });
    });
  };
};

export const getAvailableRecipients = () => {
  const request = axios.get(`/public/transmit/availableRecipients`);

  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: GET_AVAILABLE_RECIPIENTS,
        payload: response.data,
      });
    });
  };
};

export const setIsTransmitting = (isTransmitting) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_TRANSMITTING,
      payload: isTransmitting,
    });
  };
};
