import moment from 'moment';

export const reservedStatusIds = {
  OPEN: 1,
  IN_PROGRESS: 2,
  COMPLETE: 3,
};

export const reservedPriorityIds = {
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3,
};

export const NEW_TASK_ID = Number.MAX_SAFE_INTEGER;

export const statusClasses = {
  1: 'open',
  2: 'inProgress',
  3: 'complete',
};

export const defaultTaskProps = {
  description: '',
  dueDate: null,
  completedDate: null,
  assignedId: null,
  statusId: null,
  priorityId: null,
  typeId: null,
  caseId: null,
};

export const defaultNewTaskProps = {
  ...defaultTaskProps,
  id: NEW_TASK_ID,
  statusId: reservedStatusIds.OPEN,
  priorityId: reservedPriorityIds.HIGH,
  taskHistory: '',
};

export const editableTaskProps = Object.keys(defaultTaskProps).concat(['id']);

export const isStatus = (currentStatusId, ...statusConstants) => {
  // If the current status id is not listed at all, show all status change options
  if (!Object.values(reservedStatusIds).includes(currentStatusId)) {
    return true;
  }
  return statusConstants.some(
    (statusName) => reservedStatusIds[statusName] === currentStatusId,
  );
};

export const sortAndUpdateTasks = ({
  priorityHash,
  newTasksArray,
  updateTasks,
}) => {
  const isNew = (el) => el.id === NEW_TASK_ID;
  const priority = (el) => priorityHash[el.priorityId]?.weight || 0;
  const dueDate = (el) => (el.dueDate ? new Date(el.dueDate) : Infinity);
  const status = (el) => {
    switch (el.status) {
      case reservedStatusIds.OPEN:
        return 1e4;
      case reservedStatusIds.IN_PROGRESS:
        return 1e3;
      case reservedStatusIds.COMPLETE:
        return 1e2;
      default:
        return 0;
    }
  };
  return updateTasks(
    newTasksArray.concat().sort((a, b) => {
      // For now, sort by priority, due date, status
      return (
        isNew(b) - isNew(a) ||
        priority(b) - priority(a) ||
        dueDate(a) - dueDate(b) ||
        status(b) - status(a)
      );
    }),
  );
};

export const createUserHash = (users) => {
  return users.reduce(
    (hash, user) => ({
      ...hash,
      [user.id]: {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        suspended: user.suspended,
      },
    }),
    {},
  );
};

export const sortAuxData = (auxResponse) => {
  const newStatusHash = auxResponse.statuses.reduce(
    (hash, status) => ({
      ...hash,
      [status.id]: status.name,
    }),
    {},
  );
  const newPriorityHash = auxResponse.priorities.reduce(
    (hash, priority) => ({
      ...hash,
      [priority.id]: priority,
    }),
    {},
  );

  const newTypeHash = auxResponse.types.reduce(
    (hash, type) => ({
      ...hash,
      [type.id]: type,
    }),
    {},
  );

  const reservedIds = Object.values(reservedStatusIds);
  const otherStatuses = Object.entries(newStatusHash)
    .filter(([id]) => !reservedIds.includes(+id))
    .reduce(
      (hash, [id, name]) => ({
        ...hash,
        [id]: name,
      }),
      {},
    );

  return {
    userId: auxResponse.userId,
    statusHash: newStatusHash,
    otherStatuses,
    priorityHash: newPriorityHash,
    typeHash: newTypeHash,
  };
};

export const colors = {
  // theme.palette.grey['600']
  unknown: {
    backgroundColor: '#757575',
    color: '#fff',
  },
  // theme.palette.primary.main
  open: {
    backgroundColor: '#3E748D',
    color: '#fff',
  },
  inProgress: {
    // theme.palette.warning.main
    backgroundColor: '#ff9800',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  complete: {
    // theme.palette.success.main
    backgroundColor: '#4caf50',
    color: 'rgba(0, 0, 0, 0.87)',
  },
};

export const generateColorClasses = () =>
  Object.entries(colors).reduce(
    (hash, [className, definition]) => ({
      ...hash,
      [`&.${className}`]: definition,
    }),
    {},
  );

const timeEscape = '%_utc_timezone_%';
const formatHistoryTime = (time) => moment(time).format('MM-DD-YYYY HH:mm');
export const localizeHistoryTime = (payload) => {
  if (payload instanceof Array) {
    return payload.map(localizeHistoryTime);
  }

  const taskHistory =
    payload.taskHistory
      ?.split('\n')
      ?.map((history) => {
        const firstIndex = history?.indexOf(timeEscape) ?? -1;
        const lastIndex = history?.indexOf(timeEscape, firstIndex + 1) ?? -1;

        if (firstIndex === -1 || lastIndex === -1) {
          return history;
        }

        const utcTime = new Date(
          `${history
            ?.substring(firstIndex, lastIndex)
            ?.replace(timeEscape, '')} UTC`,
        );

        return `${history.substring(0, firstIndex)}${formatHistoryTime(
          utcTime,
        )}${history.substring(lastIndex + timeEscape.length)}`;
      })
      ?.join('\n') ?? '';

  return {
    ...payload,
    taskHistory,
  };
};
