import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public/drai';

class MedSocialService {
  /* DRAI */
  getDrai = async (caseId, draiId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/${draiId}`);
      if (request.status === 200) {
        return request.data.drai;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getDraiList = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}`);
      if (request.status === 200) {
        return request.data.drais;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createDrai = async (caseId, payload) => {
    try {
      const request = await axios.post(`${baseURL}/${caseId}`, payload);
      if (request.status === 200) {
        return request.data.drai;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getBlankDrai = async ({ caseId, type }) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/blank/${type}`);
      if (request.status === 200) {
        return request.data.drai;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateDrai = async (caseId, draiId, payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/${caseId}/${draiId}`,
        payload,
      );
      if (request.status === 200) {
        return request.data.drai;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const medSocialService = new MedSocialService();

export default medSocialService;
