/* eslint-disable react/no-array-index-key */
// The portal wrapper for case task (though it's generic and can be for anything)
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Divider, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CustomField } from 'src/components';
import { useGetTaskAllCaseIdsQuery } from 'src/store/serverState';
import { NEW_TASK_ID } from '../api';

const useStyles = makeStyles((theme) => ({
  required: {
    '&:after': {
      content: '"*"',
      color: theme.palette.error.main,
      margin: '0 .25rem',
    },
  },
}));

function EditTask({
  users,
  priorities,
  types,
  task: baseTask,
  includeCaseIds = false,
  onClose,
}) {
  const cases = useGetTaskAllCaseIdsQuery(null, { skip: !includeCaseIds });
  const classes = useStyles();
  const [task, setTaskObject] = useState({
    ...baseTask,
  });
  const [dueDateModified, setDueDateModified] = useState(false);
  const setTask = (obj) => setTaskObject({ ...task, ...obj });

  return (
    <Box>
      <Box
        px={2}
        py={1}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          variant="contained"
          color="secondary"
          disabled={
            task.priorityId == null ||
            !task.description ||
            task.typeId == null ||
            task.assignedId == null ||
            (cases.data && task.caseId == null)
          }
          onClick={() => {
            onClose(task, true);
          }}
        >
          Apply
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onClose(baseTask, false);
          }}
        >
          Cancel
        </Button>
      </Box>
      <Box p={2}>
        <Box>
          <Typography className={classes.required}>Type</Typography>
          <CustomField
            inputType="select"
            required
            options={types}
            value={task.typeId}
            optionLabel="name"
            optionValue="id"
            noColorChange
            writeOnly
            cbChange={({ value }) => {
              setTask({
                typeId: value,
              });
            }}
          />
        </Box>
        <Box pt={2}>
          <Typography className={classes.required}>Priority</Typography>
          <CustomField
            inputType="select"
            required
            options={priorities}
            value={task.priorityId}
            optionLabel="name"
            optionValue="id"
            noColorChange
            writeOnly
            cbChange={({ value }) => {
              setTask({
                priorityId: value,
              });
            }}
          />
        </Box>
        <Box pt={2}>
          <Typography className={classes.required}>Description</Typography>
          <CustomField
            inputType="text"
            multiline
            value={task.description}
            noColorChange
            writeOnly
            cbChange={(value) => {
              setTask({
                description: value,
              });
            }}
          />
        </Box>
        <Box pt={2}>
          <Typography className={classes.required}>Assigned User</Typography>
          <CustomField
            inputType="select"
            required
            options={users}
            value={task.assignedId}
            optionLabel="fullName"
            optionValue="id"
            noColorChange
            writeOnly
            cbChange={({ value }) => {
              setTask({
                assignedId: value,
              });
            }}
          />
        </Box>
        {cases.data && includeCaseIds ? (
          <>
            <Box pt={2}>
              <Typography className={classes.required}>Case Id</Typography>
              <CustomField
                inputType="select"
                required
                options={cases.data}
                value={task.caseId}
                optionLabel="id"
                optionValue="id"
                noColorChange
                writeOnly
                cbChange={({ value }) => {
                  setTask({
                    caseId: value,
                  });
                }}
              />
            </Box>
            <Box>
              <Link
                target="_blank"
                href={`/case/${task.caseId}/referral/summary`}
              >
                <Typography>See case details</Typography>
              </Link>
            </Box>
          </>
        ) : null}
        <Box
          pt={2}
          pb={3}
        >
          <Typography>Due Date</Typography>
          <CustomField
            inputType="dateTime"
            pastDisabled={dueDateModified}
            futureDisabled={false}
            multiline
            value={task.dueDate}
            noColorChange
            writeOnly
            cbChange={(value) => {
              if (
                new Date(value).getTime() !== new Date(task.dueDate).getTime()
              ) {
                setDueDateModified(true);
              }
              setTask({
                dueDate: value,
              });
            }}
          />
        </Box>
        {task.id !== NEW_TASK_ID ? (
          <>
            <Divider />
            <Box pt={3}>
              <Typography variant="h5">History</Typography>
            </Box>
            <Box pt={2}>
              {task.taskHistory.split('\n').map((historyLine, index) => (
                <Typography key={index}>{historyLine}</Typography>
              ))}
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
}

EditTask.propTypes = {
  users: PropTypes.array.isRequired,
  priorities: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  task: PropTypes.object.isRequired,
  includeCaseIds: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default EditTask;
