import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Toolbar, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { THEMES } from 'src/constants';
import { openCaseSearch, openRecentCases } from 'src/actions/caseActions';
import { openCaseNotesList } from 'src/actions/notesActions';
import { openCaseTasksList } from 'src/actions/caseTasksActions';
import { openChecklist } from 'src/actions/checklistActions';
import { AUDIT_READ_USERS } from 'src/services/auditService';
import NavItem from './NavItem';
import RecentCases from './RecentCases';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: theme.zIndex.drawer - 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
    backgroundColor: 'white',
  },
  toolbar: {
    minHeight: 0,
    overflowX: 'auto',
    justifyContent: 'flex-start',
  },
  toolbarContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  list: {
    display: 'flex',
    width: '100%',
  },
  listPrimary: {
    display: 'flex',
    width: '100%',
    color: theme.palette.primary.main,
  },
}));

function reduceChildRoutes({ acc, item, depth = 0 }) {
  const key = item.title + depth;

  acc.push(
    <NavItem
      depth={depth}
      href={item.href}
      icon={item.icon}
      onClick={item.onClick}
      key={key}
      info={item.info}
      title={item.title}
      disabledProp={item.disabled}
      clickHandler={item.clickHandler}
      type={item.type || null}
    />,
  );

  return acc;
}

function RenderNavItems({ items, ...rest }) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.account);
  return (
    <List
      disablePadding
      className={classes.list}
    >
      {items
        .filter(
          (item) =>
            !item.permission || user.permissions?.includes(item.permission),
        )
        .reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}
    </List>
  );
}

function SecondaryTopBar({ className, ...rest }) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const [anchorEl, setAnchorEl] = useState();

  const navItems = [
    {
      title: 'Referral',
      href: 'case/:caseId/referral/*',
      clickHandler: caseId
        ? () => navigate(`referral/summary`, { replace: true })
        : () => dispatch(openCaseSearch()),
    },
    {
      title: 'Med Social',
      href: 'case/:caseId/medSocial/*',
      clickHandler: () => navigate(`medSocial`),
      disabled: !caseId,
    },
    {
      title: 'Recovery',
      href: 'case/:caseId/recovery/*',
      clickHandler: () => navigate(`recovery/supplies`),
      disabled: !caseId,
    },
    {
      title: 'Medical Review',
      href: 'case/:caseId/medicalReview/*',
      clickHandler: () => navigate(`medicalReview/summary`),
      disabled: !caseId,
    },
    {
      title: 'Approvals',
      href: 'case/:caseId/approvals/*',
      clickHandler: () => navigate(`approvals/approval`),
      disabled: !caseId,
    },
    {
      title: 'Transmit',
      href: 'case/:caseId/transmit/*',
      clickHandler: () => navigate(`transmit/data`),
      disabled: !caseId,
    },
    {
      title: 'Attachments',
      href: 'case/:caseId/attachments/*',
      permission: 'read:attachments',
      clickHandler: () => navigate(`attachments`),
      disabled: !caseId,
    },
    {
      title: 'Audit',
      href: 'case/:caseId/audit/*',
      permission: AUDIT_READ_USERS,
      clickHandler: () => navigate(`audit`),
      disabled: !caseId,
    },
    {
      title: 'Case Notes',
      href: 'case/:caseId/notes/*',
      permission: 'read:notes',
      clickHandler: () => dispatch(openCaseNotesList()),
      disabled: !caseId,
    },
    {
      title: 'Case Tasks',
      href: 'case/:caseId/tasks/*',
      permission: 'read:tasks',
      clickHandler: () => dispatch(openCaseTasksList()),
      disabled: !caseId,
    },
    {
      title: 'Checklist',
      href: 'case/:caseId/checklist/*',
      permission: 'read:caseChecklist',
      clickHandler: () => dispatch(openChecklist()),
      disabled: !caseId,
    },
  ];

  const navItems2 = [
    {
      title: 'Reports',
      href: 'reports',
      clickHandler: () => navigate('/reports'),
      disabled: false,
    },
    {
      title: 'Dashboards',
      href: 'dashboards/*',
      clickHandler: () => navigate('/dashboards/activeReferral'),
      disabled: false,
    },
    {
      title: 'Tasks',
      href: 'tasks/',
      clickHandler: () => navigate('/tasks'),
      disabled: false,
    },
    {
      title: 'Inbox',
      href: 'inbox',
      clickHandler: () => navigate('/inbox'),
      disabled: false,
    },
    {
      title: 'Recent Cases',
      href: 'recentCases',
      clickHandler: (event) => {
        setAnchorEl(event.currentTarget);
        dispatch(openRecentCases());
      },
      disabled: false,
    },
  ];

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Box className={classes.toolbarContainer}>
          <div>
            <List
              className={classes.list}
              disablePadding
            >
              {RenderNavItems({
                classes,
                items: navItems,
                pathname: location.pathname,
              })}
            </List>
          </div>
          <div>
            <List
              className={classes.list}
              disablePadding
            >
              {RenderNavItems({
                classes,
                items: navItems2,
                pathname: location.pathname,
              })}
            </List>
          </div>
        </Box>
      </Toolbar>
      <RecentCases anchorEl={anchorEl} />
    </AppBar>
  );
}

SecondaryTopBar.propTypes = {
  className: PropTypes.string,
};

RenderNavItems.propTypes = {
  items: PropTypes.any,
};

export default SecondaryTopBar;
