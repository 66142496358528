/* eslint-disable react/jsx-no-constructed-context-values */
import { getIn } from 'formik';
import { useReducer } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { setIn } from 'src/utils/formUtil';
import useEventCallback from 'src/hooks/useEventCallback';

const CaseSearchReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      return {
        ...setIn(state, action.payload.path, action.payload.value),
      };
    }
    case 'LOADING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'DELETE': {
      const newArray = [...state.searchFields];
      newArray.splice(action.payload.index, 1);

      return {
        ...state,
        searchFields: newArray,
      };
    }
    default: {
      throw new Error("Action Type Doesn't Exist");
    }
  }
};

const CaseSearchContext = createContext(undefined);

function CaseSearchProvider(props) {
  const { children, initialValues } = props;

  const [state, dispatch] = useReducer(CaseSearchReducer, {
    searchFields: initialValues,
  });

  const setCaseSearch = useEventCallback((name, value) => {
    return dispatch({
      type: 'UPDATE',
      payload: {
        path: name,
        value,
      },
    });
  });
  const deleteCaseSearch = useEventCallback((name) => {
    return dispatch({
      type: 'UPDATE',
      payload: {
        path: name,
      },
    });
  });

  const contextValue = {
    setCaseSearch,
    deleteCaseSearch,
    state,
  };
  return (
    <CaseSearchContext.Provider value={contextValue}>
      {children}
    </CaseSearchContext.Provider>
  );
}

const useCaseSearchContext = (selector) =>
  useContextSelector(CaseSearchContext, selector);

const useGetCaseSearch = (name) =>
  useCaseSearchContext((ctx) => getIn(ctx.state, name));

const useSetCaseSearch = () => useCaseSearchContext((ctx) => ctx.setCaseSearch);
const useDeleteCaseSearch = () =>
  useCaseSearchContext((ctx) => ctx.deleteCaseSearch);

export {
  CaseSearchProvider,
  useCaseSearchContext,
  useGetCaseSearch,
  useDeleteCaseSearch,
  useSetCaseSearch,
};
