export const OPEN_CASE_TASKS_LIST = '@tasks/open-case-tasks-list';
export const CLOSE_CASE_TASKS_LIST = '@tasks/close-case-tasks-list';

export function openCaseTasksList() {
  return {
    type: OPEN_CASE_TASKS_LIST,
  };
}

export function closeCaseTasksList() {
  return {
    type: CLOSE_CASE_TASKS_LIST,
  };
}
