import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  IconButton,
  Paper,
  Portal,
  SvgIcon,
  Typography,
  Hidden,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  X as XIcon,
  Maximize as MaximizeIcon,
  Minimize as MinimizeIcon,
  ChevronDown,
  ChevronUp,
} from 'react-feather';
import { closeCaseNoteDetails } from 'src/actions/notesActions';
import clsx from 'clsx';
import NoteDetails from '../NotesView/NoteDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: `calc(100% - ${theme.spacing(6)})`,
    maxHeight: `calc(100% - ${theme.spacing(6)})`,
    width: 600,
    minWidth: 600,
    position: 'fixed',
    margin: theme.spacing(3),
    outline: 'none',
    zIndex: theme.zIndex.modal,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
    [theme.breakpoints.down('lg')]: {
      paddingTop: '65px',
      margin: '0',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  bottomSide: {
    bottom: 0,
    right: 0,
  },
  topSide: {
    top: 95,
    right: 0,
  },
  fullScreen: {
    height: '80%',
    width: '40%',
    minWidth: 650,
    [theme.breakpoints.down('lg')]: {
      width: '100vw',
      height: '100vh',
      minWidth: 0,
    },
  },
  input: {
    width: '100%',
  },
  editor: {
    flexGrow: 1,
    '& .ql-editor': {
      minHeight: 300,
    },
  },
  action: {
    marginRight: theme.spacing(1),
  },
  actionRight: {
    align: 'right',
    margin: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
}));

function CaseNoteDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { caseNoteDetailsOpen, caseNoteDetailsId } = useSelector(
    (state) => state.notes,
  );
  const [fullScreen, setFullScreen] = useState(true);
  const [flipped, setFlipped] = useState(false);

  const handleExitFullScreen = () => {
    setFullScreen(false);
  };

  const handleEnterFullScreen = () => {
    setFullScreen(true);
  };

  const handleClose = () => {
    dispatch(closeCaseNoteDetails());
  };

  const handleSwitchSides = () => {
    setFlipped(!flipped);
  };

  if (!caseNoteDetailsOpen) {
    return null;
  }

  return (
    <Portal>
      <Paper
        className={clsx(classes.root, {
          [classes.fullScreen]: fullScreen,
          [classes.topSide]: flipped,
          [classes.bottomSide]: !flipped,
        })}
        elevation={12}
      >
        <Box
          bgcolor="background.dark"
          display="flex"
          alignItems="center"
          py={1}
          px={2}
        >
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Case Notes
          </Typography>
          <Box flexGrow={1} />
          <Hidden lgDown>
            {flipped ? (
              <IconButton
                onClick={handleSwitchSides}
                size="large"
              >
                <SvgIcon fontSize="small">
                  <ChevronDown />
                </SvgIcon>
              </IconButton>
            ) : (
              <IconButton
                onClick={handleSwitchSides}
                size="large"
              >
                <SvgIcon fontSize="small">
                  <ChevronUp />
                </SvgIcon>
              </IconButton>
            )}
            {fullScreen ? (
              <IconButton
                onClick={handleExitFullScreen}
                size="large"
              >
                <SvgIcon fontSize="small">
                  <MinimizeIcon />
                </SvgIcon>
              </IconButton>
            ) : (
              <IconButton
                onClick={handleEnterFullScreen}
                size="large"
              >
                <SvgIcon fontSize="small">
                  <MaximizeIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Hidden>
          <IconButton
            onClick={handleClose}
            size="large"
          >
            <SvgIcon fontSize="small">
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <NoteDetails
          isCaseNoteDetailsInstance
          noteId={caseNoteDetailsId}
        />
      </Paper>
    </Portal>
  );
}

export default CaseNoteDetails;
