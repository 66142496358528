import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

class GroupService {
  getAll = async () => {
    try {
      const request = await axios.get(`/public/groups`);
      if (request.status === 200) {
        return request.data.groups;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}

const groupService = new GroupService();

export default groupService;
