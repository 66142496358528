import axios from 'src/utils/axios';

export const GET_PARTNERS_FULL_LIST = '@case/get-partners-full-list';

export function getPartnersFullList() {
  const request = axios.get('/public/partners');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_PARTNERS_FULL_LIST,
        payload: response.data,
      }),
    );
  };
}
