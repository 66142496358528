import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public/recovery';

class RecoveryService {
  /* DONOR INFORMATION */
  getDonorInformation = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/donorInformation`);
      if (request.status === 200) {
        return request.data.caseItem;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateDonorInformation = async (caseId, payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/${caseId}/donorInformation`,
        payload,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /* RECOVERY SITE */
  getRecoverySite = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/recoverySite`);
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createRecoverySite = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/recoverySite`,
        payload,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateRecoverySite = async (caseId, payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/${caseId}/recoverySite`,
        payload,
      );
      if (request.status === 200) {
        return request.data.caseItem;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /* SHIPPING DETAILS */
  getShippingDetails = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/shippingDetail`);
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateShippingDetails = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/shippingDetail`,
        payload,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /* EYE ASSESSMENT */
  getEyeAssessment = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/eyeAssessment`);
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getEyeFindingPreset = async () => {
    try {
      const request = await axios.get(
        `${baseURL}/eyeAssessment/findings/presets`,
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateEyeAssessment = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/eyeAssessment`,
        payload,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /* PHYSICAL ASSESSMENT */
  getPhysicalAssessment = async (caseId) => {
    try {
      const request = await axios.get(
        `${baseURL}/${caseId}/physicalAssessment`,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updatePhysicalAssessment = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/physicalAssessment`,
        payload,
      );
      if (request.status === 200) {
        return request.data.item;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /* TISSUE RECOVERY */
  getTissueRecovery = async (caseId) => {
    try {
      const request = await axios.get(`public/recoveries/${caseId}/tissues`);
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createTissueRecovery = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `public/recoveries/${caseId}/tissues`,
        payload,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateTissueRecovery = async (caseId, payload) => {
    try {
      const request = await axios.put(
        `public/recoveries/${caseId}/tissues`,
        payload,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getSerology = async (caseId, serologyId) => {
    try {
      const request = await axios.get(
        `${baseURL}/${caseId}/serology/${serologyId}`,
      );
      if (request.status === 200) {
        return request.data.serology;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createSerology = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/serology`,
        payload,
      );
      if (request.status === 200) {
        return request.data.serology;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateSerology = async (caseId, serologyId, payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/${caseId}/serology/${serologyId}`,
        payload,
      );
      if (request.status === 200) {
        return request.data.serology;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const recoveryService = new RecoveryService();

export default recoveryService;
