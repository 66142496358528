import { useDispatch } from 'react-redux';
import { Tooltip, IconButton, SvgIcon } from '@mui/material';
import { Search as SearchIcon } from 'react-feather';

import { openCaseSearch } from 'src/actions/caseActions';

function Search({ initNested, setNestedMenus, setDrawer }) {
  const dispatch = useDispatch();

  return (
    <Tooltip title="Search">
      <IconButton
        color="inherit"
        onClick={() => {
          setNestedMenus({ ...initNested });
          setDrawer(false);
          dispatch(openCaseSearch());
        }}
        size="large"
      >
        <SvgIcon fontSize="small">
          <SearchIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export default Search;
