import { memo, useState } from 'react';
import {
  Collapse,
  IconButton,
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Delete } from '@mui/icons-material';
import CustomTableMobileRowCell from './CustomTableMobileRowCell';

const CustomTableMobileRow = memo(
  ({
    index,
    rowObject,
    options,
    columns,
    baseRowObject,
    headCells,
    arrayHelpers,
    viewMode,
  }) => {
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const isExpanded = open || !viewMode;
    let keyToUse = options.key;
    if (!options.key) {
      keyToUse = columns[0].id;
    }
    const keyColumn = columns.find((cd) => cd.id === keyToUse);
    const structureDelete = () => {
      setOpenDelete(false);
      const result = options?.actions?.removeRow
        ? options?.actions?.removeRow(index)
        : null;

      if (options?.form) {
        arrayHelpers.remove(index);
      }
      return result;
    };

    return (
      <Box
        display="flex"
        borderRight="1px solid black"
        borderLeft="1px solid black"
        flexDirection="column"
        width="100%"
      >
        <Box
          padding="10px 0px"
          display="flex"
          alignItems="center"
          borderBottom="1px solid black"
          borderTop="1px solid black"
          disabled={!viewMode}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <IconButton
            disabled={!viewMode}
            style={{
              padding: 0,
            }}
            size="large"
          >
            {isExpanded ? (
              <ExpandMoreIcon size="small" />
            ) : (
              <ChevronRightIcon size="small" />
            )}
          </IconButton>
          {keyColumn ? (
            <CustomTableMobileRowCell
              columnData={keyColumn}
              nonFormValue={baseRowObject[keyToUse]}
              headData={headCells.find((hc) => hc.id === keyToUse)}
              rowObject={{ customStyle: { fontWeight: 'bold' }, label: null }}
              mainIndex={index}
              keyToUse={keyToUse}
              options={{
                key: keyToUse,
                form: options.form,
                global: { customStyle: { fontWeight: 'bold' } },
              }}
            />
          ) : (
            <Typography style={{ fontWeight: 'bold' }}>
              {keyToUse.replaceAll('{{index}}', index + 1)}
            </Typography>
          )}
        </Box>
        <Collapse in={isExpanded}>
          {columns
            .filter((cd) => cd.id !== keyToUse || !options.hideKey)
            .map((columnData, columnIndex) => {
              let headIndex;
              const headData = headCells.find((hc, hcIndex) => {
                if (hc.id === columnData.id) {
                  headIndex = hcIndex;
                  return hc.id === columnData.id;
                }
                return false;
              });
              const nonFormValue = baseRowObject[columnData.id];
              return (
                <CustomTableMobileRowCell
                  columnData={columnData}
                  nonFormValue={nonFormValue}
                  headData={headData}
                  rowObject={rowObject}
                  headIndex={headIndex}
                  mainIndex={index}
                  columnIndex={columnIndex}
                  options={options}
                />
              );
            })}
          {!viewMode &&
            (rowObject.permanent == null || !rowObject.permanent) && (
              <Box
                display="flex"
                justifyContent="flex-end"
                width="100%"
                padding="5px 15px"
                alignItems="center"
              >
                <Button
                  style={{ marginRight: '30px', backgroundColor: '#F4531B' }}
                  onClick={() => {
                    setOpenDelete(true);
                  }}
                  color="primary"
                  variant="contained"
                >
                  <Delete style={{ marginRight: '5px' }} /> Delete
                </Button>
              </Box>
            )}
        </Collapse>
        <Dialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{ paddingBottom: '0px' }}
            id="alert-dialog-title"
          >
            Are you sure you want to delete?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {keyColumn ? (
                <CustomTableMobileRowCell
                  columnData={keyColumn}
                  headData={headCells.find((hc) => hc.id === keyToUse)}
                  rowObject={{
                    customStyle: { fontWeight: 'bold' },
                    label: null,
                  }}
                  mainIndex={index}
                  keyToUse={keyToUse}
                  options={{
                    key: keyToUse,
                    form: options.form,
                    global: { customStyle: { fontWeight: 'bold' } },
                  }}
                />
              ) : (
                keyToUse.replaceAll('{{index}}', index + 1)
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => structureDelete()}
              variant="contained"
              color="secondary"
            >
              Yes
            </Button>
            <Button
              onClick={() => setOpenDelete(false)}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  },
);

export default CustomTableMobileRow;
