/* eslint-disable func-names */
import * as Yup from 'yup';
import { REQUIRED_FIELD } from 'src/constants';
import { eyeOnlyDraiLe12Schema } from './eyeLe12.validation';
import { eyeDraiGt12Schema } from './eyeGt12.validation';
import { eyeOnlyBirthMotherSchema } from './eyeBirthMother.validation';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

Yup.addMethod(Yup.object, 'unique', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    const { path } = this;
    const options = [...this.parent];
    const currentIndex = options.indexOf(value);

    const subOptions = options.slice(0, currentIndex);

    if (
      subOptions.some((option) => option[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message,
      });
    }

    return true;
  });
});

export const draiValidationSchema = Yup.object().shape({
  interviewPlace: Yup.string()
    .nullable()
    .when('signatureBool', {
      is: true,
      then: Yup.string().nullable().required(REQUIRED_FIELD),
    }),
  interviewDate: Yup.string()
    .nullable()
    .when('signatureBool', {
      is: true,
      then: Yup.string().nullable().required(REQUIRED_FIELD),
    }),
  interviewees: Yup.array().when('signatureBool', {
    is: true,
    then: Yup.array()
      .min(1)
      .of(
        Yup.object()
          .shape({
            firstName: Yup.string().nullable().required(REQUIRED_FIELD),
            lastName: Yup.string().nullable().required(REQUIRED_FIELD),
            relationshipTypeId: Yup.number()
              .nullable()
              .required('Please select a relationship'),
            phone: Yup.string()
              .nullable()
              .matches(phoneRegExp, 'Phone number is not valid')
              .required(REQUIRED_FIELD),
            address: Yup.object().shape({
              addressLine1: Yup.string().nullable().required(REQUIRED_FIELD),
              city: Yup.string().nullable().required(REQUIRED_FIELD),
              state: Yup.string().nullable().required(REQUIRED_FIELD),
              postalCode: Yup.string().nullable().required(REQUIRED_FIELD),
            }),
          })
          .unique('phone', 'Duplicate phone number'),
      ),
  }),
});

const draiGt12Schema = () => {
  return Yup.object().shape({
    gt12Questions: Yup.object()
      .nullable()
      .when('signatureBool', {
        is: true,
        then: Yup.object()
          .nullable()
          .shape({
            q1: Yup.string().nullable().required(REQUIRED_FIELD),
            q2: Yup.string().nullable().required(REQUIRED_FIELD),
            q3: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q3a: Yup.string()
              .nullable()
              .when('q3', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q4a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q4ai: Yup.string()
              .nullable()
              .when('q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q4aii: Yup.string()
              .nullable()
              .when('q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q4aiii: Yup.string()
              .nullable()
              .when('q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q4b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q4bi: Yup.string()
              .nullable()
              .when('q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q4bii: Yup.string()
              .nullable()
              .when('q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q4biii: Yup.string()
              .nullable()
              .when('q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q5a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q5ai: Yup.string()
              .nullable()
              .when('q5a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q5aia: Yup.boolean()
              .nullable()
              .when('q5a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q5aii: Yup.string()
              .nullable()
              .when('q5aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q5aiii: Yup.string()
              .nullable()
              .when('q5aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q5b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q5bi: Yup.string()
              .nullable()
              .when('q5b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6ai: Yup.string()
              .nullable()
              .when('q6a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6aii: Yup.string()
              .nullable()
              .when('q6a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6bi: Yup.string()
              .nullable()
              .when('q6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6bii: Yup.string()
              .nullable()
              .when('q6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6ci: Yup.string()
              .nullable()
              .when('q6c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6cii: Yup.string()
              .nullable()
              .when('q6c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6di: Yup.string()
              .nullable()
              .when('q6d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6dii: Yup.string()
              .nullable()
              .when('q6d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6e: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6ei: Yup.string()
              .nullable()
              .when('q6e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6eii: Yup.string()
              .nullable()
              .when('q6e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6f: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6fi: Yup.string()
              .nullable()
              .when('q6f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6fii: Yup.string()
              .nullable()
              .when('q6f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6g: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6gi: Yup.string()
              .nullable()
              .when('q6g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6gii: Yup.string()
              .nullable()
              .when('q6g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6h: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6hi: Yup.string()
              .nullable()
              .when('q6h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6hii: Yup.string()
              .nullable()
              .when('q6h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6i: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6ii: Yup.string()
              .nullable()
              .when('q6i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6iii: Yup.string()
              .nullable()
              .when('q6i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6j: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6ji: Yup.string()
              .nullable()
              .when('q6j', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6jii: Yup.string()
              .nullable()
              .when('q6j', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6k: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6ki: Yup.string()
              .nullable()
              .when('q6k', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6kii: Yup.string()
              .nullable()
              .when('q6k', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6l: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6li: Yup.string()
              .nullable()
              .when('q6l', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6lii: Yup.string()
              .nullable()
              .when('q6l', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6m: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q6mi: Yup.string()
              .nullable()
              .when('q6m', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q6mii: Yup.string()
              .nullable()
              .when('q6m', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q7: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q7a: Yup.string()
              .nullable()
              .when('q7', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q7b: Yup.string()
              .nullable()
              .when('q7', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q8: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q8a: Yup.boolean()
              .nullable()
              .when('q8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q8ai: Yup.boolean()
              .nullable()
              .when('q8a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q8aia: Yup.boolean()
              .nullable()
              .when('q8ai', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q8aiai: Yup.string()
              .nullable()
              .when('q8aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q9: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q9a: Yup.string()
              .nullable()
              .when('q9', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q9b: Yup.string()
              .nullable()
              .when('q9', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q9c: Yup.string()
              .nullable()
              .when('q9', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q10: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q10a: Yup.string()
              .nullable()
              .when('q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q10b: Yup.string()
              .nullable()
              .when('q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q10c: Yup.boolean()
              .nullable()
              .when('q10', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q10ci: Yup.string()
              .nullable()
              .when('q10c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q10d: Yup.string()
              .nullable()
              .when('q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q10e: Yup.boolean()
              .nullable()
              .when('q10d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q10ei: Yup.string()
              .nullable()
              .when('q10e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q10eii: Yup.string()
              .nullable()
              .when('q10e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q11: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q11a: Yup.string()
              .nullable()
              .when('q11', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q11b: Yup.boolean()
              .nullable()
              .when('q11', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q11ai: Yup.string()
              .nullable()
              .when('q11b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q12: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q12a: Yup.string()
              .nullable()
              .when('q12', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q12b: Yup.string()
              .nullable()
              .when('q12', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q12c: Yup.boolean()
              .nullable()
              .when('q12', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q12bi: Yup.boolean()
              .nullable()
              .when('q12c', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q12bia: Yup.string()
              .nullable()
              .when('q12bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q12bii: Yup.string()
              .nullable()
              .when('q12bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q12biia: Yup.string()
              .nullable()
              .when('q12bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q13: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q13a: Yup.boolean()
              .nullable()
              .when('q13', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q13b: Yup.boolean()
              .nullable()
              .when('q13', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q13bi: Yup.string()
              .nullable()
              .when('q13b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q14: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q14a: Yup.boolean()
              .nullable()
              .when('q14', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q14b: Yup.boolean()
              .nullable()
              .when('q14', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q14bi: Yup.string()
              .nullable()
              .when('q14b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q15a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q15ai: Yup.string()
              .nullable()
              .when('q15a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q15aii: Yup.boolean()
              .nullable()
              .when('q15a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q15b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q15bi: Yup.string()
              .nullable()
              .when('q15b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q16: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q16a: Yup.string()
              .nullable()
              .when('q16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q16b: Yup.boolean()
              .nullable()
              .when('q16', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q17: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q17a: Yup.string()
              .nullable()
              .when('q17', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q17b: Yup.boolean()
              .nullable()
              .when('q17', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q18: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q18a: Yup.string()
              .nullable()
              .when('q18', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q19: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q19a: Yup.boolean()
              .nullable()
              .when('q19', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q19ai: Yup.string()
              .nullable()
              .when('q19a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q19bi: Yup.string()
              .nullable()
              .when('q19b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q19di: Yup.string()
              .nullable()
              .when('q19d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q19c: Yup.boolean()
              .nullable()
              .when('q19', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q19ci: Yup.string()
              .nullable()
              .when('q19c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q19e: Yup.boolean()
              .nullable()
              .when('q19', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q19ei: Yup.string()
              .nullable()
              .when('q19e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q19f: Yup.boolean()
              .nullable()
              .when('q19', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q19fi: Yup.boolean()
              .nullable()
              .when('q19f', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q19fia: Yup.string()
              .nullable()
              .when('q19fi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q19fii: Yup.boolean()
              .nullable()
              .when('q19fi', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q19fiii: Yup.string()
              .nullable()
              .when('q19f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q19g: Yup.boolean()
              .nullable()
              .when('q19', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q19gi: Yup.string()
              .nullable()
              .when('q19g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q19gii: Yup.boolean()
              .nullable()
              .when('q19g', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q20: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q20a: Yup.string()
              .nullable()
              .when('q20', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q20b: Yup.string()
              .nullable()
              .when('q20', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q20c: Yup.boolean()
              .nullable()
              .when('q20', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q20ci: Yup.string()
              .nullable()
              .when('q20c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q20d: Yup.boolean()
              .nullable()
              .when('q20', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q21: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q21a: Yup.string()
              .nullable()
              .when('q21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q21b: Yup.string()
              .nullable()
              .when('q21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q21c: Yup.string()
              .nullable()
              .when('q21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q21d: Yup.boolean()
              .nullable()
              .when('q21', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q21di: Yup.string()
              .nullable()
              .when('q21d', {
                is: false,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q22a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q22ai: Yup.string()
              .nullable()
              .when('q22a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q22b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q22bi: Yup.string()
              .nullable()
              .when('q22b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q23: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q23a: Yup.string()
              .nullable()
              .when('q23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q24: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q24a: Yup.string()
              .nullable()
              .when('q24', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q25: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q25a: Yup.string()
              .nullable()
              .when('q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q25b: Yup.string()
              .nullable()
              .when('q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q25c: Yup.string()
              .nullable()
              .when('q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q26: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q26a: Yup.string()
              .nullable()
              .when('q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q26b: Yup.string()
              .nullable()
              .when('q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q26c: Yup.boolean()
              .nullable()
              .when('q26', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q26ci: Yup.string()
              .nullable()
              .when('q26c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q26cii: Yup.string()
              .nullable()
              .when('q26c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q27: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q27a: Yup.boolean()
              .nullable()
              .when('q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q27ai: Yup.string()
              .nullable()
              .when('q27a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q27aii: Yup.string()
              .nullable()
              .when('q27a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q27aiii: Yup.boolean()
              .nullable()
              .when('q27a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q27aiia: Yup.string()
              .nullable()
              .when('q27aiii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q28: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q28a: Yup.string()
              .nullable()
              .when('q28', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q28b: Yup.string()
              .nullable()
              .when('q28', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q29a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q29ai: Yup.string()
              .nullable()
              .when('q29a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q29b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q29bi: Yup.string()
              .nullable()
              .when('q29b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q29c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q29ci: Yup.string()
              .nullable()
              .when('q29c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q29d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q29di: Yup.string()
              .nullable()
              .when('q29d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q30: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q30a: Yup.string()
              .nullable()
              .when('q30', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q30b: Yup.string()
              .nullable()
              .when('q30', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q31: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q31a: Yup.string()
              .nullable()
              .when('q31', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q31b: Yup.string()
              .nullable()
              .when('q31', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q32: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q32a: Yup.string()
              .nullable()
              .when('q32', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q32aii: Yup.boolean()
              .nullable()
              .when('q32', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q32ai: Yup.string()
              .nullable()
              .when('q32aii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q32b: Yup.string()
              .nullable()
              .when('q32', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q32c: Yup.string()
              .nullable()
              .when('q32', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q32d: Yup.boolean()
              .nullable()
              .when('q32', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q32di: Yup.string()
              .nullable()
              .when('q32d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q33: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q33a: Yup.string()
              .nullable()
              .when('q33', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q33aii: Yup.boolean()
              .nullable()
              .when('q33', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q33ai: Yup.string()
              .nullable()
              .when('q33aii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q33b: Yup.string()
              .nullable()
              .when('q33', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q33c: Yup.boolean()
              .nullable()
              .when('q33', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q33ci: Yup.string()
              .nullable()
              .when('q33c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q34a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q34ai: Yup.string()
              .nullable()
              .when('q34a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q34b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q34bi: Yup.boolean()
              .nullable()
              .when('q34b', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q34bia: Yup.string()
              .nullable()
              .when('q34bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q34bib: Yup.string()
              .nullable()
              .when('q34bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q35: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q35a: Yup.string()
              .nullable()
              .when('q35', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q35b: Yup.string()
              .nullable()
              .when('q35', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q35c: Yup.string()
              .nullable()
              .when('q35', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q35d: Yup.string()
              .nullable()
              .when('q35', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q36: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q36a: Yup.string()
              .nullable()
              .when('q36', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q36b: Yup.boolean()
              .nullable()
              .when('q36', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q36bi: Yup.string()
              .nullable()
              .when('q36b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q37a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q37ai: Yup.string()
              .nullable()
              .when('q37a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q37aii: Yup.string()
              .nullable()
              .when('q37a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q37b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q37bi: Yup.string()
              .nullable()
              .when('q37b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q37bii: Yup.string()
              .nullable()
              .when('q37b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q38: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q38a: Yup.string()
              .nullable()
              .when('q38', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q38b: Yup.string()
              .nullable()
              .when('q38', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q39: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q39a: Yup.string()
              .nullable()
              .when('q39', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q39b: Yup.string()
              .nullable()
              .when('q39', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q40: Yup.boolean().nullable().required(REQUIRED_FIELD),

            q40a: Yup.string()
              .nullable()
              .when('q40', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q41: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q41a: Yup.string()
              .nullable()
              .when('q41', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q41b: Yup.string()
              .nullable()
              .when('q41', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q42: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q42a: Yup.string()
              .nullable()
              .when('q42', {
                is: true,
                then: Yup.string()
                  .nullable()
                  .when('q42ana', {
                    is: false,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('q42ana', {
                    is: undefined,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('q42ana', {
                    is: null,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  }),
              }),
            q42b: Yup.string()
              .nullable()
              .when('q42', {
                is: true,
                then: Yup.string()
                  .nullable()
                  .when('q42bna', {
                    is: false,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('q42bna', {
                    is: undefined,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('q42bna', {
                    is: null,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  }),
              }),
            q42c: Yup.string()
              .nullable()
              .when('q42', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q42d: Yup.string()
              .nullable()
              .when('q42', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q43: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q43a: Yup.string()
              .nullable()
              .when('q43', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q43ai: Yup.boolean()
              .nullable()
              .when('q43', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q43aia: Yup.string()
              .nullable()
              .when('q43ai', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q43b: Yup.string()
              .nullable()
              .when('q43', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q44a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q44ai: Yup.string()
              .nullable()
              .when('q44a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q44b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q44bi: Yup.string()
              .nullable()
              .when('q44b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q45: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q45a: Yup.string()
              .nullable()
              .when('q45', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q46: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q46a: Yup.string()
              .nullable()
              .when('q46', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q47: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q47a: Yup.string()
              .nullable()
              .when('q47', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q48: Yup.boolean().nullable().required(REQUIRED_FIELD),
            q48a: Yup.string()
              .nullable()
              .when('q48', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q49: Yup.number().nullable().required(REQUIRED_FIELD),
            q49a: Yup.string()
              .nullable()
              .when('q49', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            q49b: Yup.boolean()
              .nullable()
              .when('q49', {
                is: 1,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            q49ai: Yup.string()
              .nullable()
              .when('q49b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
          }),
      }),
  });
};

const draiLe12Schema = () => {
  return Yup.object().shape({
    le12Questions: Yup.object()
      .nullable()
      .when('signatureBool', {
        is: true,
        then: Yup.object()
          .nullable()
          .shape({
            draiLe12q1: Yup.string().nullable().required(REQUIRED_FIELD),
            draiLe12q1b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q1a: Yup.boolean()
              .nullable()
              .when('draiLe12q1b', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q1ai: Yup.string()
              .nullable()
              .when('draiLe12q1a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q2: Yup.string().nullable().required(REQUIRED_FIELD),
            draiLe12q3a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3ai: Yup.string()
              .nullable()
              .when('draiLe12q3a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3aii: Yup.string()
              .nullable()
              .when('draiLe12q3a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3aiii: Yup.boolean()
              .nullable()
              .when('draiLe12q3a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3aiiia: Yup.string()
              .nullable()
              .when('draiLe12q3aiii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3aiv: Yup.string()
              .nullable()
              .when('draiLe12q3aiii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3bi: Yup.string()
              .nullable()
              .when('draiLe12q3b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3ci: Yup.string()
              .nullable()
              .when('draiLe12q3c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3di: Yup.string()
              .nullable()
              .when('draiLe12q3d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3dii: Yup.boolean()
              .nullable()
              .when('draiLe12q3d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3diia: Yup.string()
              .nullable()
              .when('draiLe12q3dii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3e: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3ei: Yup.string()
              .nullable()
              .when('draiLe12q3e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3eii: Yup.string()
              .nullable()
              .when('draiLe12q3e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3f: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3fi: Yup.string()
              .nullable()
              .when('draiLe12q3f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3fii: Yup.string()
              .nullable()
              .when('draiLe12q3f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3g: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3gi: Yup.string()
              .nullable()
              .when('draiLe12q3g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3h: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3hi: Yup.string()
              .nullable()
              .when('draiLe12q3h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3i: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3ii: Yup.string()
              .nullable()
              .when('draiLe12q3i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3iii: Yup.string()
              .nullable()
              .when('draiLe12q3i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q3j: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q3ji: Yup.string()
              .nullable()
              .when('draiLe12q3j', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q4a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q4ai: Yup.string()
              .nullable()
              .when('draiLe12q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q4aii: Yup.string()
              .nullable()
              .when('draiLe12q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q4aiii: Yup.string()
              .nullable()
              .when('draiLe12q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q4b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q4bi: Yup.string()
              .nullable()
              .when('draiLe12q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q4bii: Yup.string()
              .nullable()
              .when('draiLe12q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q4biii: Yup.string()
              .nullable()
              .when('draiLe12q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q5a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q5ai: Yup.string()
              .nullable()
              .when('draiLe12q5a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q5aia: Yup.boolean()
              .nullable()
              .when('draiLe12q5a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q5aii: Yup.string()
              .nullable()
              .when('draiLe12q5aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q5aiii: Yup.string()
              .nullable()
              .when('draiLe12q5aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q5b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q5bi: Yup.string()
              .nullable()
              .when('draiLe12q5b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6ai: Yup.string()
              .nullable()
              .when('draiLe12q6a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6aii: Yup.string()
              .nullable()
              .when('draiLe12q6a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6bi: Yup.string()
              .nullable()
              .when('draiLe12q6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6bii: Yup.string()
              .nullable()
              .when('draiLe12q6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6ci: Yup.string()
              .nullable()
              .when('draiLe12q6c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6cii: Yup.string()
              .nullable()
              .when('draiLe12q6c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6di: Yup.string()
              .nullable()
              .when('draiLe12q6d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6dii: Yup.string()
              .nullable()
              .when('draiLe12q6d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6e: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6ei: Yup.string()
              .nullable()
              .when('draiLe12q6e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6eii: Yup.string()
              .nullable()
              .when('draiLe12q6e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6f: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6fi: Yup.string()
              .nullable()
              .when('draiLe12q6f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6fii: Yup.string()
              .nullable()
              .when('draiLe12q6f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6g: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6gi: Yup.string()
              .nullable()
              .when('draiLe12q6g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6gii: Yup.string()
              .nullable()
              .when('draiLe12q6g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6h: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6hi: Yup.string()
              .nullable()
              .when('draiLe12q6h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6hii: Yup.string()
              .nullable()
              .when('draiLe12q6h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6i: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6ii: Yup.string()
              .nullable()
              .when('draiLe12q6i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6iii: Yup.string()
              .nullable()
              .when('draiLe12q6i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6j: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6ji: Yup.string()
              .nullable()
              .when('draiLe12q6j', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6jii: Yup.string()
              .nullable()
              .when('draiLe12q6j', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6k: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q6ki: Yup.string()
              .nullable()
              .when('draiLe12q6k', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q6kii: Yup.string()
              .nullable()
              .when('draiLe12q6k', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q7: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q7a: Yup.boolean()
              .nullable()
              .when('draiLe12q7', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q7ai: Yup.boolean()
              .nullable()
              .when('draiLe12q7a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q7aia: Yup.boolean()
              .nullable()
              .when('draiLe12q7ai', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q7aiai: Yup.string()
              .nullable()
              .when('draiLe12q7aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q8: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q8a: Yup.string()
              .nullable()
              .when('draiLe12q8', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q8b: Yup.string()
              .nullable()
              .when('draiLe12q8', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q8c: Yup.boolean()
              .nullable()
              .when('draiLe12q8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q8ci: Yup.string()
              .nullable()
              .when('draiLe12q8c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q8d: Yup.boolean()
              .nullable()
              .when('draiLe12q8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q8e: Yup.boolean()
              .nullable()
              .when('draiLe12q8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q8ei: Yup.string()
              .nullable()
              .when('draiLe12q8e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q8eii: Yup.string()
              .nullable()
              .when('draiLe12q8e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q9: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q9a: Yup.string()
              .nullable()
              .when('draiLe12q9', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q9b: Yup.boolean()
              .nullable()
              .when('draiLe12q9', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q9ai: Yup.string()
              .nullable()
              .when('draiLe12q9b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q10: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q10a: Yup.string()
              .nullable()
              .when('draiLe12q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q10b: Yup.string()
              .nullable()
              .when('draiLe12q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q10c: Yup.boolean()
              .nullable()
              .when('draiLe12q10', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q10bi: Yup.boolean()
              .nullable()
              .when('draiLe12q10c', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q10bia: Yup.string()
              .nullable()
              .when('draiLe12q10bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q10bii: Yup.string()
              .nullable()
              .when('draiLe12q10c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q10biia: Yup.string()
              .nullable()
              .when('draiLe12q10c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q11: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q11a: Yup.string()
              .nullable()
              .when('draiLe12q11', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q11d: Yup.boolean()
              .nullable()
              .when('draiLe12q11', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q11b: Yup.boolean()
              .nullable()
              .when('draiLe12q11d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q11c: Yup.boolean()
              .nullable()
              .when('draiLe12q11d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q11ci: Yup.string()
              .nullable()
              .when('draiLe12q11d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q12: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q12a: Yup.string()
              .nullable()
              .when('draiLe12q12', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q12d: Yup.string()
              .nullable()
              .when('draiLe12q12', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q12b: Yup.boolean()
              .nullable()
              .when('draiLe12q12d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q12c: Yup.boolean()
              .nullable()
              .when('draiLe12q12d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q12ci: Yup.string()
              .nullable()
              .when('draiLe12q12c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q13a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q13ai: Yup.string()
              .nullable()
              .when('draiLe12q13a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q13aia: Yup.boolean()
              .nullable()
              .when('draiLe12q13a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q13aii: Yup.string()
              .nullable()
              .when('draiLe12q13aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q13aiii: Yup.string()
              .nullable()
              .when('draiLe12q13aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q13b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q13bi: Yup.string()
              .nullable()
              .when('draiLe12q13b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q14: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q14a: Yup.string()
              .nullable()
              .when('draiLe12q14', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q14b: Yup.boolean()
              .nullable()
              .when('draiLe12q14', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q15: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q15a: Yup.string()
              .nullable()
              .when('draiLe12q15', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q15b: Yup.boolean()
              .nullable()
              .when('draiLe12q15', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q16: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q16a: Yup.string()
              .nullable()
              .when('draiLe12q16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q16b: Yup.string()
              .nullable()
              .when('draiLe12q16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q16c: Yup.string()
              .nullable()
              .when('draiLe12q16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q16d: Yup.boolean()
              .nullable()
              .when('draiLe12q16', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q16di: Yup.string()
              .nullable()
              .when('draiLe12q16d', {
                is: false,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q17: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q17a: Yup.string()
              .nullable()
              .when('draiLe12q17', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q17b: Yup.string()
              .nullable()
              .when('draiLe12q17', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q17c: Yup.string()
              .nullable()
              .when('draiLe12q17', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q18: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q18a: Yup.string()
              .nullable()
              .when('draiLe12q18', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q18b: Yup.string()
              .nullable()
              .when('draiLe12q18', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q18c: Yup.boolean()
              .nullable()
              .when('draiLe12q18', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q18ci: Yup.string()
              .nullable()
              .when('draiLe12q18c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q18cii: Yup.string()
              .nullable()
              .when('draiLe12q18c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q19a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q19ai: Yup.string()
              .nullable()
              .when('draiLe12q19a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q19b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q19bi: Yup.string()
              .nullable()
              .when('draiLe12q19b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q20a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q20ai: Yup.string()
              .nullable()
              .when('draiLe12q20a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q20b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q20bi: Yup.string()
              .nullable()
              .when('draiLe12q20b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q20c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q20ci: Yup.string()
              .nullable()
              .when('draiLe12q20c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q20d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q20di: Yup.string()
              .nullable()
              .when('draiLe12q20d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q20e: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q20ei: Yup.string()
              .nullable()
              .when('draiLe12q20e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q21: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q21a: Yup.string()
              .nullable()
              .when('draiLe12q21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q21b: Yup.string()
              .nullable()
              .when('draiLe12q21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q22: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q22a: Yup.string()
              .nullable()
              .when('draiLe12q22', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q22b: Yup.string()
              .nullable()
              .when('draiLe12q22', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),

            draiLe12q23: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q23a: Yup.string()
              .nullable()
              .when('draiLe12q23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q23aii: Yup.boolean()
              .nullable()
              .when('draiLe12q23', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q23ai: Yup.string()
              .nullable()
              .when('draiLe12q23aii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q23b: Yup.string()
              .nullable()
              .when('draiLe12q23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q23c: Yup.string()
              .nullable()
              .when('draiLe12q23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q23d: Yup.boolean()
              .nullable()
              .when('draiLe12q23', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q23di: Yup.string()
              .nullable()
              .when('draiLe12q23d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q24: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q24a: Yup.string()
              .nullable()
              .when('draiLe12q24', {
                is: true,
                then: Yup.string()
                  .nullable()
                  .when('draiLe12q24ana', {
                    is: false,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('draiLe12q24ana', {
                    is: undefined,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('draiLe12q24ana', {
                    is: null,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  }),
              }),
            draiLe12q24b: Yup.string()
              .nullable()
              .when('draiLe12q24', {
                is: true,
                then: Yup.string()
                  .nullable()
                  .when('draiLe12q24bna', {
                    is: false,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('draiLe12q24bna', {
                    is: undefined,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('draiLe12q24bna', {
                    is: null,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  }),
              }),
            draiLe12q24c: Yup.string()
              .nullable()
              .when('draiLe12q24', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q24d: Yup.string()
              .nullable()
              .when('draiLe12q24', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q25: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q25a: Yup.string()
              .nullable()
              .when('draiLe12q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q25ai: Yup.boolean()
              .nullable()
              .when('draiLe12q25', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q25aii: Yup.string()
              .nullable()
              .when('draiLe12q25ai', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q25b: Yup.string()
              .nullable()
              .when('draiLe12q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q26: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q26a: Yup.string()
              .nullable()
              .when('draiLe12q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q26b: Yup.string()
              .nullable()
              .when('draiLe12q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q26c: Yup.string()
              .nullable()
              .when('draiLe12q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q27a: Yup.string()
              .nullable()
              .when('draiLe12q27', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27b: Yup.boolean()
              .nullable()
              .when('draiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27c: Yup.number()
              .nullable()
              .when('draiLe12q27', {
                is: true,
                then: Yup.number().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27ci: Yup.boolean()
              .nullable()
              .when('draiLe12q27c', {
                is: 2,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27cii: Yup.string()
              .nullable()
              .when('draiLe12q27ci', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27d: Yup.boolean()
              .nullable()
              .when('draiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27di: Yup.string()
              .nullable()
              .when('draiLe12q27d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27e: Yup.boolean()
              .nullable()
              .when('draiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27ei: Yup.string()
              .nullable()
              .when('draiLe12q27e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27f: Yup.boolean()
              .nullable()
              .when('draiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27fi: Yup.string()
              .nullable()
              .when('draiLe12q27f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27g: Yup.boolean()
              .nullable()
              .when('draiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27gi: Yup.string()
              .nullable()
              .when('draiLe12q27g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27gii: Yup.string()
              .nullable()
              .when('draiLe12q27g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27h: Yup.boolean()
              .nullable()
              .when('draiLe12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27i: Yup.number()
              .nullable()
              .when('draiLe12q27', {
                is: true,
                then: Yup.number().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27ii: Yup.string()
              .nullable()
              .when('draiLe12q27i', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q27To28: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q28: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q28a: Yup.string()
              .nullable()
              .when('draiLe12q28', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q28b: Yup.string()
              .nullable()
              .when('draiLe12q28', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q28c: Yup.string()
              .nullable()
              .when('draiLe12q28', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q28d: Yup.string()
              .nullable()
              .when('draiLe12q28', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q28To29: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q29: Yup.boolean()
              .nullable()
              .when('draiLe12q28To29', {
                is: true,
                then: Yup.boolean()
                  .nullable()
                  .required('This field is required.'),
              }),
            draiLe12q29a: Yup.string()
              .nullable()
              .when('draiLe12q29', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q29b: Yup.string()
              .nullable()
              .when('draiLe12q29', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q29bi: Yup.string()
              .nullable()
              .when('draiLe12q29b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q30: Yup.boolean()
              .nullable()
              .when('draiLe12q28To29', {
                is: true,
                then: Yup.boolean()
                  .nullable()
                  .required('This field is required.'),
              }),
            draiLe12q30a: Yup.string()
              .nullable()
              .when('draiLe12q30', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q30aii: Yup.boolean()
              .nullable()
              .when('draiLe12q30', {
                is: true,
                then: Yup.boolean()
                  .nullable()
                  .required('Thisf ield is required.'),
              }),
            draiLe12q30ai: Yup.string()
              .nullable()
              .when('draiLe12q30aii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q30b: Yup.string()
              .nullable()
              .when('draiLe12q30aii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q30c: Yup.boolean()
              .nullable()
              .when('draiLe12q30aii', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q30ci: Yup.string()
              .nullable()
              .when('draiLe12q30c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q31: Yup.boolean()
              .nullable()
              .when('draiLe12q28To29', {
                is: true,
                then: Yup.boolean()
                  .nullable()
                  .required('This field is required.'),
              }),
            draiLe12q31a: Yup.string()
              .nullable()
              .when('draiLe12q31', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q31b: Yup.string()
              .nullable()
              .when('draiLe12q31', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q31c: Yup.string()
              .nullable()
              .when('draiLe12q31', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q31d: Yup.string()
              .nullable()
              .when('draiLe12q31', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q32a: Yup.boolean()
              .nullable()
              .when('draiLe12q28To29', {
                is: true,
                then: Yup.boolean()
                  .nullable()
                  .required('This field is required.'),
              }),
            draiLe12q32ai: Yup.string()
              .nullable()
              .when('draiLe12q32a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q32b: Yup.boolean()
              .nullable()
              .when('draiLe12q28To29', {
                is: true,
                then: Yup.boolean()
                  .nullable()
                  .required('This field is required.'),
              }),
            draiLe12q32bi: Yup.string()
              .nullable()
              .when('draiLe12q32b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q33: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q33a: Yup.string()
              .nullable()
              .when('draiLe12q33', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q34: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q34a: Yup.string()
              .nullable()
              .when('draiLe12q34', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q35: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q35a: Yup.string()
              .nullable()
              .when('draiLe12q35', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            draiLe12q36: Yup.boolean().nullable().required(REQUIRED_FIELD),
            draiLe12q36a: Yup.string()
              .nullable()
              .when('draiLe12q36', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
          }),
      }),
  });
};

const draiBirthMotherSchema = () => {
  return Yup.object().shape({
    birthMotherQuestions: Yup.object()
      .nullable()
      .when('signatureBool', {
        is: true,
        then: Yup.object()
          .nullable()
          .shape({
            birthMotherQ1: Yup.string().nullable().required(REQUIRED_FIELD),
            birthMotherQ2a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ2ai: Yup.string()
              .nullable()
              .when('birthMotherQ2a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ2aii: Yup.string()
              .nullable()
              .when('birthMotherQ2a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ2aiii: Yup.string()
              .nullable()
              .when('birthMotherQ2a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ2b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ2bi: Yup.string()
              .nullable()
              .when('birthMotherQ2b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ2bii: Yup.string()
              .nullable()
              .when('birthMotherQ2b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ2biii: Yup.string()
              .nullable()
              .when('birthMotherQ2b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ3ai: Yup.string()
              .nullable()
              .when('birthMotherQ3a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3aii: Yup.string()
              .nullable()
              .when('birthMotherQ3a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ3bi: Yup.string()
              .nullable()
              .when('birthMotherQ3b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3bii: Yup.string()
              .nullable()
              .when('birthMotherQ3b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ3ci: Yup.string()
              .nullable()
              .when('birthMotherQ3c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3cii: Yup.string()
              .nullable()
              .when('birthMotherQ3c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ3di: Yup.string()
              .nullable()
              .when('birthMotherQ3d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3dii: Yup.string()
              .nullable()
              .when('birthMotherQ3d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3e: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ3ei: Yup.string()
              .nullable()
              .when('birthMotherQ3e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3eii: Yup.string()
              .nullable()
              .when('birthMotherQ3e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3f: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ3fi: Yup.string()
              .nullable()
              .when('birthMotherQ3f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3fii: Yup.string()
              .nullable()
              .when('birthMotherQ3f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3g: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ3gi: Yup.string()
              .nullable()
              .when('birthMotherQ3g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3gii: Yup.string()
              .nullable()
              .when('birthMotherQ3g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3h: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ3hi: Yup.string()
              .nullable()
              .when('birthMotherQ3h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ3hii: Yup.string()
              .nullable()
              .when('birthMotherQ3h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ4: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ4a: Yup.string()
              .nullable()
              .when('birthMotherQ4', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ4b: Yup.string()
              .nullable()
              .when('birthMotherQ4', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ4c: Yup.string()
              .nullable()
              .when('birthMotherQ4', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ5: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ5a: Yup.string()
              .nullable()
              .when('birthMotherQ5', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ5b: Yup.string()
              .nullable()
              .when('birthMotherQ5', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ5c: Yup.boolean()
              .nullable()
              .when('birthMotherQ5', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ5ci: Yup.string()
              .nullable()
              .when('birthMotherQ5c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ5d: Yup.string()
              .nullable()
              .when('birthMotherQ5', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ5e: Yup.boolean()
              .nullable()
              .when('birthMotherQ5', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ5ei: Yup.string()
              .nullable()
              .when('birthMotherQ5e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ5eii: Yup.string()
              .nullable()
              .when('birthMotherQ5e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ6: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ6a: Yup.string()
              .nullable()
              .when('birthMotherQ6', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ6b: Yup.boolean()
              .nullable()
              .when('birthMotherQ6', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ6ai: Yup.string()
              .nullable()
              .when('birthMotherQ6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ7: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ7a: Yup.string()
              .nullable()
              .when('birthMotherQ7', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ7b: Yup.string()
              .nullable()
              .when('birthMotherQ7', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ7c: Yup.boolean()
              .nullable()
              .when('birthMotherQ7', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ7bia: Yup.string()
              .nullable()
              .when('birthMotherQ7c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ7bii: Yup.string()
              .nullable()
              .when('birthMotherQ7c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ7biia: Yup.string()
              .nullable()
              .when('birthMotherQ7c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ8: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ8a: Yup.boolean()
              .nullable()
              .when('birthMotherQ8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ8b: Yup.boolean()
              .nullable()
              .when('birthMotherQ8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ8bi: Yup.string()
              .nullable()
              .when('birthMotherQ8b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ9: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ9a: Yup.boolean()
              .nullable()
              .when('birthMotherQ9', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ9b: Yup.boolean()
              .nullable()
              .when('birthMotherQ9', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ9bi: Yup.string()
              .nullable()
              .when('birthMotherQ9b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ10: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ10a: Yup.string()
              .nullable()
              .when('birthMotherQ10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ10b: Yup.string()
              .nullable()
              .when('birthMotherQ10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ11: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ11a: Yup.string()
              .nullable()
              .when('birthMotherQ11', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ11b: Yup.boolean()
              .nullable()
              .when('birthMotherQ11', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ12: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ12a: Yup.string()
              .nullable()
              .when('birthMotherQ12', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ12b: Yup.boolean()
              .nullable()
              .when('birthMotherQ12', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ13: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ13a: Yup.string()
              .nullable()
              .when('birthMotherQ13', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ14ai: Yup.string()
              .nullable()
              .when('birthMotherQ14a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ14bi: Yup.string()
              .nullable()
              .when('birthMotherQ14b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ14ci: Yup.string()
              .nullable()
              .when('birthMotherQ14c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ14di: Yup.string()
              .nullable()
              .when('birthMotherQ14d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14e: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ14ei: Yup.boolean()
              .nullable()
              .when('birthMotherQ14e', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14eia: Yup.string()
              .nullable()
              .when('birthMotherQ14ei', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14eii: Yup.boolean()
              .nullable()
              .when('birthMotherQ14e', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14eiii: Yup.string()
              .nullable()
              .when('birthMotherQ14e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14f: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ14fi: Yup.string()
              .nullable()
              .when('birthMotherQ14f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ14fii: Yup.boolean()
              .nullable()
              .when('birthMotherQ14f', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ15: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ15a: Yup.string()
              .nullable()
              .when('birthMotherQ15', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ15b: Yup.string()
              .nullable()
              .when('birthMotherQ15', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ15c: Yup.boolean()
              .nullable()
              .when('birthMotherQ15', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ15ci: Yup.string()
              .nullable()
              .when('birthMotherQ15c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ15d: Yup.boolean()
              .nullable()
              .when('birthMotherQ15', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ16: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ16a: Yup.string()
              .nullable()
              .when('birthMotherQ16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ16b: Yup.string()
              .nullable()
              .when('birthMotherQ16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ16c: Yup.string()
              .nullable()
              .when('birthMotherQ16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ16d: Yup.boolean()
              .nullable()
              .when('birthMotherQ16', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ16di: Yup.string()
              .nullable()
              .when('birthMotherQ16d', {
                is: false,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ17a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ17ai: Yup.string()
              .nullable()
              .when('birthMotherQ17a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ17b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ17bi: Yup.string()
              .nullable()
              .when('birthMotherQ17b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ18: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ18a: Yup.string()
              .nullable()
              .when('birthMotherQ18', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ19: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ19a: Yup.string()
              .nullable()
              .when('birthMotherQ19', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ19b: Yup.string()
              .nullable()
              .when('birthMotherQ19', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ20a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ20ai: Yup.string()
              .nullable()
              .when('birthMotherQ20a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ20b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ20bi: Yup.string()
              .nullable()
              .when('birthMotherQ20b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ20c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ20ci: Yup.string()
              .nullable()
              .when('birthMotherQ20c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ20d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ20di: Yup.string()
              .nullable()
              .when('birthMotherQ20d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ21: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ21a: Yup.string()
              .nullable()
              .when('birthMotherQ21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ21b: Yup.string()
              .nullable()
              .when('birthMotherQ21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ22: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ22a: Yup.string()
              .nullable()
              .when('birthMotherQ22', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ22b: Yup.string()
              .nullable()
              .when('birthMotherQ22', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ23: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ23a: Yup.string()
              .nullable()
              .when('birthMotherQ23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ23b: Yup.string()
              .nullable()
              .when('birthMotherQ23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ24: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ24a: Yup.string()
              .nullable()
              .when('birthMotherQ24', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ25: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ25a: Yup.string()
              .nullable()
              .when('birthMotherQ25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ26: Yup.boolean().nullable().required(REQUIRED_FIELD),
            birthMotherQ26a: Yup.string()
              .nullable()
              .when('birthMotherQ26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ27: Yup.number().nullable().required(REQUIRED_FIELD),
            birthMotherQ27a: Yup.string()
              .nullable()
              .when('birthMotherQ27', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ27ai: Yup.boolean()
              .nullable()
              .when('birthMotherQ27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ27aia: Yup.string()
              .nullable()
              .when('birthMotherQ27ai', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ28a: Yup.number().nullable().required(REQUIRED_FIELD),
            birthMotherQ28ai: Yup.string()
              .nullable()
              .when('birthMotherQ28a', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ28aia: Yup.boolean()
              .nullable()
              .when('birthMotherQ28a', {
                is: 1,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ28aii: Yup.string()
              .nullable()
              .when('birthMotherQ28aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ28b: Yup.number().nullable().required(REQUIRED_FIELD),
            birthMotherQ28bi: Yup.string()
              .nullable()
              .when('birthMotherQ28b', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ28bii: Yup.boolean()
              .nullable()
              .when('birthMotherQ28b', {
                is: 1,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ28bia: Yup.string()
              .nullable()
              .when('birthMotherQ28bii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ28bib: Yup.boolean()
              .nullable()
              .when('birthMotherQ28bii', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            birthMotherQ28bic: Yup.string()
              .nullable()
              .when('birthMotherQ28bib', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
          }),
      }),
  });
};

const draiInfectDiseaseAddendumSchema = () => {
  return Yup.object().shape({
    addendum: Yup.object()
      .nullable()
      .when('signatureBool', {
        is: true,
        then: Yup.object()
          .nullable()
          .shape({
            ebolaQe1a: Yup.string()
              .nullable()
              .when('ebolaQe1', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            ebolaQe2a: Yup.string()
              .nullable()
              .when('ebolaQe2', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            zikaQz1a: Yup.string()
              .nullable()
              .when('zikaQz1', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            zikaQz1b: Yup.string()
              .nullable()
              .when('zikaQz1', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            zikaQz2ai: Yup.string()
              .nullable()
              .when('zikaQz2a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            zikaQz2aii: Yup.string()
              .nullable()
              .when('zikaQz2a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            zikaQz2bi: Yup.string()
              .nullable()
              .when('zikaQz2b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            zikaQz2bii: Yup.string()
              .nullable()
              .when('zikaQz2b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            covidQc1a: Yup.string()
              .nullable()
              .when('covidQc1', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            covidQc1b: Yup.string()
              .nullable()
              .when('covidQc1', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            covidQc2a: Yup.string()
              .nullable()
              .when('covidQc2', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
          }),
      }),
  });
};

export const draiQuestionFormSchema = ({ type, fieldVersions }) => {
  switch (type) {
    case 'uniformDraiGt12': {
      return draiGt12Schema(fieldVersions);
    }
    case 'uniformDraiLe12': {
      return draiLe12Schema(fieldVersions);
    }
    case 'uniformDraiBirthMother': {
      return draiBirthMotherSchema(fieldVersions);
    }
    case 'eyeOnlyUniformDraiLe12': {
      return eyeOnlyDraiLe12Schema(fieldVersions);
    }
    case 'eyeOnlyUniformDraiGt12': {
      return eyeDraiGt12Schema(fieldVersions);
    }
    case 'eyeOnlyBirthMother': {
      return eyeOnlyBirthMotherSchema(fieldVersions);
    }
    case 'addendum': {
      return draiInfectDiseaseAddendumSchema(fieldVersions);
    }
    default:
      return null;
  }
};
