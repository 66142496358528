import './assets/css/global.css';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { create } from 'jss';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { createTheme } from 'src/theme';
import Auth from 'src/components/Auth';
import ScrollReset from 'src/components/ScrollReset';
import Routing from 'src/Routing';
import OnlineDetection from './components/OnlineDetection';
import { BroadcastProvider } from './context/BroadcastContext';
import RefreshController from './components/RefreshController';
import AccessLogger from './components/AccessLogger';

const jss = create({ plugins: [...jssPreset().plugins] });

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme}>
        <StylesProvider jss={jss}>
          <SnackbarProvider maxSnack={1}>
            <Router>
              <BroadcastProvider>
                <RefreshController />
                <AccessLogger />
                <Auth>
                  <OnlineDetection />
                  <ScrollReset />
                  <Routing />
                </Auth>
              </BroadcastProvider>
            </Router>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Sentry.withProfiler(App);
