import axios from 'src/utils/axios';

export const GET_DRAIS = '@drai/get-drais';
export const SET_CURRENT_DRAI_FORM = '@drai/set-current-drai-form';

export function getDRAIs(caseId) {
  const request = axios.get(`/public/drai/${caseId}`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_DRAIS,
        payload: response.data,
      }),
    );
  };
}

export function setCurrentDRAIForm(form) {
  return {
    type: SET_CURRENT_DRAI_FORM,
    payload: { form },
  };
}
