import axios from 'src/utils/axios';

export const GET_LABELS = '@notes/get-labels';
export const GET_NOTES = '@notes/get-notes';
export const GET_NOTE = '@notes/get-note';
export const OPEN_SIDEBAR = '@notes/open-sidebar';
export const CLOSE_SIDEBAR = '@notes/close-sidebar';
export const OPEN_NEW_MESSAGE = '@notes/open-new-message';
export const CLOSE_NEW_MESSAGE = '@notes/close-new-message';
export const SAVE_NOTE = '@notes/save-note';
export const DELETE_NOTE = '@notes/delete-note';
export const FILTER_NOTES = '@notes/filter-notes';
export const FILTER_CASE_NOTES = '@notes/filter-notes';
export const SAVE_NOTE_TEMPLATE = '@notes/save-note-template';
export const GET_NOTE_TEMPLATES = '@notes/get-note-template';
export const TEMPLATE_DIALOG_OPEN = '@notes/template-dialog-open';
export const DELETE_TEMPLATE = '@notes/delete-template';
export const OPEN_CASE_NOTES_LIST = '@notes/open-case-notes-list';
export const CLOSE_CASE_NOTES_LIST = '@notes/close-case-notes-list';
export const OPEN_CASE_NOTE_DETAILS = '@notes/open-case-note-details';
export const CLOSE_CASE_NOTE_DETAILS = '@notes/close-case-note-details';
export const GET_CASE_NOTES = '@notes/get-case-notes';
export const GET_ORG_NOTES = '@notes/get-org-notes';
export const CLEAR_ORG_NOTES = '@notes/clear-org-notes';

export function getLabels() {
  return {
    type: GET_LABELS,
    payload: {
      labels: [
        {
          id: 'all',
          type: 'system_label',
          name: 'All Notes',
        },
        {
          id: 'drafts',
          type: 'system_label',
          name: 'Draft Notes',
          unreadCount: 0,
          totalCount: 0,
        },
        {
          id: 'trash',
          type: 'system_label',
          name: 'Trash',
          unreadCount: 0,
          totalCount: 1,
        },
      ],
    },
  };
}

export function getNotes(params) {
  const request = axios.get('/public/notes', {
    params,
  });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_NOTES,
        payload: response.data,
      }),
    );
  };
}

export function getCaseNotes(caseId) {
  const request = axios.get(`/public/caseNotes/${caseId}`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_CASE_NOTES,
        payload: response.data,
      }),
    );
  };
}

export function getOrgNotes(orgId) {
  const request = axios.get(`/public/orgNotes/${orgId}`);
  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_ORG_NOTES,
        payload: response.data,
      }),
    );
  };
}

export function clearOrgNotes() {
  return {
    type: CLEAR_ORG_NOTES,
  };
}

export function getNote(noteId) {
  const request = axios.get(`/public/notes/${noteId}`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_NOTE,
        payload: response.data,
      }),
    );
  };
}

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR,
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR,
  };
}

export function openNewMessage() {
  return {
    type: OPEN_NEW_MESSAGE,
  };
}

export function closeNewMessage() {
  return {
    type: CLOSE_NEW_MESSAGE,
  };
}

export function openCaseNotesList() {
  return {
    type: OPEN_CASE_NOTES_LIST,
  };
}

export function closeCaseNotesList() {
  return {
    type: CLOSE_CASE_NOTES_LIST,
  };
}

export function openCaseNoteDetails(noteId) {
  return {
    type: OPEN_CASE_NOTE_DETAILS,
    payload: noteId,
  };
}

export function closeCaseNoteDetails() {
  return {
    type: CLOSE_CASE_NOTE_DETAILS,
  };
}

export function deleteNote(noteId) {
  const request = axios.delete(`/public/notes/${noteId}`);

  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: DELETE_NOTE,
        payload: response.data,
      });
    });
  };
}

export function notesFilter(filterString) {
  return {
    type: FILTER_NOTES,
    payload: filterString,
  };
}

export function caseNotesFilter(filterString) {
  return {
    type: FILTER_CASE_NOTES,
    payload: filterString,
  };
}

export function templateDialogOpen(dialogOpen) {
  return {
    type: TEMPLATE_DIALOG_OPEN,
    payload: dialogOpen,
  };
}

export function saveNoteTemplate(templateText) {
  const request = axios.post('/public/noteTemplates', templateText);

  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: SAVE_NOTE_TEMPLATE,
        payload: response.data,
      });
    });
  };
}

export function loadNoteTemplates() {
  const request = axios.get('/public/noteTemplates');

  return (dispatch) => {
    dispatch({
      type: GET_NOTE_TEMPLATES,
      payload: { noteTemplates: [], isLoading: true },
    });
    request.then((response) =>
      dispatch({
        type: GET_NOTE_TEMPLATES,
        payload: { ...response.data, isLoading: false },
      }),
    );
  };
}

export function deleteTemplate(templateId) {
  const request = axios.delete(`/public/noteTemplates/${templateId}`);

  return (dispatch) => {
    request.then(() =>
      dispatch({
        type: DELETE_TEMPLATE,
        payload: templateId,
      }),
    );
  };
}
