import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Key as KeyIcon, PlusCircle as PlusCircleIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import LogoSmall from 'src/components/LogoSmall';
import { THEMES } from 'src/constants';
import { clearCase } from 'src/actions/caseActions';
import { useDispatch, useSelector } from 'react-redux';
import { Permission } from 'src/components';
import { useState } from 'react';
import Account from './Account';
import Notifications from './Notifications';
import Settings from './Settings';
import Search from './Search';
import LinkButton from './LinkButton';
import packageJson from '../../../../package.json';
import MobileDrawer from '../MobileDrawer';

const { version } = packageJson;

const versionsMatch = (actual, environment) => {
  const [actualMajor, actualMinor, actualPatch] = actual?.split('.') || [];
  const [envMajor, envMinor, envPatch] = environment?.split('.') || [];
  if (actualMajor >= envMajor) {
    if (actualMinor >= envMinor) {
      if (actualPatch >= envPatch) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  secondToolbar: {
    minHeight: 50,
    backgroundColor: 'gray',
  },
  versionNumber: {
    marginLeft: 5,
  },
  link: {
    color: 'inherit',
  },

  mobileToolbar: {
    position: 'absolute',
    width: '100vw',
    left: 0,
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },

  leftInnerMobile: {
    width: '33%',
  },

  centerMobile: {
    width: '34%',
    textAlign: 'center',
  },

  rightInnerMobile: {
    display: 'flex',
    flexDirection: 'row',
    width: '33%',
    justifyContent: 'end',
  },

  newText: {
    borderRadius: '5px',
    fontSize: '13px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.alternative.main,
    padding: '4px 5px',
    textTransform: 'initial',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  vBar: {
    margin: '0px 10px',
    height: '40px',
    border: '1px solid white',
    color: theme.palette.common.white,
  },
}));

const INIT_NESTED = {
  caseOpen: false,
  recoveryOpen: false,
  medReviewOpen: false,
  approvalsOpen: false,
  transmitOpen: false,
  dashboardOpen: false,
};

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.config);
  const [drawer, setDrawer] = useState(false);
  const [nestedMenus, setNestedMenus] = useState({ ...INIT_NESTED });

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Typography
            className={classes.versionNumber}
            variant="caption"
            color="inherit"
          >
            v{version}/v{config.backendVersion}{' '}
          </Typography>
          {!versionsMatch(version, config.frontendVersion) ? (
            <>
              <div className={classes.vBar} />
              <Button
                onClick={() => window.location.reload()}
                className={classes.newText}
              >
                A new version is available. Click to refresh.
              </Button>
            </>
          ) : null}
        </Hidden>

        {/* mobile  topBar */}
        <Hidden lgUp>
          <Box
            className={classes.mobileToolbar}
            px={1}
          >
            <Box className={classes.leftInnerMobile}>
              <MobileDrawer
                drawer={drawer}
                setDrawer={setDrawer}
                nestedMenus={nestedMenus}
                setNestedMenus={setNestedMenus}
              />
            </Box>
            <Box className={classes.centerMobile}>
              <RouterLink to="/">
                <LogoSmall
                  onClick={() => {
                    setDrawer(false);
                    setNestedMenus({ ...INIT_NESTED });
                  }}
                />
              </RouterLink>
            </Box>
            <Box className={classes.rightInnerMobile}>
              <Permission permit="read:cases">
                <Search
                  initNested={INIT_NESTED}
                  setNestedMenus={setNestedMenus}
                  setDrawer={setDrawer}
                />
              </Permission>
              <Notifications />
            </Box>
          </Box>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <LinkButton
          to="/admin"
          icon={<KeyIcon />}
          toolTip="Admin"
          hidden
        />
        {/* end mobile topbar */}

        <Hidden lgDown>
          <Permission permit="create:referralWorksheet">
            <LinkButton
              to="/case/worksheet"
              onClick={() => dispatch(clearCase())}
              icon={<PlusCircleIcon />}
              toolTip="New Case"
            />
          </Permission>
          <Permission permit="read:cases">
            <Search
              initNested={INIT_NESTED}
              setNestedMenus={setNestedMenus}
              setDrawer={setDrawer}
            />
          </Permission>
          <Hidden lgDown>
            <Notifications />
            <Settings />
          </Hidden>
          <Box ml={2}>
            <Account />
          </Box>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
