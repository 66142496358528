import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';

import packageJson from '../../../../package.json';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    height: '54px',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(1),
    color: 'white',
  },
}));
function MobileFooter() {
  const { version } = packageJson;
  const { config } = useSelector((state) => state.config);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Typography variant="caption">
          v{version}/v{config.backendVersion}
          <br />
          Copyright © 2021 CorneaGen
        </Typography>
      </Box>
    </>
  );
}

export default MobileFooter;
