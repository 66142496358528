/* eslint-disable max-len */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Button,
  IconButton,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { X as XIcon } from 'react-feather';
import {
  templateDialogOpen,
  loadNoteTemplates,
  deleteTemplate,
} from 'src/actions/notesActions';

import useMediaQuery from '@mui/material/useMediaQuery';
import Show from 'src/components/Show';
import LoadingScreen from 'src/components/LoadingScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    zIndex: theme.zIndex.modal,
  },
  advancedInput: {
    paddingLeft: 0,
    paddingRight: 7,
  },
  addButton: {
    padding: 8,
  },
  removeButton: {
    padding: 0,
  },
  advancedInputBox: {
    marginBottom: 0,
    '&:(:last-child)': {
      marginBottom: 50,
    },
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0,
  },
  bottomButton: {
    marginTop: 4,
  },
  title: {},
  mobileStyling: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  mobileTemplateButton: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
}));

function NoteTemplateDialog({
  setNewNoteValue,
  open,
  onClose,
  onApply,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { noteTemplates, templateDialogIsOpen } = useSelector(
    (state) => state.notes,
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (templateDialogIsOpen) {
      dispatch(loadNoteTemplates());
    }
  }, [templateDialogIsOpen]);

  const handleDone = () => {
    dispatch(templateDialogOpen(false));
  };

  const insertTemplateIntoNote = (templateText) => {
    setNewNoteValue((currText) => {
      let cloneText = currText;
      cloneText += templateText;
      return cloneText;
    });
  };

  const handleDeleteTemplate = (templateId) => {
    dispatch(deleteTemplate(templateId));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={templateDialogIsOpen}
      fullScreen={fullScreen}
    >
      <DialogContent className={classes.mobileStyling}>
        <div
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Typography
            align="center"
            className={classes.title}
            gutterBottom
            variant="h3"
            color="textPrimary"
          >
            Templates
          </Typography>
          <Show when={!noteTemplates.isLoading}>
            <div>
              {noteTemplates.allIds &&
                noteTemplates.allIds.length > 0 &&
                // eslint-disable-next-line
                noteTemplates.allIds.map((templateId) => {
                  if (noteTemplates.byId[templateId].templateText) {
                    const formattedTemplate = noteTemplates.byId[
                      templateId
                    ].templateText
                      .replace('</p>', '')
                      .replace('<br>', ' ')
                      .replace('  ', ' ')
                      .replace(/(<([^>]+)>)/gi, '');
                    let shortFormattedTemplate = '';
                    if (formattedTemplate?.length < 85) {
                      shortFormattedTemplate = formattedTemplate;
                    } else {
                      shortFormattedTemplate = formattedTemplate.substring(
                        0,
                        84,
                      );
                    }

                    return (
                      <div key={templateId}>
                        <Button
                          className={classes.mobileTemplateButton}
                          onClick={() => {
                            insertTemplateIntoNote(
                              noteTemplates.byId[templateId].templateText,
                            );
                          }}
                          sx={{ color: 'text.primary' }}
                          variant="text"
                        >
                          {noteTemplates.byId[templateId]?.name ||
                            shortFormattedTemplate}
                        </Button>
                        {/* <IconButton
                          className={classes.removeButton}
                          onClick={() => handleDeleteTemplate(templateId)}
                          fontSize="small"
                          color="default"
                          size="large"
                        >
                          <XIcon />
                        </IconButton> */}
                      </div>
                    );
                  }
                })}
            </div>
          </Show>
          <Show when={noteTemplates.isLoading}>
            <LoadingScreen />
          </Show>
          <Box
            align="center"
            mt={3}
          >
            <Button
              onClick={handleDone}
              color="primary"
              variant="contained"
            >
              Done
            </Button>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
}

NoteTemplateDialog.propTypes = {
  setNewNoteValue: PropTypes.func,
  className: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

NoteTemplateDialog.defaultProps = {
  onApply: () => {},
  onClose: () => {},
};

export default NoteTemplateDialog;
