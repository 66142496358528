/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  Button,
  LinearProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useSnackbar } from 'notistack';

import axios from 'src/utils/axios';
import { useSelector, useDispatch } from 'react-redux';
import { closeCaseSearch } from 'src/actions/caseActions';
import { captureException } from '@sentry/react';
import OfflineView from 'src/views/pages/OfflineView';
import {
  useGetOutcomesAllQuery,
  useGetUsersQuery,
} from 'src/store/serverState';
import { X } from 'react-feather';
import CaseSearchTable from './CaseSearchTable';
import CaseSearchField from './CaseSearchField';
import { useGetCaseSearch, useSetCaseSearch } from './CaseSearchContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: (props) => (props.fullPage ? undefined : theme.spacing(3)),
  },
  dialogRoot: {
    backgroundColor: theme.palette.primary.main,
    padding: `8px ${theme.spacing(3)}`,
    zIndex: 3,
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  advancedInput: {
    paddingLeft: 0,
    paddingRight: 7,
  },
  addButton: {
    padding: 8,
  },
  removeButton: {
    padding: 0,
  },
  advancedInputBox: {
    marginBottom: 0,
    '&:(:last-child)': {
      marginBottom: 50,
    },
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0,
  },
  submitButton: {
    marginTop: 10,
  },

  clearButton: {
    marginTop: 10,
    marginRight: 5,
  },
  icon: {},
  title: {},
}));
const DialogFullHeight = withStyles(() => ({
  scrollPaper: {
    alignItems: 'flex-start', // 100% is for full height or anything else what you need
    paddingTop: '60px',
  },
}))(Dialog);
function CaseSearchView({ fullPage }) {
  const users = useGetUsersQuery();
  const setCaseSearch = useSetCaseSearch();
  const outcomeTypes = useGetOutcomesAllQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [outcomeDetails, setOutcomeDetails] = useState({
    Eye: [],
    Tissue: [],
    Organ: [],
  });

  const getOutcomes = (type) => {
    const outcome = outcomeTypes.data?.find((types) => types.name === type);
    return outcome?.outcomes || [];
  };
  const searchOptions = [
    {
      label: 'Donor ID',
      value: 'donorId',
      fieldProps: {
        inputType: 'text',
      },
    },
    {
      label: 'Statline ID #',
      value: 'statlineIdNumber',
      fieldProps: {
        inputType: 'text',
      },
    },
    {
      label: 'Last Name',
      value: 'donorLastName',
      fieldProps: {
        inputType: 'text',
      },
    },
    {
      label: 'First Name',
      value: 'donorFirstName',
      fieldProps: {
        inputType: 'text',
      },
    },
    {
      label: 'Date of Birth',
      value: 'donorDateOfBirth',
      fieldProps: {
        inputType: 'text',
        regex: /[^0-9/]+/g,
        placeholder: 'mmddyyyy',
        helperText: 'mm | yyyy | mmyyyy | mmddyyyy',
      },
    },
    {
      label: 'Date of Death',
      value: 'donorDateOfDeath',
      fieldProps: {
        inputType: 'text',
        regex: /[^0-9/]+/g,
        placeholder: 'mmddyyyy',
        helperText: 'mm | yyyy | mmyyyy | mmddyyyy',
      },
    },
    {
      label: 'Referring Org',
      value: 'referringOrg',
      fieldProps: {
        inputType: 'select',
        noFlash: true,
        newLoader: true,
        organizations: '1',
      },
    },
    {
      label: 'Referral Taken By',
      value: 'referralTakenBy',
      fieldProps: {
        inputType: 'select',
        noFlash: true,
        newLoader: true,
        options: users.data || [],
        optionLabel: 'fullName',
        optionValue: 'id',
      },
    },
    {
      label: 'Eye / Tissue / Organ Partner Name',
      value: 'partnerName',
      fieldProps: {
        inputType: 'select',
        noFlash: true,
        newLoader: true,
        partners: 'eye,tissue,organ',
      },
    },
    {
      label: 'Eye / Tissue / Organ Partner ID',
      value: 'partnerId',
      fieldProps: {
        inputType: 'text',
        noFlash: true,
        newLoader: true,
      },
    },
    {
      label: 'Eye Outcome',
      value: 'eyeOutcome',
      fieldProps: {
        inputType: 'select',
        optionValue: 'id',
        optionLabel: 'name',
        options: getOutcomes('Eye'),
        cbChange: (e) => {
          setCaseSearch('searchFields.eyeOutcome', e.value);
          setCaseSearch('searchFields.eyeOutcomeDetail', null);
          setOutcomeDetails((prev) => ({ ...prev, Eye: e.raw?.details || [] }));
        },
      },
    },
    {
      label: 'Eye Outcome Detail',
      value: 'eyeOutcomeDetail',
      fieldProps: {
        inputType: 'select',
        noFlash: true,
        newLoader: true,
        noOptionsDisabled: true,
        optionValue: 'id',
        optionLabel: 'name',
        options: outcomeDetails.Eye,
      },
    },
    {
      label: 'Tissue Outcome',
      value: 'tissueOutcome',
      fieldProps: {
        inputType: 'select',
        optionValue: 'id',
        optionLabel: 'name',
        options: getOutcomes('Tissue'),
        cbChange: (e) => {
          setCaseSearch('searchFields.tissueOutcome', e.value);
          setCaseSearch('searchFields.tissueOutcomeDetail', null);
          setOutcomeDetails((prev) => ({
            ...prev,
            Tissue: e.raw?.details || [],
          }));
        },
      },
    },
    {
      label: 'Tissue Outcome Detail',
      value: 'tissueOutcomeDetail',
      fieldProps: {
        inputType: 'select',
        noFlash: true,
        newLoader: true,
        noOptionsDisabled: true,
        optionValue: 'id',
        optionLabel: 'name',
        options: outcomeDetails.Tissue,
      },
    },
    {
      label: 'Organ Outcome',
      value: 'organOutcome',
      fieldProps: {
        inputType: 'select',
        noFlash: true,
        newLoader: true,
        optionValue: 'id',
        optionLabel: 'name',
        options: getOutcomes('Organ'),
        cbChange: (e) => {
          setCaseSearch('searchFields.organOutcome', e.value);
          setCaseSearch('searchFields.organOutcomeDetail', null);
          setOutcomeDetails((prev) => ({
            ...prev,
            Organ: e.raw?.details || [],
          }));
        },
      },
    },
    {
      label: 'Organ Outcome Detail',
      value: 'organOutcomeDetail',
      fieldProps: {
        inputType: 'select',
        noFlash: true,
        newLoader: true,
        noOptionsDisabled: true,
        optionValue: 'id',
        optionLabel: 'name',
        options: outcomeDetails.Organ,
      },
    },
    {
      label: 'Case Status',
      value: 'caseStatus',
      fieldProps: {
        inputType: 'select',
        noFlash: true,
        newLoader: true,
        lookup: 'caseStatus',
      },
    },
    {
      label: 'NOK Main Phone',
      value: 'nokMainPhone',
      fieldProps: {
        inputType: 'text',
        regex: /[^0-9]+/g,
      },
    },
    {
      label: 'DIN',
      value: 'din',
      fieldProps: {
        inputType: 'text',
        noFlash: true,
        newLoader: true,
      },
    },
  ];

  const [errorCode, setErrorCode] = useState({});
  const [caseItems, setCaseItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles({
    fullPage,
  });
  const dispatch = useDispatch();
  const { caseSearchOpen } = useSelector((state) => state.caseItem);
  const { user } = useSelector((state) => state.account);

  const handleClose = () => {
    dispatch(closeCaseSearch());
    setCaseItems([]);
    setErrorCode({});
    setCaseSearch('searchFields', {});
  };

  function ClearButton() {
    return (
      <Button
        className={classes.clearButton}
        variant="contained"
        color="primary"
        onClick={() => setCaseSearch('searchFields', {})}
        disabled={isLoading}
      >
        CLEAR
      </Button>
    );
  }

  function SubmitButton() {
    const allValues = useGetCaseSearch('searchFields');
    const sanitizedValues = { ...allValues };

    const newDin = sanitizedValues?.din;
    sanitizedValues.din = newDin ? newDin.replace(/\s/g, '') : null;

    const handleBasicSubmit = (event) => {
      setIsLoading(true);
      event.preventDefault();

      const isSearchBlank = Object.values(sanitizedValues).filter(
        (value) => value !== null,
      );

      if (isSearchBlank.length === 0) {
        setIsLoading(false);
        return enqueueSnackbar('Fields Can Not All Be Empty', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      }
      axios
        .post(`/public/cases/search`, sanitizedValues)
        .then((res) => {
          if (res?.data?.cases) {
            setCaseItems(res.data.cases);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          if (err?.response?.status) {
            setErrorCode({
              status: err.response.status,
              linked: err.response.data,
            });
          } else {
            const errorId = captureException(err);
            setErrorCode({
              status: 1001,
              linked: errorId,
            });
          }
          setIsLoading(false);
        });
      return null;
    };
    return (
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleBasicSubmit}
        disabled={isLoading}
      >
        Search
      </Button>
    );
  }

  const hasPermission =
    user?.permissions && user.permissions.includes('read:cases');

  if (fullPage) {
    return (
      <Box width="100%">
        {errorCode?.status ? (
          <OfflineView
            noHome
            errorInformation={errorCode}
          />
        ) : (
          <div className={clsx(classes.root)}>
            <form>
              <Box padding="10px">
                <Box
                  display="flex"
                  flexDirection="row"
                  gap="12px"
                  flexWrap="wrap"
                >
                  {searchOptions.map((searchOption) => {
                    return (
                      <CaseSearchField
                        key={searchOption.value}
                        searchOption={searchOption}
                      />
                    );
                  })}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="baseline"
                  flexWrap="wrap-reverse"
                >
                  <Typography variant="caption">
                    {caseItems?.length ? `${caseItems.length} results` : ''}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="baseline"
                  >
                    <ClearButton />
                    <SubmitButton />
                  </Box>
                </Box>
              </Box>
              {isLoading ? (
                <LinearProgress />
              ) : caseItems?.length ? (
                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  width={fullPage ? 'calc(100vw - 20px)' : '100%'}
                >
                  <CaseSearchTable
                    handleClose={handleClose}
                    caseItems={caseItems}
                  />
                </Box>
              ) : null}
            </form>
          </div>
        )}
      </Box>
    );
  }
  return (
    <DialogFullHeight
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={caseSearchOpen && hasPermission}
    >
      <DialogTitle className={classes.dialogRoot}>
        <Typography
          style={{ color: 'white' }}
          className={classes.title}
          variant="h5"
        >
          Case Search
        </Typography>
        <IconButton
          style={{ padding: 0, color: 'white' }}
          onClick={handleClose}
          type="button"
          size="large"
        >
          <X />
        </IconButton>
      </DialogTitle>
      {errorCode?.status ? (
        <OfflineView
          noHome
          errorInformation={errorCode}
        />
      ) : (
        <div className={clsx(classes.root)}>
          <form>
            <Box padding="10px">
              <Box
                display="flex"
                flexDirection="row"
                gap="12px"
                flexWrap="wrap"
              >
                {searchOptions.map((searchOption) => {
                  return (
                    <CaseSearchField
                      key={searchOption.value}
                      searchOption={searchOption}
                    />
                  );
                })}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                flexWrap="wrap-reverse"
              >
                <Typography variant="caption">
                  {caseItems?.length ? `${caseItems.length} results` : ''}
                </Typography>
                <Box
                  display="flex"
                  alignItems="baseline"
                >
                  <ClearButton />
                  <SubmitButton />
                </Box>
              </Box>
            </Box>
            {isLoading ? (
              <LinearProgress />
            ) : caseItems?.length ? (
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                width={fullPage ? 'calc(100vw - 20px)' : '100%'}
              >
                <CaseSearchTable
                  handleClose={handleClose}
                  caseItems={caseItems}
                />
              </Box>
            ) : null}
          </form>
        </div>
      )}
    </DialogFullHeight>
  );
}

export default CaseSearchView;
