/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_LABELS,
  GET_NOTES,
  GET_NOTE,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  OPEN_NEW_MESSAGE,
  CLOSE_NEW_MESSAGE,
  SAVE_NOTE,
  FILTER_NOTES,
  FILTER_CASE_NOTES,
  DELETE_NOTE,
  SAVE_NOTE_TEMPLATE,
  GET_NOTE_TEMPLATES,
  TEMPLATE_DIALOG_OPEN,
  DELETE_TEMPLATE,
  OPEN_CASE_NOTES_LIST,
  CLOSE_CASE_NOTES_LIST,
  OPEN_CASE_NOTE_DETAILS,
  CLOSE_CASE_NOTE_DETAILS,
  GET_CASE_NOTES,
  GET_ORG_NOTES,
  CLEAR_ORG_NOTES,
} from 'src/actions/notesActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  notes: {
    byId: {},
    allIds: [],
  },
  caseNotes: {
    byId: {},
    allIds: [],
  },
  orgNotes: [],
  noteTemplates: {
    isLoading: false,
    byId: {},
    allIds: [],
  },
  templateDialogIsOpen: false,
  labels: [],
  sidebarOpen: false,
  newMessageOpen: false,
  caseNotesListOpen: false,
  caseNoteDetailsOpen: false,
  caseNoteDetailsId: null,
  filterString: '',
  caseNotesFilterString: '',
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LABELS: {
      const { labels } = action.payload;

      return produce(state, (draft) => {
        draft.labels = labels;
      });
    }

    case GET_NOTES: {
      const { notes } = action.payload;

      return produce(state, (draft) => {
        draft.notes.byId = objFromArray(notes);
        draft.notes.allIds = Object.keys(draft.notes.byId);
      });
    }

    case GET_CASE_NOTES: {
      const { notes } = action.payload;
      return produce(state, (draft) => {
        draft.caseNotes.byId = objFromArray(notes);
        draft.caseNotes.allIds = Object.keys(draft.caseNotes.byId);
      });
    }

    case GET_ORG_NOTES: {
      const { notes } = action.payload;
      return produce(state, (draft) => {
        draft.orgNotes = notes;
      });
    }

    case CLEAR_ORG_NOTES: {
      return produce(state, (draft) => {
        draft.orgNotes = initialState.orgNotes;
      });
    }

    case GET_NOTE: {
      const { note } = action.payload;

      return produce(state, (draft) => {
        draft.notes.byId[note.id] = note;

        if (!draft.notes.allIds.includes(note.id)) {
          draft.notes.allIds.push(note.id);
        }
      });
    }

    case OPEN_SIDEBAR: {
      return produce(state, (draft) => {
        draft.sidebarOpen = true;
      });
    }

    case CLOSE_SIDEBAR: {
      return produce(state, (draft) => {
        draft.sidebarOpen = false;
      });
    }

    case OPEN_NEW_MESSAGE: {
      return produce(state, (draft) => {
        draft.newMessageOpen = true;
      });
    }

    case CLOSE_NEW_MESSAGE: {
      return produce(state, (draft) => {
        draft.newMessageOpen = false;
      });
    }

    case OPEN_CASE_NOTES_LIST: {
      return produce(state, (draft) => {
        draft.caseNotesListOpen = true;
      });
    }

    case CLOSE_CASE_NOTES_LIST: {
      return produce(state, (draft) => {
        draft.caseNotesListOpen = false;
      });
    }

    case OPEN_CASE_NOTE_DETAILS: {
      const noteId = action.payload;
      return produce(state, (draft) => {
        draft.caseNoteDetailsOpen = true;
        draft.caseNoteDetailsId = noteId;
      });
    }

    case CLOSE_CASE_NOTE_DETAILS: {
      return produce(state, (draft) => {
        draft.caseNoteDetailsOpen = initialState.caseNoteDetailsOpen;
        draft.caseNoteDetailsId = initialState.caseNoteDetailsId;
      });
    }

    case SAVE_NOTE: {
      const { note } = action.payload;

      return produce(state, (draft) => {
        draft.newMessageOpen = false;
        draft.notes.byId[note.id] = note;

        if (!draft.notes.allIds.includes(note.id)) {
          draft.notes.allIds.push(note.id);
        }
      });
    }

    case FILTER_NOTES: {
      const filterString = action.payload;
      return produce(state, (draft) => {
        draft.filterString = filterString.toLowerCase();
      });
    }

    case FILTER_CASE_NOTES: {
      const filterString = action.payload;
      return produce(state, (draft) => {
        draft.caseNotesFilterString = filterString.toLowerCase();
      });
    }

    case DELETE_NOTE: {
      const deletedNote = action.payload;
      return produce(state, (draft) => {
        const index = draft.notes.allIds.indexOf(deletedNote.id);
        if (index > -1) {
          draft.notes.allIds.splice(index, 1);
        }
      });
    }

    case SAVE_NOTE_TEMPLATE: {
      const { noteTemplate } = action.payload;

      return produce(state, (draft) => {
        draft.noteTemplates.byId[noteTemplate.id] = noteTemplate;
        if (!draft.noteTemplates.allIds.includes(noteTemplate.id)) {
          draft.noteTemplates.allIds.push(noteTemplate.id);
        }
      });
    }

    case GET_NOTE_TEMPLATES: {
      const { noteTemplates, isLoading } = action.payload;

      return produce(state, (draft) => {
        draft.noteTemplates.byId = objFromArray(noteTemplates);
        draft.noteTemplates.allIds = Object.keys(draft.noteTemplates.byId);
        draft.noteTemplates.isLoading = isLoading;
      });
    }

    case TEMPLATE_DIALOG_OPEN: {
      const dialogOpen = action.payload;

      return produce(state, (draft) => {
        draft.templateDialogIsOpen = dialogOpen;
      });
    }

    case DELETE_TEMPLATE: {
      const templateId = action.payload;
      return produce(state, (draft) => {
        const index = draft.noteTemplates.allIds.indexOf(templateId);
        if (index > -1) {
          draft.noteTemplates.allIds.splice(index, 1);
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default notesReducer;
