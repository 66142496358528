import CaseSearchView from './CaseSearchView';
import { CaseSearchProvider } from './CaseSearchContext';

function CaseSearchDialog({ fullPage }) {
  return (
    <CaseSearchProvider>
      <CaseSearchView fullPage={fullPage} />
    </CaseSearchProvider>
  );
}

export default CaseSearchDialog;
