// The portal wrapper for case task, basically copy/pasted from CaseNotesList
// This can probably be made into a reusable component.
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Divider, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { closeCaseTasksList } from 'src/actions/caseTasksActions';
import { useDispatch, useSelector } from 'react-redux';
import { clone } from 'src/utils/object';
import {
  serverState,
  useGetUsersQuery,
  useAddCaseTaskMutation,
  useUpdateCaseTaskMutation,
} from 'src/store/serverState';
import LoadingScreen from 'src/components/LoadingScreen';
import PortalWrapper from './portalWrapper';
import Item from './item';
import EditTask from './editTask';
import StatusButtonRow from './statusButtonRow';
import Toolbar from './toolbar';

import {
  defaultTaskProps,
  defaultNewTaskProps as rawDefaultNewTaskProps,
  reservedStatusIds,
  editableTaskProps,
  NEW_TASK_ID,
  sortAndUpdateTasks,
  createUserHash,
  sortAuxData,
} from '../api';

const weightToColorNumber = (weight) =>
  Math.round(Math.max(0, Math.min(100, weight)) * 2.56);

const useStyles = makeStyles((theme) => ({
  portalContent: {
    flexGrow: 1,
    overflow: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.dark,
  },
  hidden: {
    display: 'none',
  },
}));

function CaseTasks({ caseId, caseTasks, tasksAuxData }) {
  const classes = useStyles();
  const isOpen = useSelector((state) => state.caseTasks.open);
  const [saveNewTask] = useAddCaseTaskMutation();
  const [updateTask] = useUpdateCaseTaskMutation();
  const dispatch = useDispatch();
  const [isShowingCompletedTasks, setIsShowingCompletedTasks] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userHash, setUserHash] = useState({});
  const [tasks, updateTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [{ statusHash, priorityHash, typeHash, otherStatuses }, setAuxData] =
    useState({
      statusHash: {},
      priorityHash: {},
      typeHash: {},
      otherStatuses: {},
    });
  const users = useGetUsersQuery();
  const priorities = useMemo(() => Object.values(priorityHash), [priorityHash]);
  const types = useMemo(() => Object.values(typeHash), [typeHash]);

  const defaultNewTaskProps = useMemo(
    () => ({
      ...rawDefaultNewTaskProps,
      caseId,
      assignedId: userId,
    }),
    [caseId, userId],
  );

  const [activeTaskId, setActiveTaskId] = useState(null);
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const activeTaskIndex =
    tasks.length > 0 && tasks.findIndex((task) => +task.id === +activeTaskId);

  const activeTaskObject = isCreatingTask
    ? defaultNewTaskProps
    : tasks?.[activeTaskIndex] || null;

  const [showPermissionError, setShowPermissionError] = useState(false);

  useEffect(() => {
    if (!users.isFetching) {
      setUserHash(createUserHash(users.data));
    }
  }, [users.isFetching]);

  const loadData = useCallback(() => {
    const auxResponse = sortAuxData(tasksAuxData);
    setUserId(auxResponse.userId);
    setAuxData({
      statusHash: auxResponse.statusHash,
      priorityHash: auxResponse.priorityHash,
      typeHash: auxResponse.typeHash,
      otherStatuses: auxResponse.otherStatuses,
    });
    sortAndUpdateTasks({
      priorityHash: auxResponse.priorityHash,
      newTasksArray: caseTasks?.map((task) => ({
        ...task,
        id: +task.id,
        modifiedData: null,
      })),
      updateTasks,
    });
  }, [isOpen, caseId, caseTasks, tasksAuxData]);

  useEffect(() => {
    if (isOpen && caseId && tasksAuxData && caseTasks) {
      loadData();
      setIsLoading(false);
    }
  }, [isOpen, loadData, caseId, caseTasks, tasksAuxData]);

  if (!isOpen) {
    return null;
  }

  const getTaskTitle = (t) => {
    return `${typeHash[t.typeId].name} - ${userHash[t.assignedId]?.name}: ${
      t.description
    } (${statusHash[t.statusId] || 'unknown'})`;
  };

  const createTask = (newTaskProps) => {
    const newTask = {
      ...defaultTaskProps,
      ...newTaskProps,
    };
    delete newTask.id;
    setIsLoading(true);
    saveNewTask(newTask);
  };

  const updateAndSaveTask = (newTaskProps) => {
    const newTasks = tasks.concat();
    const taskIndex =
      newTaskProps.id !== NEW_TASK_ID
        ? tasks.findIndex((t) => t.id === newTaskProps.id)
        : -1;

    if (taskIndex === -1) {
      throw Error('Task being modified does not exist in the tasks array');
    }
    // Existing task:
    const initialTask = clone(tasks[taskIndex]);

    const newTask = {
      ...initialTask,
      ...newTaskProps,
    };

    const taskPropsToUpdate = Object.entries(newTask).reduce(
      (hash, [prop, val]) =>
        editableTaskProps.includes(prop) && initialTask[prop] !== newTask[prop]
          ? {
              ...hash,
              [prop]: val,
            }
          : hash,
      {},
    );
    newTasks.splice(taskIndex, 1, {
      ...newTask,
    });
    setIsLoading(true);
    updateTask({ taskId: newTask.id, ...taskPropsToUpdate });
  };

  return (
    <PortalWrapper
      title={
        activeTaskObject
          ? `${
              activeTaskObject.id === NEW_TASK_ID
                ? 'Create New Task'
                : `Edit ${getTaskTitle(activeTaskObject)}`
            }`
          : 'Case Tasks'
      }
      onClose={() => {
        dispatch(closeCaseTasksList());
      }}
    >
      <div className={classes.portalContent}>
        {
          // eslint-disable-next-line no-nested-ternary
          showPermissionError ? (
            <Box p={2}>
              <Typography>
                There was a permission-related error and tasks cannot be shown.
              </Typography>
              <Typography>Please contact your administrator.</Typography>
            </Box>
          ) : activeTaskObject ? (
            <EditTask
              users={users.data || []}
              priorities={priorities}
              types={types}
              task={activeTaskObject}
              onClose={(newTaskObject) => {
                setIsCreatingTask(false);
                setActiveTaskId(null);
                if (newTaskObject === activeTaskObject) return;
                if (newTaskObject.id === NEW_TASK_ID) {
                  const newTask = { ...newTaskObject };
                  delete newTask.id;
                  createTask(newTask);
                } else {
                  updateAndSaveTask(newTaskObject);
                }
              }}
            />
          ) : (
            <>
              <Toolbar
                isShowingCompletedTasks={isShowingCompletedTasks}
                totalTasks={tasks.reduce(
                  (count, task) =>
                    count +
                    (isShowingCompletedTasks ||
                      task.statusId !== reservedStatusIds.COMPLETE),
                  0,
                )}
                onShowCompletedToggled={(value) => {
                  setIsShowingCompletedTasks(value);
                }}
                onRefreshClicked={() => {
                  dispatch(
                    serverState.util.invalidateTags([
                      'CaseTasks',
                      'TaskAuxData',
                    ]),
                  );
                }}
                onTaskCreateClicked={() => {
                  setIsCreatingTask(true);
                }}
              />
              <Divider />
              {isLoading || users.isFetching ? (
                <LoadingScreen />
              ) : (
                tasks
                  .filter(
                    (task) =>
                      isShowingCompletedTasks ||
                      task.statusId !== reservedStatusIds.COMPLETE,
                  )
                  .map((task) => {
                    const weight = +(
                      priorityHash[task.priorityId]?.weight || 0
                    );
                    const priorityColor = `rgb(${weightToColorNumber(
                      weight,
                    )},0,${weightToColorNumber(100 - weight)})`;

                    return (
                      <Item
                        color={priorityColor}
                        key={task.id}
                        id={task.id}
                        subject={getTaskTitle(task)}
                        text=""
                        dateText={
                          task.dueDate &&
                          task.statusId !== reservedStatusIds.COMPLETE
                            ? moment(task.dueDate).format('DD MMM YYYY HH:mm')
                            : (task.statusId === reservedStatusIds.COMPLETE &&
                                'Complete') ||
                              ''
                        }
                        onClick={(id) => {
                          setActiveTaskId(id);
                        }}
                        renderOnHover={() => (
                          <StatusButtonRow
                            task={task}
                            otherStatuses={otherStatuses}
                            onClick={(statusId) => {
                              updateAndSaveTask({
                                id: task.id,
                                statusId,
                              });
                            }}
                          />
                        )}
                      />
                    );
                  })
              )}
            </>
          )
        }
      </div>
    </PortalWrapper>
  );
}

export default CaseTasks;
