import { useState, useEffect, useCallback, memo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  useTheme,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import { useAppSelector } from 'src/store';

const DEFAULT_CHANGECALLLBACK = () => {};

export interface FormCheckboxProps extends MuiCheckboxProps {
  value?: boolean;
  labelsProps?: Partial<FormControlLabelProps>;
  label?: string;
  viewOnly?: boolean;
  changeCallback?: (value: boolean | null) => void;
  noColorChange?: boolean;
  width?: string;
  disabled?: boolean;
  labelPlacement?: 'start' | 'top' | 'bottom' | 'end';
  noMargin?: boolean;
  writeOnly?: boolean;
  noPadding?: boolean;
  justifyContent?: string;
}

function FormCheckbox({
  value: valueProp, // the inital value you want it to start with
  changeCallback = DEFAULT_CHANGECALLLBACK, // function executed on change
  label, // the label of the *input*
  noColorChange = false, // boolean if u want it to change color if no longer inital value
  width, // percentage
  name: nameProp, // the name of the input
  readOnly: readOnlyProp = false, // alias for disabled
  disabled: disabledProp = false, // boolean for disabling the component fully
  labelPlacement: labelPlacementProp = 'end', // string for the position of label can be: | start | top | bottom | end
  noMargin = false, // removes all margin boolean
  writeOnly = false, // always be in write mode even if it in in view mode
  noPadding = false,
  viewOnly = false, // always be in view mode even if it in in write mode
  justifyContent: justifyContentProp, // override justify content prop
  ...props
}: FormCheckboxProps) {
  // set component state
  const [checkedValue, setCheckedValue] = useState<typeof valueProp | null>(
    valueProp,
  );
  const [initialValueField] = useState(valueProp);
  const [isViewMode, setIsViewMode] = useState(true); // boolean for conditionally determining if the form is in view mode
  const [isInitialValue, setIsInitialValue] = useState(true); // boolean for conditionally determining if the selected value has changed from it's initial value
  const formMode = useAppSelector((state) => state.formPermissions.mode);
  const theme = useTheme();

  // a function to set the state of isViewMode and isInitialValue
  const setFormPermissionValues = useCallback(async () => {
    if (formMode) {
      if (writeOnly) {
        setIsViewMode(false);
      } else {
        setIsViewMode(viewOnly || formMode === 'view');
      }
    }

    if (checkedValue != null && initialValueField != null) {
      setIsInitialValue(checkedValue === initialValueField);
    } else if (initialValueField === null && checkedValue === false) {
      setIsInitialValue(true);
    } else if (checkedValue != null) {
      setIsInitialValue(false);
    } else {
      setIsInitialValue(true);
    }
  }, [formMode, checkedValue, viewOnly, initialValueField, writeOnly]);

  useEffect(() => {
    setFormPermissionValues();
  }, [setFormPermissionValues]);

  // will return an the checked value
  const handleChange = (val: boolean | null) => {
    // check if value is empty
    // @ts-ignore ignoring check on '' for legacy purposes
    if (val === null || val === undefined || val === '') {
      setCheckedValue(null);
      return null;
    }

    // if exists, set value
    setCheckedValue(val);
    return val;
  };

  // a function to get the value and check if there is
  // a default value to look for in the array of options
  const getSelectedValue = useCallback(() => {
    // check to see if any default value has been specified
    // @ts-ignore ignoring check on '' for legacy purposes
    if (valueProp === null || valueProp === undefined || valueProp === '') {
      setCheckedValue(null);
      return null;
    }

    // if exists, set value
    setCheckedValue(valueProp);
    return valueProp;
  }, [valueProp]);

  // lifecycle method for setting the value to correct value
  useEffect(() => {
    getSelectedValue();
  }, [getSelectedValue]);

  const checkboxColor = () => {
    if (disabledProp) return theme.palette.unavailable.main;

    return isInitialValue || noColorChange
      ? theme.palette.primary.main
      : theme.palette.alternative.main;
  };

  // main render, the component is no longer loading
  return (
    <FormControlLabel
      {...props.labelsProps}
      labelPlacement={labelPlacementProp}
      label={label}
      disabled={disabledProp || readOnlyProp || isViewMode}
      sx={{
        '& .MuiFormControlLabel-label.Mui-disabled': {
          color: disabledProp ? '#b0bec5' : 'initial',
        },
        ...props.labelsProps?.sx,
      }}
      style={{
        width,
        alignItems: 'center',
        margin: noMargin ? '0px' : undefined,
        padding: noPadding ? '0px' : undefined,
        justifyContent: justifyContentProp || (noMargin ? 'center' : 'initial'),
        ...props.labelsProps?.style,
      }}
      control={
        <Checkbox
          size={props.size || 'small'}
          name={nameProp}
          checked={checkedValue || false}
          onChange={(event, checked) => {
            changeCallback(handleChange(event.target?.checked));
            if (props.onChange) {
              props.onChange(event, checked);
            }
          }}
          style={{
            paddingTop: labelPlacementProp === 'top' ? '0px' : undefined,
            padding: noPadding ? '0px' : undefined,
            color: checkboxColor(),
            ...props.style,
          }}
        />
      }
    />
  );
}

export default memo(FormCheckbox);
