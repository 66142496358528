import * as Sentry from '@sentry/browser';
import axios from 'src/utils/axios';
import { stripNull, clone, isIdentical } from 'src/utils/object';

const baseURL = '/public';

export const constructPayload = (values) => ({
  definitions: values.definitions
    .map((definition, definitionIndex) => {
      const initialDefinition =
        values.initialState.definitions[definitionIndex];
      return {
        name: definition.name,
        modifiedRows: definition.rows
          .filter((row, rowIndex) => {
            const initialRow = initialDefinition.rows[rowIndex];
            return !isIdentical(row, initialRow);
          })
          .reduce(
            (modifiedRowObject, modifiedRow) => ({
              ...modifiedRowObject,
              [modifiedRow.id]: stripNull({
                ...modifiedRow,
                createdBy: null,
                createdDate: null,
              }),
            }),
            {},
          ),
        newRows: definition.rows
          .slice(initialDefinition.rows.length)
          .filter((row) =>
            stripNull({
              ...row,
              id: null,
              createdBy: null,
              createdDate: null,
            }),
          ),
      };
    })
    .filter((d) => Object.keys(d.modifiedRows).length || d.newRows.length),
});

class AdminService {
  // roles expects a comma separated list of role abbreviations (i.e. "tdc,mrc,ert")
  getAccessLog = async () => {
    try {
      const request = await axios.get(`${baseURL}/admin/accessLogs`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getReferenceTables = async () => {
    try {
      const request = await axios.get(`${baseURL}/referenceTables`);
      if (request.status === 200) {
        const transformedData = {
          ...request.data.record,
        };
        return {
          ...transformedData,
          initialState: clone(transformedData),
        };
      }
      throw new Error(request.data.msg);
    } catch (error) {
      if (error.request.status === 403) {
        return {
          error: 'This user is not authorized to view this page.',
        };
      }
      Sentry.captureException(error);
      throw error;
    }
  };

  getOrganizations = async (orgId = null) => {
    try {
      const request = await axios.get(
        `${baseURL}/referenceTables/organizations${orgId ? `/${orgId}` : ''}`,
      );
      if (request.status === 200) {
        const { organizationData } = request.data.record;
        const transformedData = {
          organizationData: {
            externalTables: organizationData.externalTables,
            newOrganizationId: organizationData.newOrganizationId,
            organizations: organizationData.organizations.map((org) =>
              stripNull({
                ...org,
                addressId: null,
                organizationContacts: null,
                contacts: org.organizationContacts,
                notes: org.notes.map((note) =>
                  stripNull({
                    ...note,
                    organizationNote: null,
                  }),
                ),
                zlOrgTypes: null,
                orgTypes: org.zlOrgTypes.map(({ id }) => id),
              }),
            ),
          },
        };
        return {
          ...transformedData,
          initialState: clone(transformedData),
        };
      }
      throw new Error(request.data.msg);
    } catch (error) {
      if (error.request.status === 403) {
        return {
          error: 'This user is not authorized to view this page.',
        };
      }
      Sentry.captureException(error);
      throw error;
    }
  };

  getGroups = async (groupId = null) => {
    try {
      const request = await axios.get(
        `${baseURL}/referenceTables/groups${groupId ? `/${groupId}` : ''}`,
      );
      if (request.status === 200) {
        const { groupData } = request.data.record;
        const transformedData = {
          groupData: {
            externalTables: groupData.externalTables,
            newGroupId: groupData.newGroupId,
            groups: groupData.groups.map((group) =>
              stripNull({
                ...group,
                userIds: null,
                users: group.userIds.map((userId) => {
                  const user = groupData.externalTables.user.find(
                    (u) => +u.id === +userId,
                  );
                  return {
                    id: userId,
                    name: user ? `${user.firstName} ${user.lastName}` : 'N/A',
                  };
                }),
              }),
            ),
          },
        };
        return {
          ...transformedData,
          initialState: clone(transformedData),
        };
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateReferenceTables = async (values) => {
    const payload = {
      ...constructPayload(values),
    };
    try {
      const request = await axios.put(`${baseURL}/referenceTables`, {
        payload,
      });
      if (request.status === 200) {
        return {
          ...request.data.record,
          initialState: clone(request.data.record),
        };
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateOrganizations = async (values) => {
    const payload = {
      organizationData: stripNull({
        ...values.organizationData,
        organizations: values.organizationData.organizations
          .filter(
            (org, orgIndex) =>
              !isIdentical(
                org,
                values.initialState.organizationData.organizations[orgIndex],
              ),
          )
          .map((org) => ({
            ...org,
            orgTypes: org.orgTypes.filter((orgType) => orgType != null),
          })),
      }),
    };
    try {
      const request = await axios.put(
        `${baseURL}/referenceTables/organizations`,
        {
          payload,
        },
      );
      if (request.status === 200) {
        return {
          ...request.data.record,
          initialState: clone(request.data.record),
        };
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateGroups = async (values) => {
    const payload = {
      groupData: stripNull({
        ...values.groupData,
        groups: values.groupData.groups
          .filter(
            (group, groupIndex) =>
              !isIdentical(
                group,
                values.initialState.groupData.groups[groupIndex],
              ),
          )
          .map((group) =>
            stripNull({
              ...group,
              // eslint-disable-next-line no-unsafe-optional-chaining
              userIds: group.users.map((user) => +user?.id),
              users: null,
            }),
          ),
      }),
    };
    try {
      const request = await axios.put(`${baseURL}/referenceTables/groups`, {
        payload,
      });
      if (request.status === 200) {
        return {
          ...request.data.record,
          initialState: clone(request.data.record),
        };
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}

const adminService = new AdminService();

export default adminService;
