import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/prismjs';
import 'src/mixins/fontawesome';
import 'text-security/text-security.css';

import { enableES5 } from 'immer';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import setNotificationStore from 'src/services/messageNotificationService';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/context/SettingsContext';
import { store } from 'src/store';
import { restoreSettings } from 'src/utils/settings';
import App from 'src/App';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import {
  ReportingObserver as ReportingObserverIntegration,
  RewriteFrames,
  ExtraErrorData as ExtraErrorDataIntegration,
  Offline as OfflineIntegration,
} from '@sentry/integrations';
import reportWebVitals from './reportWebVitals';
import CrashScreen from './views/pages/CrashScreen';

enableES5();

// https://vitejs.dev/guide/build.html#load-error-handling
window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();
  window.location.reload();
});

const sentryEnvironment = import.meta.env.REACT_APP_ENVIRONMENT;
const packageJsonVersion = import.meta.env.REACT_APP_ENVIRONMENT;
const envsToSample = ['dev', 'uat', 'prd'];

Sentry.init({
  dsn: import.meta.env.REACT_APP_SENTRY,
  environment: sentryEnvironment,
  release: `project-horus-front@${packageJsonVersion}`,
  normalizeDepth: 11,
  integrations: [
    new TracingIntegrations.BrowserTracing(),
    new RewriteFrames(),
    new ReportingObserverIntegration(),
    new ExtraErrorDataIntegration({ depth: 10 }),
    new OfflineIntegration(),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  tracesSampler: (samplingContext) => {
    if (envsToSample.includes(sentryEnvironment)) {
      return 0.25;
    }
    return 0;
  },
});

const settings = restoreSettings();

setNotificationStore(store);
const container = document.getElementById('root');

// this is to insure the dialog is always on top and can always be accessed.
const withReportDialog = (customize, { once } = {}) => {
  const next = window.sentryEmbedCallback;
  window.sentryEmbedCallback = (embed) => {
    if (once) window.sentryEmbedCallback = next;
    customize(embed);
    const onClose = embed.close.bind(embed);
    // eslint-disable-next-line no-param-reassign
    embed.close = () => {
      const muiDialog =
        document && document.getElementsByClassName('MuiDialog-scrollPaper')[0];
      if (muiDialog) muiDialog.setAttribute('tabIndex', -1);
      onClose();
    };
    if (next && typeof next === 'function') next(embed);
  };
};

const putOnTop = ({ element } = {}) => {
  const style = element && element.getElementsByTagName('style')[0];
  if (style)
    style.innerHTML = style.innerHTML.replace(
      /z-index:\s*\d+;/,
      'z-index: 999999999;',
    );

  const muiDialog =
    document && document.getElementsByClassName('MuiDialog-scrollPaper')[0];
  if (muiDialog) muiDialog.removeAttribute('tabIndex');
};

withReportDialog(putOnTop);

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<CrashScreen />}>
    <Provider store={store}>
      <SettingsProvider settings={settings}>
        <App />
      </SettingsProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
  container,
);

serviceWorker.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
