import PropTypes from 'prop-types';
import {
  Box,
  Hidden,
  IconButton, // Input,
  // Paper,
  Button,
  Tooltip,
  SvgIcon,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RefreshIcon from '@mui/icons-material/Refresh';
// import MoreIcon from '@mui/icons-material/MoreVert';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  // Search as SearchIcon,
  Menu as MenuIcon,
} from 'react-feather';
import { CustomField } from 'src/components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    height: 68,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
  dueDateColumn: {
    position: 'absolute',
    right: '1rem',

    bottom: 0,
  },
}));

function Toolbar({
  isShowingCompletedTasks,
  // totalTasks,
  onShowCompletedToggled,
  onRefreshClicked,
  onTaskCreateClicked,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <IconButton
          value={isShowingCompletedTasks}
          onClick={() => {
            onShowCompletedToggled(!isShowingCompletedTasks);
          }}
          size="large"
        >
          <SvgIcon fontSize="small">
            <MenuIcon />
          </SvgIcon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="space-between"
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              onTaskCreateClicked();
            }}
          >
            New Task
          </Button>
          <CustomField
            inputType="checkbox"
            noColorChange
            writeOnly
            value={isShowingCompletedTasks}
            label="Show Completed"
            cbChange={(value) => {
              onShowCompletedToggled(value);
            }}
          />
        </Box>
        {/*
        <Paper
          component={Box}
          display="flex"
          alignItems="center"
          variant="outlined"
          py={0.5}
          px={1}
        >
          <SvgIcon color="action" fontSize="small">
            <SearchIcon />
          </SvgIcon>
          <Input
            className={classes.searchInput}
            disableUnderline
            placeholder="Search notes"
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </Paper>
        */}
        <Tooltip title="Refresh">
          <IconButton
            onClick={() => onRefreshClicked()}
            size="large"
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/*
        <Box display="flex" alignItems="center">
          <Tooltip title="Next page">
            <IconButton>
              <KeyboardArrowLeftIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {totalTasks > 0 ? (
            <>
              <Typography noWrap variant="body2" color="textSecondary">
                1 - {totalTasks} of {totalTasks}
              </Typography>
              <Tooltip title="Previous page">
                <IconButton>
                  <KeyboardArrowRightIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          ) : null}
        </Box>
        */}
      </Hidden>
      <div className={classes.dueDateColumn}>
        <Typography variant="caption">Due Date</Typography>
      </div>
    </div>
  );
}

Toolbar.propTypes = {
  isShowingCompletedTasks: PropTypes.bool.isRequired,
  // totalTasks: PropTypes.number.isRequired,
  onShowCompletedToggled: PropTypes.func.isRequired,
  onRefreshClicked: PropTypes.func.isRequired,
  onTaskCreateClicked: PropTypes.func.isRequired,
};

export default Toolbar;
