import { Outlet } from 'react-router-dom';
import { Hidden } from '@mui/material';
import SecondaryTopBar from '../MainLayout/SecondaryTopBar';

function NonCaseLayout() {
  return (
    <>
      <Hidden lgDown>
        <SecondaryTopBar />
      </Hidden>
      <div style={{ overflow: 'auto', height: '100%' }}>
        <Outlet />
      </div>
    </>
  );
}

export default NonCaseLayout;
