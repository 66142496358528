import LRUCache from 'lru-cache';
import buildSortedUrl from './buildSortedUrl';

export default function throttleAdapterEnhancer(adapter, options = {}) {
  const { threshold = 1000, cache = new LRUCache({ max: 10 }) } = options;

  const recordCacheWithRequest = (index, config) => {
    const responsePromise = (async () => {
      try {
        const response = await adapter(config);

        cache.set(index, {
          timestamp: Date.now(),
          value: Promise.resolve(response),
        });

        return response;
      } catch (reason) {
        cache.del(index);
        throw reason;
      }
    })();

    cache.set(index, {
      timestamp: Date.now(),
      value: responsePromise,
    });

    return responsePromise;
  };

  return (config) => {
    const { url, method, params, paramsSerializer } = config;
    const index = buildSortedUrl(url, params, paramsSerializer);

    const now = Date.now();
    const cachedRecord = cache.get(index) || { timestamp: now };

    if (method === 'get') {
      if (now - cachedRecord.timestamp <= threshold) {
        const responsePromise = cachedRecord.value;
        if (responsePromise) {
          return responsePromise;
        }
      }

      return recordCacheWithRequest(index, config);
    }

    return adapter(config);
  };
}
