import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public';

class SettingService {
  getNotificationSettings = async () => {
    try {
      const request = await axios.get(`${baseURL}/settings/notifications`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  setNotificationSettings = async (payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/settings/notifications`,
        payload,
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}

const settingService = new SettingService();

export default settingService;
