import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import { CaseBannerType } from 'types';
import CaseBanner from './CaseBanner';

const drawerWidth = 198;
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingTop: '44.5px',
    backgroundColor: 'white',
  },
  drawer: {
    height: '100%',
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-docked': {
      height: '100%',
    },
  },
  drawerPaper: {
    position: 'relative',
    padding: '0px 11px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
    zIndex: theme.zIndex.drawer - 100,
  },
}));

type Props = {
  banner: CaseBannerType;
};

function CaseBannerView({ banner }: Props) {
  const classes = useStyles();
  if (!banner?.id) {
    return null;
  }

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <CaseBanner banner={banner} />
        </Drawer>
      </nav>
    </div>
  );
}

export default CaseBannerView;
