import openSocket from 'socket.io-client';
import socketUrl from 'src/utils/socketUrl';

const socket = openSocket(socketUrl, {
  transports: ['websocket'],
});
export default socket;

// Close the connection on unload.
// Depending on the browser, the most likely scenario is the page closes before this fires.
// On older browsers, it may be necessary to call this however.
window.addEventListener('beforeunload', () => {
  socket.disconnect();
});
