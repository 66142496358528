import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';
import { stripNull } from 'src/utils/object';

const baseURL = '/public/medicalReview';

class MedicalReviewService {
  getPathology = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/pathology`);
      if (request.status === 200) {
        return request.data.pathology;
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createPathology = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/pathology`,
        payload,
      );
      if (request.status === 200) {
        return request.data.pathology;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /* DONOR INFORMATION */
  updatePathology = async (caseId, payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/${caseId}/pathology`,
        payload,
      );
      if (request.status === 200) {
        return request.data.pathology;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /* HEMODILUTION */
  updateHemodilutionInformation = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/hemodilution`,
        stripNull({
          ...payload,
          serverState: null,
        }),
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getHemodilutionInformation = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/hemodilution`);
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getHemodilutionSerologies = async (caseId) => {
    try {
      const request = await axios.get(
        `${baseURL}/${caseId}/hemodilutionSerologies`,
      );
      if (request.status === 200) {
        return request.data.serologies;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /** Medical summary */
  getMedicalReviewSummary = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/summary`);
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  upsertMedicalReviewSummary = async (caseId, payload) => {
    try {
      const request = await axios.post(`${baseURL}/${caseId}/summary`, payload);
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /** Culture Results */
  getCultureResults = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/cultureResult`);
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateCultureResults = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/cultureResult`,
        payload,
      );
      if (request.status === 200) {
        return request.data.item;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /** cbcTempMeds service */
  getCbcTempMeds = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/cbcTempMeds`);
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  upsertCbcTempMeds = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/cbcTempMeds`,
        payload,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const medicalReviewService = new MedicalReviewService();

export default medicalReviewService;
