import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { AppBar, Toolbar, IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CaseBannerType } from 'types';
import CaseBanner from './CaseBanner';

const drawerWidth = 198;
const useStyles = makeStyles((theme: Theme) => ({
  drawerTop: {
    height: '47px',
    backgroundColor: 'white',
  },
  appBar: {
    marginTop: '55px',
    height: '55px',
    filter: 'brightness(.85)',
  },
  horizontalDrawerPaper: {
    padding: '0px 11px',
    marginBottom: '200px',
    color: 'white',
    display: 'flex',
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    top: '110px',
    left: 0,
    overflowY: 'auto',
    maxHeight: 'calc(100% - 110px)',
    height: '100%',
  },
}));

type Props = {
  banner: CaseBannerType;
};

function CaseBannerMobile({ banner }: Props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!banner?.id) {
    return null;
  }

  return (
    <div>
      <div className={classes.drawerTop} />
      <AppBar
        onClick={handleDrawerToggle}
        className={classes.appBar}
        style={{ alignItems: 'flex-start' }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{
              color: 'white',
              fontSize: '14px',
              fontWeight: 'bold',
              paddingRight: '15px',
            }}
          >
            {banner.donor.lastName}, {banner.donor.firstName}
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            size="large"
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.horizontalDrawerPaper,
        }}
        ModalProps={{
          BackdropProps: {
            invisible: true,
          },
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <CaseBanner banner={banner} />
      </Drawer>
    </div>
  );
}

export default CaseBannerMobile;
