export function limit(val, max) {
  let value = val;
  if (value.length === 1 && value[0] > max[0]) {
    value = `0${value}`;
  }
  if (value.length === 2) {
    if (Number(value) === 0) {
      value = '01';
      // this can happen when user paste number
    } else if (value > max) {
      value = max;
    }
  }
  return value;
}
export function limitWithZero(val, max) {
  let value = val;
  if (value.length === 2) {
    if (Number(value) === 0) {
      value = '00';
      // this can happen when user paste number
    } else if (value > max) {
      value = max;
    }
  }
  return value;
}

export function DateFormat(val) {
  const month = limit(val.substring(0, 2), '12');
  const date = limit(val.substring(2, 4), '31');
  const year = val.substring(4, 8);
  return (
    month + (date.length ? `/${date}` : '') + (year.length ? `/${year}` : '')
  );
}
export function DateTimeFormat(val) {
  const month = limit(val.substring(0, 2), '12');
  const date = limit(val.substring(2, 4), '31');
  const year = val.substring(4, 8);
  const hours = limitWithZero(val.substring(8, 10), '24');
  const mins = limitWithZero(val.substring(10, 12), '59');
  return (
    month +
    (date.length ? `/${date}` : '') +
    (year.length ? `/${year}` : '') +
    (hours.length ? ` ${hours}` : '') +
    (mins.length ? `:${mins}` : '')
  );
}
