import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function Permission({ children, permit }) {
  const account = useSelector((state) => state.account);
  if (account?.user?.permissions && account.user.permissions.includes(permit)) {
    return children;
  }

  return <></>;
}

Permission.propTypes = {
  children: PropTypes.any,
  permit: PropTypes.string.isRequired,
};

export default Permission;
