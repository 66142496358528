import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import {
  Box,
  Hidden,
  IconButton,
  Input,
  Paper,
  Button,
  Tooltip,
  Typography,
  SvgIcon,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Search as SearchIcon } from 'react-feather';
import {
  notesFilter,
  caseNotesFilter,
  openNewMessage,
  getNotes,
} from 'src/actions/notesActions';
import { serverState } from 'src/store/serverState';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    height: 68,
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
}));

function Toolbar({
  className,
  notes,
  onDeselectAll,
  onSelectAll,
  selectedNotes,
  isCaseNoteInstance,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSearchChange = (searchString) => {
    if (isCaseNoteInstance) {
      dispatch(caseNotesFilter(searchString));
    } else {
      dispatch(notesFilter(searchString));
    }
  };

  const handleComposeClick = () => {
    dispatch(openNewMessage());
  };

  const handleRefreshClick = () => {
    if (isCaseNoteInstance) {
      dispatch(serverState.util.invalidateTags(['CaseNotes']));
    } else {
      dispatch(getNotes());
    }
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      {isCaseNoteInstance ? (
        <Box flexGrow={1}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleComposeClick}
          >
            New <Hidden mdDown>Note</Hidden>
          </Button>
        </Box>
      ) : (
        <Box flexGrow={1} />
      )}
      <Paper
        component={Box}
        display="flex"
        alignItems="center"
        variant="outlined"
        py={0.5}
        px={1}
      >
        <SvgIcon
          color="action"
          fontSize="small"
        >
          <SearchIcon />
        </SvgIcon>
        <Input
          className={classes.searchInput}
          disableUnderline
          placeholder="Search notes"
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </Paper>
      <Tooltip title="Refresh">
        <IconButton
          onClick={handleRefreshClick}
          size="large"
        >
          <RefreshIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Hidden lgDown>
        <Tooltip title="More options">
          <IconButton size="large">
            <MoreIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Box
          display="flex"
          alignItems="center"
        >
          <Tooltip title="Next page">
            <IconButton size="large">
              <KeyboardArrowLeftIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {notes > 0 && (
            <>
              <Typography
                noWrap
                variant="body2"
                color="textSecondary"
              >
                1 - {notes} of {notes}
              </Typography>
              <Tooltip title="Previous page">
                <IconButton size="large">
                  <KeyboardArrowRightIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </Hidden>
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
  notes: PropTypes.number.isRequired,
  onDeselectAll: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedNotes: PropTypes.number.isRequired,
  isCaseNoteInstance: PropTypes.bool,
};

Toolbar.defualtProps = {
  onDeselectAll: () => {},
  onSelectAll: () => {},
};

export default Toolbar;
