import { TableCell, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { memo } from 'react';
import FormField from '../FormField';
import CustomField from '../CustomField';

const StyledTableCell = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F0F0F0',
    },
  },
}))(TableCell);
const CustomTableMobileRowCell = memo(
  ({
    columnData,
    columnIndex,
    mainIndex,
    headData,
    nonFormValue,
    rowObject,
    keyToUse,
    options,
  }) => {
    const isKey = keyToUse === columnData.id;
    let additionalFields = {};
    if (isKey) {
      additionalFields = {
        removeIcon: true,
        viewOnly: true,
        noColorChange: true,
      };
    }
    const renderField = () => {
      if (columnData?.renderCell) {
        const renderInput = {
          ...columnData.renderCell(mainIndex),
          props: {
            ...columnData.renderCell(mainIndex).props,
            zeroPadding: true,
            noPadding: true,
            ...additionalFields,
            width:
              columnData.inputType === 'dateTime' ? 'fit-content' : undefined,
          },
        };
        return renderInput;
      }
      if (options?.form) {
        const customDisabled =
          typeof columnData?.disabled === 'function'
            ? columnData.disabled(mainIndex)
            : columnData.disabled;

        const customMinDate =
          typeof columnData?.minDate === 'function' &&
          columnData.minDate(mainIndex);

        const customMaxDate =
          typeof columnData?.maxDate === 'function' &&
          columnData.maxDate(mainIndex);

        const customRequired =
          typeof columnData?.required === 'function'
            ? columnData.required(mainIndex)
            : columnData.required;

        return (
          <FormField
            {...columnData}
            disabled={customDisabled || false}
            minDate={customMinDate || columnData.minDates}
            maxDate={customMaxDate || columnData.maxDates}
            required={customRequired || columnData.required}
            cbChange={(e) => {
              return typeof columnData?.cbChange === 'function'
                ? columnData?.cbChange(e, mainIndex)
                : null;
            }}
            name={`${options?.form}.${mainIndex}.${columnData.id}`}
            table
            label={null}
            {...options?.global}
            zeroPadding
            noPadding
            {...additionalFields}
            width={
              columnData.inputType === 'dateTime' ? 'fit-content' : undefined
            }
          />
        );
      }

      return (
        <CustomField
          {...columnData}
          {...options?.global}
          width={
            columnData.inputType === 'dateTime' ? 'fit-content' : undefined
          }
          value={nonFormValue}
          table
          label={null}
          zeroPadding
          {...additionalFields}
          noPadding
        />
      );
    };
    return (
      <StyledTableCell
        key={`table_cell_${columnData.id}_${columnIndex || 'main'}`}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          borderBottom: isKey ? 0 : undefined,
          padding: !isKey ? '5px 5px 5px 23px' : '0px',
        }}
        {...rowObject}
        {...columnData}
      >
        {!isKey && headData?.label ? (
          <Typography style={{ fontWeight: 'bold' }}>
            {headData.label}
          </Typography>
        ) : null}
        {renderField()}
      </StyledTableCell>
    );
  },
);

export default CustomTableMobileRowCell;
