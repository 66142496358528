import axios from 'src/utils/axios';

export const TOGGLE_MODAL = '@attachments/toggle-modal';
export const CREATE_ATTACHMENT = '@attachments/create-attachment';
export const SET_ERROR = '@attachments/set-error';
export const SET_LOADING = '@attachments/set-loading';
export const CLEAR_ITEM = '@attachments/clear-item';

const checkTypes = (object) => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    // eslint-disable-next-line no-restricted-globals
    if (typeof value === 'string' && !isNaN(value)) {
      acc[key] = +value;
      return acc;
    }
    acc[key] = value;
    return acc;
  }, {});
};

export const toggleModal = (modalType, modalData) => ({
  type: TOGGLE_MODAL,
  payload: { modalType, modalData },
});

export const clearItem = () => ({
  type: CLEAR_ITEM,
});

export const createAttachment = (caseId, payload) => {
  const formBody = new FormData();

  Object.entries(payload).reduce((acc, [key, val]) => {
    formBody.append(key, val);
    return formBody;
  }, formBody);

  const request = axios.post(`/public/attachments/${caseId}`, formBody);

  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: { value: true },
    });

    request
      .then((response) => {
        dispatch({
          type: CREATE_ATTACHMENT,
          payload: checkTypes(response.data.item),
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};
