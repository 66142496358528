import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

function ApiKeyDialog({ apiData }) {
  const [dialogOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(`/admin/apiKeys/${apiData.id}`);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={dialogOpen}
    >
      <DialogTitle>
        <Typography variant="h4">API Key</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          API key has been created.{' '}
          <strong>You can only view this one time</strong>.
        </Typography>

        <Typography
          style={{
            padding: '13px 16px',
            backgroundColor: '#e4e1e6',
          }}
          gutterBottom
        >
          {apiData.key}
        </Typography>
        <Typography gutterBottom>
          Please store it somewhere safe because as soon as you navigate away
          from this page, we will not be able to retrieve or restore this
          generated token.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ApiKeyDialog.propTypes = {
  apiData: PropTypes.object,
};

export default ApiKeyDialog;
