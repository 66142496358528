function LogoSmall(props) {
  return (
    <img
      width="50px"
      alt="LogoSmall"
      src="/static/cc_logo_white.png"
      {...props}
    />
  );
}

export default LogoSmall;
