import axios from 'src/utils/axios';

export const SET_CURRENT_AUTH_FORM = '@authForm/set-current-auth-form';
export const GET_AUTH_FORMS = '@authForm/get-auth-forms';

export function getAuthForms(caseId) {
  const request = axios.get(`/public/authForm/${caseId}`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_AUTH_FORMS,
        payload: response.data,
      }),
    );
  };
}

export function setCurrentAuthForm(form) {
  return {
    type: SET_CURRENT_AUTH_FORM,
    payload: { form },
  };
}
