import {
  Dialog,
  DialogContent,
  Typography,
  SvgIcon,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { WifiOffRounded } from '@mui/icons-material';

import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  wrapper: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  noWifiIcon: {
    width: '100%',
    height: '100px',
  },
}));
function OfflineDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  return (
    <Dialog
      style={{ zIndex: '999999' }}
      className={classes.container}
      fullWidth
      maxWidth="sm"
      open
      fullScreen={fullScreen}
    >
      <DialogContent className={classes.wrapper}>
        <SvgIcon
          fontSize="large"
          className={classes.noWifiIcon}
        >
          <WifiOffRounded />
        </SvgIcon>
        <Typography
          style={{ fontWeight: 'bold' }}
          variant="h1"
        >
          You are offline
        </Typography>
        <Typography variant="body2">
          It seems there is a problem with your connection. Please check your
          network status.
          <br />
          Once connection has been re-established, this popup will close
          automatically.
        </Typography>
        <Typography variant="caption">
          Remaining offline may result in being automatically logged out.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default OfflineDialog;
