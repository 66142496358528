/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_SHIPPING_REPORT,
  SET_CURRENT_REPORT,
} from 'src/actions/reportActions';

const initialState = {
  reportData: [],
  currentReportName: '',
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHIPPING_REPORT: {
      const { reportData } = action.payload;
      return produce(state, (draft) => {
        draft.reportData = reportData;
      });
    }
    case SET_CURRENT_REPORT:
      return produce(state, (newState) => {
        newState.currentReportName = action.payload;
      });
    default:
      return state;
  }
};

export default reportReducer;
