export const SET_MODE = '@formPermissions/set-mode';
export const SET_INITIAL_FORM_VALUES =
  '@formPermissions/set-initial-form-values';

export function setMode(mode) {
  return (dispatch) => {
    dispatch({
      type: SET_MODE,
      payload: mode,
    });
  };
}

export function setInitialFormValues(values) {
  return (dispatch) => {
    dispatch({
      type: SET_INITIAL_FORM_VALUES,
      payload: values,
    });
  };
}
