/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_AVAILABLE_CASE_DATA,
  GET_AVAILABLE_RECIPIENTS,
  SET_IS_TRANSMITTING,
} from '../actions/transmitActions';

const initialState = {
  isTransmitting: false,
  approvals: [],
  attachments: [],
  caseNotes: [],
  logs: [],
  docTitles: [],
  users: [],
  organizations: [],
  signedDRAIs: [],
  signedAuthForms: [],
  signedSerologies: [],
  location: '',
};

const draiReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_CASE_DATA: {
      const {
        approvals,
        attachments,
        caseNotes,
        docTitles,
        signedDRAIs,
        signedSerologies,
        signedAuthForms,
        location,
      } = action.payload;

      return produce(state, (draft) => {
        draft.approvals = approvals;
        draft.attachments = attachments;
        draft.caseNotes = caseNotes;
        draft.docTitles = docTitles;
        draft.signedDRAIs = signedDRAIs;
        draft.signedAuthForms = signedAuthForms;
        draft.signedSerologies = signedSerologies;
        draft.location = location;
      });
    }

    case GET_AVAILABLE_RECIPIENTS: {
      const { users, organizations } = action.payload;

      // needed to .slice() each sort so that they become unfrozen and so they
      // are not readonly.
      // https://stackoverflow.com/questions/53420055/a/53420326

      return produce(state, (draft) => {
        draft.users = users
          .slice()
          .sort((a, b) => a.firstName.localeCompare(b.firstName));
        draft.organizations = organizations
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));
      });
    }

    case SET_IS_TRANSMITTING: {
      return produce(state, (draft) => {
        draft.isTransmitting = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default draiReducer;
