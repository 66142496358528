import { useRef, useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  Badge,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Bell as BellIcon,
  CheckCircle as CheckCircleIcon,
  Inbox as InboxIcon,
  // Truck as TruckIcon, could be used in the future?
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

function Notifications() {
  const classes = useStyles();
  const rawNotifications = useSelector(
    (state) => state.messageNotification.notifications,
  );
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Group the raw notifications together
  const notifications = useMemo(() => {
    return Object.values(
      rawNotifications.reduce((groups, notification) => {
        switch (notification.type) {
          case 'MESSAGE': {
            const count = (groups.message?.count || 0) + 1;
            return {
              ...groups,
              message: {
                ...(groups.message || {}),
                key: 'message',
                icon: InboxIcon,
                title: `New message${count > 1 ? 's' : ''} received`,
                description: `You have ${count} new message${
                  count > 1 ? 's' : ''
                }`,
                count,
              },
            };
          }
          case 'ASSIGNMENT':
          case 'GROUP_ASSIGNMENT': {
            const count = (groups.assignment?.count || 0) + 1;
            return {
              ...groups,
              assignment: {
                ...(groups.assignment || {}),
                key: 'assignment',
                icon: CheckCircleIcon,
                title: `New assignment${count > 1 ? 's' : ''} received`,
                description: `You have ${count} new assignment${
                  count > 1 ? 's' : ''
                }`,
                count,
              },
            };
          }
          case 'TASK': {
            const count = (groups.task?.count || 0) + 1;
            return {
              ...groups,
              task: {
                ...(groups.task || {}),
                key: 'task',
                icon: CheckCircleIcon,
                title: `New task${count > 1 ? 's' : ''} received`,
                description: `You have ${count} new task${
                  count > 1 ? 's' : ''
                }`,
                count,
              },
            };
          }
          default: {
            const count = (groups.other?.count || 0) + 1;
            return {
              ...groups,
              other: {
                ...(groups.other || {}),
                key: 'other',
                icon: CheckCircleIcon,
                title: `New unknown update${count > 1 ? 's' : ''} received`,
                description: `You have ${count} new update${
                  count > 1 ? 's' : ''
                }`,
                count,
              },
            };
          }
        }
      }, {}),
    );
  }, [rawNotifications]);

  const notificationCount = rawNotifications.length;

  return (
    <>
      <Tooltip title="Notifications">
        <Badge
          badgeContent={notificationCount}
          overlap="circular"
          color="secondary"
        >
          <IconButton
            color="inherit"
            ref={ref}
            onClick={handleOpen}
            size="large"
          >
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List
              className={classes.list}
              disablePadding
            >
              {notifications.map((notification) => {
                const Icon = notification.icon;
                return (
                  <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider
                    key={notification.key}
                    to="/inbox"
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: 'textPrimary',
                      }}
                      secondary={notification.description}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
