/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_DRAIS, SET_CURRENT_DRAI_FORM } from 'src/actions/draiActions';

type Action = {
  payload: any;
  type: string;
};

type CurrentDRAIForm = {
  value: string;
  raw: {
    label: string;
    value: string;
  };
};

type InitialState = {
  drais: [];
  currentDRAIForm: Partial<CurrentDRAIForm>;
};

const initialState: InitialState = {
  drais: [],
  currentDRAIForm: {},
};

const draiReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case GET_DRAIS: {
      const { drais } = action.payload;

      return produce(state, (draft) => {
        draft.drais = drais;
      });
    }

    case SET_CURRENT_DRAI_FORM: {
      const { form } = action.payload;

      return produce(state, (draft) => {
        draft.currentDRAIForm = form;
      });
    }

    default: {
      return state;
    }
  }
};

export default draiReducer;
