import { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Divider, Typography, SvgIcon, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { Send as SendIcon } from 'react-feather';
import Toolbar from './Toolbar';

const QuillEditor = lazy(() => import('src/components/QuillEditor'));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  avatar: {
    height: 56,
    width: 56,
  },
  date: {
    whiteSpace: 'nowrap',
  },
  message: {
    color: theme.palette.text.secondary,
    '& > p': {
      ...theme.typography.body1,
      marginBottom: theme.spacing(2),
    },
  },
  h1: {
    marginBottom: theme.spacing(2),
  },
  editor: {
    flexGrow: 1,
    '& .ql-editor': {
      minHeight: 300,
    },
    marginTop: -68,
  },
}));

const quillEditorModules = {
  toolbar: [],
};

function NoteDetails({ isCaseNoteDetailsInstance, noteId }) {
  const whichNotes = isCaseNoteDetailsInstance ? 'caseNotes' : 'notes';
  const classes = useStyles();
  const paramNoteId = useParams().noteId;
  const useNoteId = paramNoteId || noteId;

  const note = useSelector((state) => state.notes[whichNotes].byId[useNoteId]);

  if (!note) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <div className={classes.root}>
        {!isCaseNoteDetailsInstance && <Toolbar />}
        <Divider />
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          flexShrink={0}
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <Box ml={2}>
              <Typography
                variant="h1"
                color="textPrimary"
                className={classes.h1}
              >
                {note.noteType || 'Misc Note'}{' '}
                {note.allowTransmit && (
                  <Tooltip
                    title="Transmittable"
                    aria-label="transmittable"
                  >
                    <SvgIcon fontSize="small">
                      <SendIcon />
                    </SvgIcon>
                  </Tooltip>
                )}
              </Typography>
              <Typography
                display="block"
                variant="h5"
                color="textPrimary"
              >
                {note.author}
              </Typography>
              <Typography
                className={classes.date}
                color="textSecondary"
                variant="caption"
              >
                {note?.createdDate
                  ? moment(note.createdDate).format('MMMM Do YYYY, h:mm:ss a')
                  : ''}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
          />
        </Box>
        <Divider />
        <Box
          flexGrow={1}
          py={2}
          px={3}
          bgcolor="background.dark"
        >
          <Box mt={2}>
            <QuillEditor
              className={classes.editor}
              value={note.noteText ? note.noteText : ''}
              readOnly
              modules={quillEditorModules}
            />
          </Box>
        </Box>
        <Divider />
      </div>
    </Suspense>
  );
}

NoteDetails.propTypes = {
  isCaseNoteDetailsInstance: PropTypes.bool,
  noteId: PropTypes.number,
};

export default NoteDetails;
