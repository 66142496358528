import { Alert } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { useGetCaseBannerInfoQuery } from 'src/store/serverState';

export function RecoveryLayout(props: { children: React.ReactNode }) {
  const { caseId } = useParams();

  const banner = useGetCaseBannerInfoQuery(caseId);
  if (!banner.data) {
    return null;
  }
  if (!banner.data.referralAuthorized && !banner.data.locked) {
    return (
      <Alert severity="error">
        Donor not yet{' '}
        <Link to={`/case/${caseId}/referral/usage`}>authorized</Link>
      </Alert>
    );
  }

  return props.children;
}
