/* eslint-disable no-param-reassign */
import produce from 'immer';
import { SET_CONFIG } from 'src/actions/configActions';

const initialState = { config: {} };

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG: {
      const { config } = action.payload;

      return produce(state, (draft) => {
        // eslint-disable-next-line no-unused-vars
        draft.config = config;
      });
    }

    default: {
      return state;
    }
  }
};

export default configReducer;
