import axios from 'src/utils/axios';

export const UPSERT_PHYSICAL_ASSESSMENT =
  '@recovery/upsert-physical-assessment';
export const UPDATE_PHYSICAL_ASSESSMENT =
  '@recovery/update-physical-assessment';
export const REMOVE_PHYSICAL_ASSESSMENT =
  '@recovery/remove-physical-assessment';
export const GET_PHYSICAL_ASSESSMENT = '@recovery/get-physical-assessment';
export const CLEAR_PHYSICAL_ASSESSMENT = '@recovery/clear-physical-assessment';

export const UPSERT_SHIPPING_DETAIL = '@recovery/upsert-shipping-detail';
export const REMOVE_SHIPPING_DETAIL = '@recovery/remove-shipping-detail';
export const GET_SHIPPING_DETAIL = '@recovery/get-shipping-detail';
export const CLEAR_SHIPPING_DETAIL = '@recovery/clear-shipping-detail';

export const UPSERT_EYE_ASSESSMENT = '@recovery/upsert-eye-assessment';
export const REMOVE_EYE_ASSESSMENT = '@recovery/remove-eye-assessment';
export const GET_EYE_ASSESSMENT = '@recovery/get-eye-assessment';
export const CLEAR_EYE_ASSESSMENT = '@recovery/clear-eye-assessment';

export const SET_ERROR = '@recovery/set-error';
export const SET_CASE_ID = '@case/set-case-id';

export const UPDATE_VALIDATION_ROWS = '@recovery/update-validation-rows';
export const CLEAR_VALIDATION_ROWS = '@recovery/clear-validation-rows';

export const upsertPhysicalAssessment = (caseId, values) => {
  const request = axios.post(`/public/recovery/physicalAssessment/${caseId}`, {
    ...values,
  });

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: UPSERT_PHYSICAL_ASSESSMENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};

export const updatePhysicalAssessment = (caseId, values) => {
  const request = axios.put(`/public/recovery/physicalAssessment/${caseId}`, {
    ...values,
  });

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: UPDATE_PHYSICAL_ASSESSMENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};

export const getPhysicalAssessment = (caseId) => {
  const request = axios.get(`/public/recovery/physicalAssessment/${caseId}`);

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: GET_PHYSICAL_ASSESSMENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CLEAR_PHYSICAL_ASSESSMENT,
        });
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};

export const removePhysicalAssessment = (caseId) => {
  const request = axios.delete(`/public/recovery/physicalAssessment/${caseId}`);

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: REMOVE_PHYSICAL_ASSESSMENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};

export const upsertShippingDetail = (caseId, values) => {
  const request = axios.post(`/public/recovery/shippingDetail/${caseId}`, {
    ...values,
  });

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: UPSERT_SHIPPING_DETAIL,
          payload: response.data,
        });
        dispatch({
          type: SET_CASE_ID,
          payload: response.data?.item?.caseId,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};

export const getShippingDetail = (caseId) => {
  const request = axios.get(`/public/recovery/shippingDetail/${caseId}`);

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: GET_SHIPPING_DETAIL,
          payload: response.data,
        });
        dispatch({
          type: SET_CASE_ID,
          payload: response.data?.item?.caseId,
        });
      })
      .catch((error) => {
        dispatch({
          type: CLEAR_SHIPPING_DETAIL,
        });
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};

export const removeShippingDetail = (caseId) => {
  const request = axios.delete(`/public/recovery/shippingDetail/${caseId}`);

  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: REMOVE_SHIPPING_DETAIL,
        payload: response.data,
      });
    });
  };
};

export const upsertEyeAssessment = (caseId, values) => {
  const request = axios.post(`/public/recovery/eyeAssessment/${caseId}`, {
    ...values,
  });

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: UPSERT_EYE_ASSESSMENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
        dispatch({
          type: CLEAR_EYE_ASSESSMENT,
        });
      });
  };
};

export const getEyeAssessment = (caseId) => {
  const request = axios.get(`/public/recovery/eyeAssessment/${caseId}`);

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: GET_EYE_ASSESSMENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
        dispatch({
          type: CLEAR_EYE_ASSESSMENT,
        });
      });
  };
};

export const removeEyeAssessment = (caseId) => {
  const request = axios.delete(`/public/recovery/eyeAssessment/${caseId}`);

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: REMOVE_EYE_ASSESSMENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};
export const updateSupplyValidation = (validationRows) => {
  return {
    type: UPDATE_VALIDATION_ROWS,
    payload: validationRows,
  };
};

export const clearSupplyValidation = () => {
  return {
    type: CLEAR_VALIDATION_ROWS,
  };
};
