import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import accessLogService from 'src/services/accessLogService';
import { loginRoutes } from 'src/Routing';

function AccessLogger() {
  const location = useLocation();
  useEffect(() => {
    if (loginRoutes.find((r) => r.path === location.pathname)) return;
    let caseId = null;
    const potentialCaseId = location.pathname.slice(1).split('/')[1];
    if (
      potentialCaseId &&
      potentialCaseId.length === 10 &&
      !Number.isNaN(potentialCaseId) &&
      Number(potentialCaseId)
    ) {
      caseId = potentialCaseId;
    }

    accessLogService.logAccessEvent(caseId, location.pathname);
  }, [location.pathname]);

  return <></>;
}

export default AccessLogger;
