// @ts-nocheck
import { memo } from 'react';
import { REQUIRED_FIELD } from 'src/constants';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import moment from 'moment';
import { FormSelect, FormCheckbox, FormDateTime, FormTextField } from './form';

const FormField: React.FC<any> = memo(
  ({
    inputType, // text, select, date, dateTime, time, checkbox
    options, //  if inputType='select' ... list the options
    disabledOptionValues, // if inputType='select' ... any options that should be disabled/hidden when not selected
    label, // the text that appears above the field
    timezone: timezoneProp, // only if date, dateTime, time ... sets it to timezone, optional
    width, // default 100%, u can define any unit
    maxWidth, // default 100%, u can define any unit
    readOnly, // alternative to disabled, but doesn't gray it out
    step: stepProp, // inputType='text' && type='number' , defines decimals if u want decimals set step='any', optional
    mask: maskProp, // inputType='text', u can either specify a preset (phone). can customize
    maskChar, // default _ , placeholder if nothing is there
    secured: securedProp, // blured it will be dots, when its focused u can read it
    optionLabel, // inputType='select' && options of object, specify the object key that u want label to be
    optionValue, // inputType='select' && options of object, specify the object key that u want value to be
    cbChange, // callback on change, this is only partially, selects, but can be implemented on other fields if we need it
    getPreviousValues, // callback on delete for multi select
    onChange: onChangeProp, // this is called on every change (used for textfield)
    endProp, // what u want at the end inside of the field
    lookup, // instead of options or mode, u define lookup to get a specific lookup table
    mode, // instead of options or lookup, specify a mode (yeno, timezone)
    variant: variantProp, // outlined, standard, etc. (MUI) rarely changed. default outlined
    name: nameProp, // name of the input, also used for control change and formik value getting and setting
    includeName, // If true, the name is included in the HTML element (it is not by default to avoid autofill)
    squared, // removed the border radius
    type: typeProp, // u can specify number or a html type
    required: requiredProp, // adds * but still needs to be added to the formik validation as well
    min: minProp, // type='number' min value
    max: maxProp, // type='number' max value
    noPadding,
    outcomes: outcomesProp,
    newLoader,
    outcomeDetails: outcomeDetailsProp,
    multiline: multilineProp, // allows for more then one line primary use is for textareas
    rows: rowsProp, // specifys how many rows primary use for textareas
    rowsMax: rowsMaxProp, // limits the rows for a textarea
    disabled: disabledProp, // boolean value for disable
    labelPlacement: labelPlacementProp, // position of label in Checkbox can be | start | bottom | end | top
    table: tableProp, // show or hide the border
    futureDisabled,
    italic,
    pastDisabled,
    minDate: minDateProp,
    maxDate: maxDateProp,
    maxCustomDate: maxCustomDateProp,
    minCustomDate: minCustomDateProp,
    beforeDeath: beforeDeathProp, // checks if value is before date/time of death
    afterDeath: afterDeathProp, // checks if value is 72 hrs after date/time of death
    afterCustomDatetime: afterCustomDatetimeProp, // Allows us to specify to specify how much time, type of time and message to display
    beforeCustomDatetime: beforeCustomDatetimeProp, // Allows us to specify to specify how much time, type of time and message to display
    error: errorProp,
    defaultLocal,
    placeholder,
    paddingLeft,
    filter: filterProp,
    noFlash,
    groupBy, // group by options - adds header to drop down based on options passed
    noColorChange, // boolean if u want it to change color if no longer inital value
    lookupWhere, // find lookups specified by set of attributes
    noOptionsDisabled, // disable if no options are available
    roles: rolesProp, // prop for specifying role
    organizations: organizationsProp, // prop for specifying role
    mapValues, // prop for specifying role
    order: orderProp, // prop for giving an order to the select
    writeOnly, // always be in write mode even if it in in view mode
    viewOnly, // always be in view mode even if it in in write mode
    multiple: multipleProp, // can the user select multiple values
    noMargin: noMarginProp, // removes margin from checkbox
    removeIcons, // remove x and dropdown icon from select
    customValidate: validateProp, // custom validation
    alwaysSecured, // always show the dots
    justifyContent: justifyContentProp, // override checkbox justify content
    maxLines: maxLinesProp,
    dateValue,
    regex: regexProp,
    setTimezone: setTimezoneProp,
    showTimezone: showTimezoneProp,
    partners: partnersProp,
    orgPartners: orgPartnersProp,
    orgPartnersType: orgPartnersTypeProp,
    size,
    height: heightProp,
    zeroPadding,
    customStyle,
    noInitialValue, // passed to conditionally rendered fields or fields that populate based on other fields to properly clear out value on cancel
    autoFillValidate,
    expiredSupply,
    ...props // any other props
  }) => {
    const isRequired = (fieldValue) =>
      requiredProp && (fieldValue == null || fieldValue === '')
        ? REQUIRED_FIELD
        : null;

    const checkErrors = (fieldError) =>
      inputType === 'dateTime' &&
      fieldError &&
      (fieldError.split(' ').slice(0, 3).join(' ') === 'The date entered' ||
        fieldError.split(' ').slice(0, 3).join(' ') ===
          'The date/time entered' ||
        fieldError === 'Please select a timezone.' ||
        fieldError === 'Date required because of timezone.')
        ? fieldError
        : null;

    // formik context hook for getting the field setup
    const [field, meta, helpers] = useField({
      name: nameProp,
      validate: () =>
        isRequired(field.value) ||
        checkErrors(meta.error) ||
        validateProp(field.value),
      ...props,
    });

    const htmlName = includeName ? nameProp : null;
    switch (inputType) {
      case 'text': {
        return (
          <FormTextField
            error={errorProp || meta.error}
            name={htmlName}
            viewOnly={viewOnly}
            writeOnly={writeOnly}
            secured={securedProp}
            min={minProp}
            max={maxProp}
            italic={italic}
            label={label}
            placeholder={placeholder}
            size={size}
            variant={variantProp}
            height={heightProp}
            step={stepProp}
            table={tableProp}
            zeroPadding={zeroPadding}
            noPadding={noPadding}
            paddingLeft={paddingLeft}
            squared={squared}
            noColorChange={noColorChange}
            regex={regexProp}
            mask={maskProp}
            alwaysSecured={alwaysSecured}
            maskChar={maskChar}
            customStyle={customStyle}
            width={width}
            maxWidth={maxWidth}
            multiline={multilineProp}
            rows={rowsProp}
            rowsMax={rowsMaxProp}
            maxLines={maxLinesProp}
            changeCallback={(value) => {
              field.onBlur(value);
              cbChange(value);
            }}
            onChange={(value) => {
              onChangeProp(value);
            }}
            type={typeProp}
            disabled={disabledProp}
            readOnly={readOnly}
            endProp={endProp}
            required={requiredProp}
            value={field.value}
          />
        );
      }
      case 'select': {
        return (
          <FormSelect
            options={options}
            disabledOptionValues={disabledOptionValues}
            width={width || '100%'}
            label={label}
            name={htmlName}
            lookup={lookup}
            noPadding={noPadding}
            removeIcons={removeIcons}
            mode={mode}
            newLoader={newLoader}
            dateValue={dateValue}
            outcomes={outcomesProp}
            outcomeDetails={outcomeDetailsProp}
            squared={squared}
            customStyle={customStyle}
            partners={partnersProp}
            orgPartners={orgPartnersProp}
            orgPartnersType={orgPartnersTypeProp}
            readOnly={readOnly}
            multiple={multipleProp}
            maxWidth={maxWidth}
            variant={variantProp}
            value={field.value}
            filter={filterProp}
            zeroPadding={zeroPadding}
            changeCallback={({ value, raw }) => {
              field.onBlur(value);
              cbChange(value, raw);
            }}
            getPreviousValues={getPreviousValues}
            mapValues={({ value, raw }) => {
              mapValues(value, raw);
            }}
            optionLabel={optionLabel}
            optionValue={optionValue}
            error={errorProp || meta.error}
            disabled={disabledProp}
            noFlash={noFlash}
            groupBy={groupBy}
            required={requiredProp}
            secured={securedProp}
            noColorChange={noColorChange}
            table={tableProp}
            lookupWhere={lookupWhere}
            viewOnly={viewOnly}
            noOptionsDisabled={noOptionsDisabled}
            roles={rolesProp}
            organizations={organizationsProp}
            order={orderProp}
            writeOnly={writeOnly}
          />
        );
      }
      case 'checkbox': {
        return (
          <FormCheckbox
            width={width}
            writeOnly={writeOnly}
            viewOnly={viewOnly}
            label={label}
            customStyle={customStyle}
            name={nameProp}
            noPadding={noPadding}
            noMargin={noMarginProp}
            readOnly={readOnly}
            justifyContent={justifyContentProp}
            noColorChange={noColorChange}
            value={field.value}
            changeCallback={async (value) => {
              await field.onBlur(value);
              await cbChange(value);
            }}
            labelPlacement={labelPlacementProp}
            disabled={disabledProp}
          />
        );
      }
      case 'dateTime': {
        return (
          <FormDateTime
            width={width || '100%'}
            label={label}
            type={typeProp || 'dateTime'}
            error={errorProp || meta.error}
            table={tableProp}
            variant={variantProp}
            writeOnly={writeOnly}
            noColorChange={noColorChange}
            viewOnly={viewOnly}
            showTimezone={showTimezoneProp}
            zeroPadding={zeroPadding}
            noPadding={noPadding}
            secured={securedProp}
            customStyle={customStyle}
            name={htmlName}
            readOnly={readOnly}
            maxDate={maxDateProp}
            beforeDeath={beforeDeathProp}
            afterDeath={afterDeathProp}
            afterCustomDatetime={afterCustomDatetimeProp}
            beforeCustomDatetime={beforeCustomDatetimeProp}
            minCustomDate={minCustomDateProp}
            maxCustomDate={maxCustomDateProp}
            defaultLocal={defaultLocal}
            minDate={minDateProp}
            pastDisabled={pastDisabled}
            setTimezone={(value) => {
              setTimezoneProp(value);
            }}
            squared={squared}
            required={requiredProp}
            timezone={timezoneProp}
            value={field.value}
            disabled={disabledProp}
            changeCallback={({ value, error }) => {
              const dateFormat =
                typeProp === 'date' ? 'MM/DD/YYYY' : 'MM/DD/YYYY HH:mm Z';
              const fullFormat = 'YYYY-MM-DDTHH:mm:ssZ';
              if (
                error ||
                (!(moment(value, fullFormat, true).isValid() === true) &&
                  value !== null)
              ) {
                const defaultErrorMsg = `The ${
                  typeProp === 'date' ? 'date' : 'date/time'
                } entered is invalid.`;
                helpers.setError(error || defaultErrorMsg);
                field.onBlur(value);
                cbChange(value);
              } else if (
                !field.value ||
                moment(value).local().format(dateFormat) !==
                  moment(field.value).local().format(dateFormat) ||
                !(moment(field.value, fullFormat, true).isValid() === true) ||
                !(moment(value, fullFormat, true).isValid() === true)
              ) {
                helpers.setError();
                // below if statment prevents unsaved changes dialog bug
                if (
                  moment(field.value).local().format(dateFormat) !==
                  moment(value).local().format(dateFormat)
                ) {
                  field.onBlur(value);
                }
                cbChange(value);
              } else {
                helpers.setError();
                cbChange(value);
              }
            }}
            futureDisabled={futureDisabled}
            noInitialValue={noInitialValue}
            autoFillValidate={autoFillValidate}
            expiredSupply={expiredSupply}
          />
        );
      }
      default:
        return (
          <div>
            <pre>
              inputType
              {inputType} does not exist
            </pre>
          </div>
        );
    }
  },
);

FormField.propTypes = {
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  afterDeath: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  beforeDeath: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  afterCustomDatetime: PropTypes.object,
  beforeCustomDatetime: PropTypes.object,
  inputType: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  readOnly: PropTypes.bool,
  noFlash: PropTypes.bool,
  groupBy: PropTypes.func,
  squared: PropTypes.bool,
  options: PropTypes.array,
  customStyle: PropTypes.object,
  disabledOptionValues: PropTypes.array,
  filter: PropTypes.func,
  name: PropTypes.string,
  includeName: PropTypes.bool,
  lookup: PropTypes.string,
  mode: PropTypes.string,
  maskChar: PropTypes.string,
  error: PropTypes.bool,
  mask: PropTypes.string,
  cbChange: PropTypes.func,
  getPreviousValues: PropTypes.func,
  mapValues: PropTypes.func,
  customValidate: PropTypes.func,
  onChange: PropTypes.func,
  endProp: PropTypes.node,
  variant: PropTypes.string,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  secured: PropTypes.bool,
  type: PropTypes.string,
  step: PropTypes.string,
  timezone: PropTypes.string,
  setTimezone: PropTypes.func,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  disabled: PropTypes.bool,
  labelPlacement: PropTypes.string,
  lookupWhere: PropTypes.string,
  table: PropTypes.bool,
  futureDisabled: PropTypes.bool,
  pastDisabled: PropTypes.bool,
  noOptionsDisabled: PropTypes.bool,
  noColorChange: PropTypes.bool,
  writeOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  showTimezone: PropTypes.bool,
  maxWidth: PropTypes.string,
  roles: PropTypes.string,
  organizations: PropTypes.string,
  placeholder: PropTypes.string,
  partners: PropTypes.string,
  orgPartners: PropTypes.number,
  orgPartnersType: PropTypes.number,
  dateValue: PropTypes.string,
  viewOnly: PropTypes.bool,
  noPadding: PropTypes.bool,
  noMargin: PropTypes.bool,
  zeroPadding: PropTypes.bool,
  defaultLocal: PropTypes.bool,
  alwaysSecured: PropTypes.bool,
  italic: PropTypes.bool,
  removeIcons: PropTypes.bool,
  order: PropTypes.string,
  size: PropTypes.string,
  justifyContent: PropTypes.string,
  paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noInitialValue: PropTypes.bool,
  autoFillValidate: PropTypes.bool,
  expiredSupply: PropTypes.bool,
};

FormField.defaultProps = {
  cbChange: () => {},
  getPreviousValues: () => {},
  mapValues: () => {},
  onChange: () => {},
  setTimezone: () => {},
  filter: () => true,
  customValidate: () => false,
  groupBy: () => {},
  maxWidth: '100%',
  variant: 'outlined',
  customStyle: {},
  size: 'small',
  includeName: false,
  squared: false,
  required: false,
  error: false,
  secured: false,
  readOnly: false,
  removeIcons: false,
  multiline: false,
  defaultLocal: false,
  italic: false,
  disabled: false,
  writeOnly: false,
  table: false,
  multiple: false,
  noOptionsDisabled: false,
  noPadding: false,
  zeroPadding: false,
  noMargin: false,
  showTimezone: false,
  noColorChange: false,
  noFlash: false,
  alwaysSecured: false,
  viewOnly: false,
  labelPlacement: 'end',
  noInitialValue: false,
  autoFillValidate: false,
  expiredSupply: false,
};

export default FormField;
