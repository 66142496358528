/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as ACTIONS from '../actions/recoveryActions';

const initialState = {
  error: null,
  physicalAssessment: {},
  shippingDetail: {},
  eyeAssessment: {},
  serologies: [],
  dateValidationRows: [],
};

const recoveryReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTIONS.GET_PHYSICAL_ASSESSMENT:
        draft.physicalAssessment = action.payload.item;
        break;
      case ACTIONS.UPSERT_PHYSICAL_ASSESSMENT:
        draft.physicalAssessment = action.payload.item;
        break;
      case ACTIONS.UPDATE_PHYSICAL_ASSESSMENT:
        draft.physicalAssessment = action.payload.item;
        break;
      case ACTIONS.REMOVE_PHYSICAL_ASSESSMENT:
        draft.physicalAssessment = {};
        break;
      case ACTIONS.CLEAR_PHYSICAL_ASSESSMENT:
        draft.physicalAssessment = {};
        break;
      case ACTIONS.GET_SHIPPING_DETAIL:
        draft.shippingDetail = action.payload.item;
        break;
      case ACTIONS.UPSERT_SHIPPING_DETAIL:
        draft.shippingDetail = action.payload.item;
        break;
      case ACTIONS.REMOVE_SHIPPING_DETAIL:
        draft.shippingDetail = {};
        break;
      case ACTIONS.CLEAR_SHIPPING_DETAIL:
        draft.shippingDetail = {};
        break;
      case ACTIONS.GET_EYE_ASSESSMENT:
        draft.eyeAssessment = action.payload.item;
        break;
      case ACTIONS.UPSERT_EYE_ASSESSMENT:
        draft.eyeAssessment = action.payload.item;
        break;
      case ACTIONS.REMOVE_EYE_ASSESSMENT:
        draft.eyeAssessment = {};
        break;
      case ACTIONS.CLEAR_EYE_ASSESSMENT:
        draft.eyeAssessment = {};
        break;
      case ACTIONS.SET_ERROR:
        draft.error = action.payload;
        break;
      case ACTIONS.UPDATE_VALIDATION_ROWS:
        draft.dateValidationRows = action.payload;
        break;
      case ACTIONS.CLEAR_VALIDATION_ROWS:
        draft.dateValidationRows = {};
        break;
      default:
        break;
    }
  });

export default recoveryReducer;
