/* eslint-disable func-names */
import axios from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from './cache';
import baseURL from './baseUrl';

const isTest = import.meta.env.REACT_APP_ENVIRONMENT === 'testing';

const options = {
  baseURL,
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache',
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  adapter: isTest
    ? null
    : throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter)),
};

const instance = axios.create(options);

export default instance;
