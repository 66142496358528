import * as Yup from 'yup';
import { REQUIRED_FIELD } from 'src/constants';

type FieldVersionsType = {
  formVersion: number;
};

export const eyeOnlyBirthMotherSchema = (fieldVersions: FieldVersionsType) => {
  return Yup.object().shape({
    eyeOnlyBirthMotherQuestions: Yup.object()
      .nullable()
      .when('signatureBool', {
        is: true,
        then: Yup.object()
          .nullable()
          .shape({
            eyeOnlyBirthMotherQ2a: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ2ai: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ2a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ2aii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ2a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ2aiii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ2a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ2b: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ2bi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ2b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ2bii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ2b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ2biii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ2b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3a: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ3ai: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3aii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3b: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ3bi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3bii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3c: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ3ci: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3cii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3d: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ3di: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3dii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3e: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ3ei: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3eii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3f: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ3fi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3fii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3g: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ3gi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3gii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3h: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ3hi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ3hii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ3h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ4: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ4a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ4', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ4b: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ4', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ4c: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ4', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ5: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ5a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ5', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ5b: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ5', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ5c: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ5', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ5ci: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ5c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ5d: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ5', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ5e: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ5', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ5ei: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ5e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ5eii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ5e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ6: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ6a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ6', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ6b: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ6', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ6ai: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ7: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ7a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ7', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ7b: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ7', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ7c: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ7', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ7bia: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ7c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ7bii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ7c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ7biia: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ7c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ8: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ8a: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ8b: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ8bi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ8b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ9: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ9a: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ9', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ9b: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ9', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ9bi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ9b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ10: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ10a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ10b: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ11: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ11a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ11', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ11b: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ11', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ12: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ12a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ12', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ12b: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ12', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ13: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ13a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ13', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ14a: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ14ai: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ14a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ14b: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ14bi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ14b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ14c: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ14ci: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ14c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ14d: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ14di: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ14d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ14f: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ14fi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ14f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ14fii: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ14f', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ16: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ16a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ16b: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ16c: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ16d: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ16', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ16di: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ16d', {
                is: false,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ17a: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ17ai: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ17a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ17b: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ17bi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ17b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ19: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ19a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ19', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ19b: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ19', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ20a: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ20ai: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ20a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ20b: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ20bi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ20b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ20c: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ20ci: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ20c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ20d: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ20di: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ20d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ21: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ21a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ21b: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ22: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ22a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ22', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ22b: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ22', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ22bi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ22b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ22bii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ22b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ24: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ24a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ24', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ25: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ25a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ26: Yup.boolean()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ26a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ27: Yup.number()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ27a: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ27', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ27ai: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ27aia: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ27ai', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ28a: Yup.number()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ28ai: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ28a', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ28aia: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ28a', {
                is: 1,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ28aii: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ28aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ28b: Yup.number()
              .nullable()
              .required(REQUIRED_FIELD),
            eyeOnlyBirthMotherQ28bi: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ28b', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ28bii: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ28b', {
                is: 1,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ28bia: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ28bii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ28bib: Yup.boolean()
              .nullable()
              .when('eyeOnlyBirthMotherQ28bii', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeOnlyBirthMotherQ28bic: Yup.string()
              .nullable()
              .when('eyeOnlyBirthMotherQ28bib', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
          }),
      }),
  });
};
