import { useState, useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Portal,
  SvgIcon,
  Typography,
  Hidden,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  X as XIcon,
  Maximize as MaximizeIcon,
  Minimize as MinimizeIcon,
  ChevronDown,
  ChevronUp,
} from 'react-feather';
import { closeNewMessage, templateDialogOpen } from 'src/actions/notesActions';
import clsx from 'clsx';
import { CustomField } from 'src/components';
import { useSnackbar } from 'notistack';
import isEmpty from 'src/utils/isEmpty';
import { useAddCaseNoteMutation } from 'src/store/serverState';
import NoteTemplateDialog from '../NoteTemplateDialog';

const QuillEditor = lazy(() => import('src/components/QuillEditor'));

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: `calc(100% - ${theme.spacing(6)})`,
    maxHeight: `calc(100% - ${theme.spacing(6)})`,
    width: 600,
    minWidth: 600,
    position: 'fixed',
    margin: theme.spacing(3),
    outline: 'none',
    zIndex: theme.zIndex.modal,
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    [theme.breakpoints.down('lg')]: {
      paddingTop: '65px',
      margin: '0',
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100vw',
      height: '100vh',
      minWidth: 0,
    },
  },
  bottomSide: {
    bottom: 0,
    right: 0,
  },
  topSide: {
    top: 95,
    right: 0,
  },
  fullScreen: {
    height: '80%',
    width: '40%',
    minWidth: 650,
    [theme.breakpoints.down('lg')]: {
      width: '100vw',
      height: '100vh',
      minWidth: 0,
    },
  },
  input: {
    width: '100%',
  },
  editor: {
    flexGrow: 1,
    '& .ql-editor': {
      minHeight: 300,
    },
    overflow: 'hidden',
  },
  action: {
    marginRight: theme.spacing(1),
  },
  actionRight: {
    align: 'right',
    marginLeft: theme.spacing(0),
  },
  hidden: {
    display: 'none',
  },
  formControlLabel: {
    fontSize: '0.9rem',
  },
}));

function NoteNew({ caseId }) {
  const [saveNote] = useAddCaseNoteMutation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const newMessageOpen = useSelector((state) => state.notes.newMessageOpen);
  const templateDialogIsOpen = useSelector(
    (state) => state.notes.templateDialogIsOpen,
  );
  const [fullScreen, setFullScreen] = useState(false);
  const [flipped, setFlipped] = useState(false);
  const [attachToCase, setAttachToCase] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [noteTypeId, setNoteTypeId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [newNoteValue, setNewNoteValue] = useState('');

  const handleChange = (value) => {
    setNewNoteValue(value);
  };

  const handleExitFullScreen = () => {
    setFullScreen(false);
  };

  const handleEnterFullScreen = () => {
    setFullScreen(true);
  };

  const handleClose = () => {
    dispatch(templateDialogOpen(false));
    setNewNoteValue('');
    setNoteTypeId();
    setAttachToCase(true);
    dispatch(closeNewMessage());
  };

  const handleSave = async () => {
    if (
      !(saveDisabled || isEmpty(newNoteValue?.replace(/(<([^>]+)>)/gi, '')))
    ) {
      setSaveDisabled(true);
      if (noteTypeId && newNoteValue) {
        saveNote({
          noteTypeId,
          noteText: newNoteValue,
          caseId: caseId && attachToCase ? caseId : null,
        });

        setNewNoteValue('');
        setNoteTypeId();
        setSaveDisabled(false);
        dispatch(closeNewMessage());
      } else {
        enqueueSnackbar('Notes must have a note type and some text.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        setSaveDisabled(false);
      }
    }
  };

  const handleLoadTemplate = () => {
    dispatch(templateDialogOpen(true));
  };

  const closeDialog = () => {
    dispatch(templateDialogOpen(false));
  };

  const handleSwitchSides = () => {
    setFlipped(!flipped);
  };

  useEffect(() => {
    setAttachToCase(!!caseId);
  }, [caseId]);

  if (!newMessageOpen) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Portal>
        <NoteTemplateDialog
          setNewNoteValue={setNewNoteValue}
          open={templateDialogIsOpen}
          onClose={closeDialog}
        />
        <Paper
          className={clsx(classes.root, {
            [classes.fullScreen]: fullScreen,
            [classes.topSide]: flipped,
            [classes.bottomSide]: !flipped,
          })}
          elevation={12}
        >
          <Box
            bgcolor="background.dark"
            display="flex"
            alignItems="center"
            py={1}
            px={2}
          >
            <Typography
              variant="h5"
              color="textPrimary"
            >
              New Note
            </Typography>
            <Box flexGrow={1} />
            <Hidden lgDown>
              {flipped ? (
                <IconButton
                  onClick={handleSwitchSides}
                  size="large"
                >
                  <SvgIcon fontSize="small">
                    <ChevronDown />
                  </SvgIcon>
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleSwitchSides}
                  size="large"
                >
                  <SvgIcon fontSize="small">
                    <ChevronUp />
                  </SvgIcon>
                </IconButton>
              )}
              {fullScreen ? (
                <IconButton
                  onClick={handleExitFullScreen}
                  size="large"
                >
                  <SvgIcon fontSize="small">
                    <MinimizeIcon />
                  </SvgIcon>
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleEnterFullScreen}
                  size="large"
                >
                  <SvgIcon fontSize="small">
                    <MaximizeIcon />
                  </SvgIcon>
                </IconButton>
              )}
            </Hidden>
            <IconButton
              onClick={handleClose}
              size="large"
            >
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Box>
            <CustomField
              name="noteTypeId"
              placeholder="Note Type"
              inputType="select"
              lookup="noteType"
              lookupWhere="isOrgNoteType:false"
              noColorChange
              disabled={saveDisabled}
              writeOnly
              table
              value={noteTypeId}
              cbChange={({ value }) => {
                setNoteTypeId(value);
              }}
            />
          </Box>

          <QuillEditor
            className={classes.editor}
            disabled={saveDisabled}
            onChange={handleChange}
            placeholder="Enter note"
            value={newNoteValue || ''}
          />

          <Divider />
          <Box
            display="flex"
            alignItems="center"
            py={1}
            px={2}
            justifyContent="space-between"
          >
            <Button
              onClick={handleSave}
              disabled={
                saveDisabled ||
                isEmpty(newNoteValue?.replace(/(<([^>]+)>)/gi, ''))
              }
              color="secondary"
              variant="contained"
              className={classes.action}
            >
              Save
            </Button>
            <Box>
              <Button
                onClick={handleLoadTemplate}
                color="primary"
                size="small"
                className={classes.actionRight}
              >
                Insert Template
              </Button>
            </Box>
          </Box>
        </Paper>
      </Portal>
    </Suspense>
  );
}

export default NoteNew;
