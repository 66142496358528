import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { memo } from 'react';
import { CustomField } from 'src/components';
import { useGetCaseSearch, useSetCaseSearch } from './CaseSearchContext';

const useStyles = makeStyles((theme) => ({
  fieldRoot: {
    width: 'calc(50% - 6px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
const CaseSearchField = memo(({ searchOption }) => {
  const classes = useStyles();
  let additionalOptions = searchOption.fieldProps;
  const setCaseSearch = useSetCaseSearch();
  const caseSearchValue = useGetCaseSearch(
    `searchFields.${searchOption.value}`,
  );
  const outcomeValue = useGetCaseSearch(
    `searchFields.${searchOption.value.replace('Detail', '')}`,
  );
  if (additionalOptions?.outcomeDetails) {
    if (outcomeValue) {
      additionalOptions = {
        ...additionalOptions,
        outcomeDetails: {
          type: additionalOptions.outcomeDetails.type,
          outcome: outcomeValue,
        },
      };
    } else {
      additionalOptions = {
        ...additionalOptions,
        outcomeDetails: {
          type: additionalOptions.outcomeDetails.type,
          outcome: undefined,
        },
      };
    }
  }

  const handleChange = (event) => {
    if (searchOption.fieldProps.inputType === 'select') {
      setCaseSearch(`searchFields.${searchOption.value}`, event.value);
      if (additionalOptions?.outcomes) {
        setCaseSearch(`searchFields.${searchOption.value}Detail`, undefined);
      }
    } else {
      setCaseSearch(`searchFields.${searchOption.value}`, event);
    }
  };

  if (searchOption.fieldProps.inputType === 'text') {
    additionalOptions = {
      ...additionalOptions,
      onChange: (val) => handleChange(val),
    };
  }
  return (
    <Box
      className={classes.fieldRoot}
      key={searchOption.value}
    >
      <CustomField
        label={searchOption.label}
        writeOnly
        value={caseSearchValue}
        cbChange={(val) => handleChange(val)}
        noColorChange
        {...additionalOptions}
      />
    </Box>
  );
});

export default CaseSearchField;
