import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from './Toolbar';
import NoteItem from './NoteItem';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.dark,
  },
  hidden: {
    display: 'none',
  },
}));

function NotesList({ notes, isCaseNotesInstance }) {
  const classes = useStyles();
  const { filterString } = useSelector((state) => state.notes);
  const [selectedNotes, setSelectedNotes] = useState([]);

  const handleSelectAllNotes = () => {
    setSelectedNotes(notes.map((note) => note.id));
  };

  const handleDeselectAllNotes = () => {
    setSelectedNotes([]);
  };

  const handleSelectOneNote = (noteId) => {
    setSelectedNotes((prevSelectedNotes) => {
      if (!prevSelectedNotes.includes(noteId)) {
        return [...prevSelectedNotes, noteId];
      }

      return prevSelectedNotes;
    });
  };

  const handleDeselectOneNote = (noteId) => {
    setSelectedNotes((prevSelectedNotes) =>
      prevSelectedNotes.filter((id) => id !== noteId),
    );
  };

  const filterNoteDisplay = (note) => {
    if (note.noteText && typeof note.noteText === 'string') {
      return note.noteText.toLowerCase().includes(filterString);
    }
    return true;
  };

  return (
    <div className={classes.root}>
      <Toolbar
        onDeselectAll={handleDeselectAllNotes}
        onSelectAll={handleSelectAllNotes}
        selectedNotes={selectedNotes.length}
        notes={notes.allIds.length}
        isCaseNoteInstance={isCaseNotesInstance}
      />
      <Divider />
      {notes.allIds &&
        notes.allIds.length > 0 &&
        notes.allIds
          .filter((noteId) => filterNoteDisplay(notes.byId[noteId]))
          .map((noteId) => (
            <NoteItem
              note={notes.byId[noteId]}
              key={noteId}
              onDeselect={() => handleDeselectOneNote(noteId)}
              onSelect={() => handleSelectOneNote(noteId)}
              selected={selectedNotes.includes(noteId)}
              isCaseNoteInstance={isCaseNotesInstance} // drill go brrr
            />
          ))}
    </div>
  );
}

NotesList.propTypes = {
  notes: PropTypes.object,
  isCaseNotesInstance: PropTypes.bool,
};

export default NotesList;
