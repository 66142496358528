import { TableCell, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    whiteSpace: 'nowrap',
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: '0 16px',
    textAlign: 'left',
    '&:last-child': {
      borderRight: 'none',
    },
  },
}));

export default function RenderRow({ fieldName, fieldType, rowDetails }) {
  const classes = useStyles();

  const renderName = () => {
    if (rowDetails[fieldName]) {
      if (fieldType === 'date') {
        return moment(rowDetails[fieldName]).format('MM/DD/YYYY');
      }
      if (fieldType === 'dateTime') {
        return moment(rowDetails[fieldName]).format('MM/DD/YYYY HH:mm');
      }
      return rowDetails[fieldName];
    }
    return '';
  };

  return (
    <TableCell className={classes.tableCell}>
      <Typography variant="body1">{renderName()}</Typography>
    </TableCell>
  );
}
