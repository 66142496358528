import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
  reservedStatusIds,
  statusClasses,
  isStatus,
  generateColorClasses,
} from '../api';

const useTaskButtonStyles = makeStyles((theme) => {
  return {
    taskButton: {
      margin: '0 .5rem',
      minWidth: '5rem',
      maxHeight: '2rem',
      whiteSpace: 'nowrap',
      ...generateColorClasses(theme),
    },
  };
});

function StatusButton({ statusId, text, onClick }) {
  const classes = useTaskButtonStyles();
  return (
    <Button
      className={`${classes.taskButton} ${
        statusClasses[statusId] || 'unknown'
      }`}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {text}
    </Button>
  );
}
StatusButton.propTypes = {
  statusId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() => {
  return {
    root: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      overflowX: 'auto',
      display: 'flex',
      flexDirection: 'row-reverse',
      padding: '.5rem 0',
    },
  };
});

function StatusButtonRow({ task, otherStatuses, onClick }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {Object.entries(otherStatuses)
        .reverse()
        .map(([otherStatusId, name]) => {
          return (
            <StatusButton
              key={otherStatusId}
              taskId={task.id}
              statusId={+otherStatusId}
              text={name}
              onClick={() => onClick(+otherStatusId)}
            />
          );
        })}
      {isStatus(task.statusId, 'COMPLETE') ? (
        <StatusButton
          taskId={task.id}
          statusId={reservedStatusIds.OPEN}
          text="OPEN"
          onClick={() => onClick(reservedStatusIds.OPEN)}
        />
      ) : null}
      {isStatus(task.statusId, 'OPEN', 'COMPLETE') ? (
        <StatusButton
          taskId={task.id}
          statusId={reservedStatusIds.IN_PROGRESS}
          text="Start"
          onClick={() => onClick(reservedStatusIds.IN_PROGRESS)}
        />
      ) : null}
      {isStatus(task.statusId, 'IN_PROGRESS') ? (
        <StatusButton
          taskId={task.id}
          statusId={reservedStatusIds.OPEN}
          text="Stop"
          onClick={() => onClick(reservedStatusIds.OPEN)}
        />
      ) : null}
      {isStatus(task.statusId, 'IN_PROGRESS') ? (
        <StatusButton
          taskId={task.id}
          statusId={reservedStatusIds.COMPLETE}
          text="Complete"
          onClick={() => onClick(reservedStatusIds.COMPLETE)}
        />
      ) : null}
    </Box>
  );
}

StatusButtonRow.propTypes = {
  task: PropTypes.object.isRequired,
  otherStatuses: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

StatusButtonRow.defaultProps = {
  otherStatuses: {},
};

export default StatusButtonRow;
