import {
  Box,
  Button,
  Container,
  Typography,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import * as Sentry from '@sentry/react';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f4f6f8',
    minHeight: '100%',
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    padding: '50px 20px',
    height: 'calc(100vh - 16px)',
    width: 'calc(100vw - 16px)',
  },
  errorTitle: {
    fontSize: '30px',
    fontFamily: 'Arial',
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
}));

function CrashScreen() {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Container className={classes.root}>
          <Typography
            align="center"
            variant="h2"
            color="textPrimary"
          >
            1002: Error Occurred
          </Typography>
          <Typography
            align="center"
            variant="h5"
            color="textSecondary"
          >
            Please try again later.
          </Typography>

          <Box
            mt={6}
            display="flex"
            gap="12px"
            justifyContent="center"
          >
            <Button
              color="secondary"
              onClick={() => window.location.reload()}
              variant="outlined"
            >
              Refresh
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                const newError = Sentry.captureException(
                  new Error(`1002: Error Occurred`),
                );
                Sentry.showReportDialog({
                  subtitle:
                    'Tell us what happened below without including any Protected Health Information.',
                  subtitle2: '',
                  labelSubmit: 'Submit Report',
                  eventId: newError,
                });
              }}
              variant="outlined"
            >
              Give feedback
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default CrashScreen;
