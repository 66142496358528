// The portal wrapper for case task (though it's generic and can be for anything)
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Paper,
  Portal,
  SvgIcon,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  X as XIcon,
  Maximize as MaximizeIcon,
  Minimize as MinimizeIcon,
  ChevronDown,
  ChevronUp,
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: `calc(100% - ${theme.spacing(6)})`,
    maxHeight: `calc(100% - ${theme.spacing(6)})`,
    height: 400,
    width: 600,
    position: 'fixed',
    margin: theme.spacing(3),
    outline: 'none',
    zIndex: theme.zIndex.modal,
    display: 'flex',
    flexDirection: 'column',
  },
  bottomSide: {
    bottom: 0,
    right: 0,
  },
  topSide: {
    top: 95,
    right: 0,
  },
  fullScreen: {
    height: '80%',
    width: '40%',
    minWidth: 650,
  },
  input: {
    width: '100%',
  },
  editor: {
    flexGrow: 1,
    '& .ql-editor': {
      minHeight: 300,
    },
  },
  action: {
    marginRight: theme.spacing(1),
  },
  actionRight: {
    align: 'right',
    margin: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
}));

function PortalWrapper({ title, onClose, children }) {
  const classes = useStyles();
  const [fullScreen, setFullScreen] = useState(true);
  const [flipped, setFlipped] = useState(false);

  return (
    <Portal>
      <Paper
        className={`${classes.root} ${fullScreen ? classes.fullScreen : ''} ${
          flipped ? classes.topSide : classes.bottomSide
        }`}
        elevation={12}
      >
        <Box
          bgcolor="background.dark"
          display="flex"
          alignItems="center"
          py={1}
          px={2}
        >
          <Typography
            variant="h5"
            color="textPrimary"
          >
            {title || ''}
          </Typography>
          <Box flexGrow={1} />
          {flipped ? (
            <IconButton
              onClick={() => setFlipped(!flipped)}
              size="large"
            >
              <SvgIcon fontSize="small">
                <ChevronDown />
              </SvgIcon>
            </IconButton>
          ) : (
            <IconButton
              onClick={() => setFlipped(!flipped)}
              size="large"
            >
              <SvgIcon fontSize="small">
                <ChevronUp />
              </SvgIcon>
            </IconButton>
          )}
          {fullScreen ? (
            <IconButton
              onClick={() => setFullScreen(false)}
              size="large"
            >
              <SvgIcon fontSize="small">
                <MinimizeIcon />
              </SvgIcon>
            </IconButton>
          ) : (
            <IconButton
              onClick={() => setFullScreen(true)}
              size="large"
            >
              <SvgIcon fontSize="small">
                <MaximizeIcon />
              </SvgIcon>
            </IconButton>
          )}
          <IconButton
            onClick={onClose ? () => onClose() : null}
            size="large"
          >
            <SvgIcon fontSize="small">
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        {children}
      </Paper>
    </Portal>
  );
}

PortalWrapper.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
};

export default PortalWrapper;
