/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as ACTIONS from '../actions/attachmentsActions';

const initialState = {
  loading: false,
  show: false,
  modalType: null,
  modalData: null,
  item: null,
};

const attachmentsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTIONS.TOGGLE_MODAL:
        draft.show = !draft.show;
        draft.modalType = action.payload.modalType;
        draft.modalData = action.payload.modalData;
        break;
      case ACTIONS.SET_LOADING:
        draft.loading = true;
        break;
      case ACTIONS.CREATE_ATTACHMENT:
        draft.item = action.payload;
        draft.loading = false;
        break;
      case ACTIONS.SET_ERROR:
        draft.item = null;
        draft.loading = true;
        break;
      case ACTIONS.CLEAR_ITEM:
        draft.item = null;
        break;
      default:
        break;
    }
  });

export default attachmentsReducer;
