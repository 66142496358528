import axios from 'src/utils/axios';

export const OPEN_REPORT_VIEW = '@reports/report-viewer';
export const SET_CURRENT_REPORT = '@reports/set-current-report';
export const GET_SHIPPING_REPORT = '@reports/get-shipping-report';

export function setCurrentReport(reportName) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_REPORT,
      payload: reportName,
    });
  };
}

export function getShippingReport() {
  const request = axios.get(`/public/reports/shippingReport`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_SHIPPING_REPORT,
        payload: response.data,
      }),
    );
  };
}
