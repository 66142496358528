import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public';

class UserService {
  getSessionInformation = async () => {
    try {
      const request = await axios.get(`${baseURL}/sessionInformation`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  refreshSession = async () => {
    try {
      const request = await axios.get(`${baseURL}/refreshSession`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getUserAdmin = async (userId) => {
    try {
      const request = await axios.get(`${baseURL}/admin/users/${userId}`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getSelf = async () => {
    try {
      const request = await axios.get(`${baseURL}/settings`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  checkPasswordAdmin = async (userId, password) => {
    try {
      const request = await axios.post(
        `${baseURL}/admin/checkPreviousPassword/${userId}`,
        { password },
      );
      if (request.status === 200) {
        return request.data.used;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  checkPasswordSelf = async (password) => {
    try {
      const request = await axios.post(`${baseURL}/checkPreviousPassword`, {
        password,
      });
      if (request.status === 200) {
        return request.data.used;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateUser = async (userId, payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/admin/users/${userId}`,
        payload,
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateSelf = async (payload) => {
    try {
      const request = await axios.put(`${baseURL}/settings`, payload);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createUser = async (payload) => {
    try {
      const request = await axios.post(`${baseURL}/admin/users`, payload);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getAllUsers = async () => {
    try {
      const request = await axios.get('/public/users');
      if (request.status === 200) {
        return request.data.users;
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getAdvisors = async () => {
    try {
      const request = await axios.get('/public/advisors');
      if (request.status === 200) {
        return request.data.users;
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getResetPassword = async (tokenString) => {
    try {
      const request = await axios.post('/public/password/checkReset', {
        tokenString,
      });
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}

const userService = new UserService();

export default userService;
