import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,

      hoverContent: {
        position: 'absolute',
        right: '0',
        backgroundColor: '#0f0',
      },
    },
  },
  eventWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  hoverContent: {
    position: 'absolute',
    right: '0',
  },
  details: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
  },
  name: {
    [theme.breakpoints.up('md')]: {
      minWidth: 180,
      flexBasis: 180,
    },
  },
  subject: {
    maxWidth: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  message: {
    maxWidth: 400,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 'auto',
  },
  date: {
    whiteSpace: 'nowrap',
  },
}));

function Item({
  color,
  subject,
  text,
  dateText,
  className,
  onClick,
  id,
  renderOnHover,
  ...props
}) {
  const classes = useStyles();
  const [hovering, setHovering] = useState(false);

  return (
    <div
      style={{
        borderLeft: `3px solid ${color || 'transparent'}`,
      }}
      className={classes.root}
      onClick={onClick ? () => onClick(id) : null}
      onMouseOver={() => {
        setHovering(true);
      }}
      onFocus={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
      onBlur={() => {
        setHovering(false);
      }}
      {...props}
    >
      <div className={classes.eventWrapper} />
      {hovering && renderOnHover ? renderOnHover() : null}

      <Box
        minWidth="1px"
        display="flex"
        flexGrow={1}
      >
        <Box
          minWidth="1px"
          ml={1}
          className={classes.details}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.subject}
          >
            {subject || ''}
          </Typography>
          {hovering && renderOnHover ? null : (
            <>
              <Hidden mdDown>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.message}
                >
                  <Box
                    component="span"
                    ml={2}
                  />
                  {text ? text.replace(/(<([^>]+)>)/gi, '') : ''}
                </Typography>
              </Hidden>
              <Typography
                className={classes.date}
                color="textSecondary"
                variant="caption"
              >
                {dateText}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
}

Item.propTypes = {
  color: PropTypes.string,
  subject: PropTypes.string,
  text: PropTypes.string,
  dateText: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.any.isRequired,
  renderOnHover: PropTypes.func,
  onClick: PropTypes.func.isRequired,
};

Item.defaultProps = {
  renderOnHover: null,
};

export default Item;
