import * as Yup from 'yup';
import moment from 'moment';

const validation = Yup.lazy(() => {
  return Yup.object().shape({
    nextStepDueDate: Yup.mixed().test(
      'nextStepDueDate',
      'The date/time entered is invalid.',
      (value) => !value || (`${value}`.length > 10 && moment(value).isValid()),
    ),
  });
});

export default validation;
