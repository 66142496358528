import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public/admin/roles';

class RoleService {
  getRoleAdmin = async (roleName) => {
    try {
      const request = await axios.get(`${baseURL}/${roleName}`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getAll = async () => {
    try {
      const request = await axios.get(`/public/roles/all`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createRole = async (caseId, payload) => {
    try {
      const request = await axios.post(`${baseURL}/${caseId}`, payload);
      if (request.status === 200) {
        return request.data.serology;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateRole = async (roleName, payload) => {
    try {
      const request = await axios.put(`${baseURL}/${roleName}`, payload);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const roleService = new RoleService();

export default roleService;
