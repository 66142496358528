import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { logout, setUserData } from 'src/actions/accountActions';
import { setConfigData } from 'src/actions/configActions';
import authService from 'src/services/authService';
import * as Sentry from '@sentry/react';
import { useLogoutTabs } from 'src/context/BroadcastContext';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const logoutTabs = useLogoutTabs();

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => {
          dispatch(logout());
          logoutTabs();
        },
      });

      authService
        .refresh()
        .then((data) => {
          if (data?.config) {
            if (data?.user) {
              dispatch(setUserData(data.user));
              const sentryUser = {
                id: data.user.id,
                email: data.user.email,
                firstName: data.user.firstName,
                lastName: data.user.lastName,
              };
              Sentry.setUser(sentryUser);
            } else {
              dispatch(setUserData(null));
              Sentry.setUser(null);
            }
            dispatch(setConfigData(data.config));
          }
          setLoading(false);
        })
        .catch((err) => {
          Sentry.captureException(err);
          setLoading(false);
        });
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;
