import * as Yup from 'yup';
import { REQUIRED_FIELD } from 'src/constants';

type FieldVersionsType = {
  formVersion: number;
};

export const eyeDraiGt12Schema = (fieldVersions: FieldVersionsType) => {
  const tbQuestions =
    fieldVersions.formVersion > 2
      ? {
          eyeGt12q34: Yup.boolean().nullable().required(REQUIRED_FIELD),
          eyeGt12q34a: Yup.string()
            .nullable()
            .when('eyeGt12q34', {
              is: true,
              then: Yup.string().nullable().required(REQUIRED_FIELD),
            }),
          eyeGt12q34b: Yup.boolean()
            .nullable()
            .when('eyeGt12q34', {
              is: true,
              then: Yup.boolean().nullable().required(REQUIRED_FIELD),
            }),
          eyeGt12q34bi: Yup.string()
            .nullable()
            .when('eyeGt12q34b', {
              is: true,
              then: Yup.string().nullable().required(REQUIRED_FIELD),
            }),
          eyeGt12q34bii: Yup.string()
            .nullable()
            .when('eyeGt12q34b', {
              is: true,
              then: Yup.string().nullable().required(REQUIRED_FIELD),
            }),
        }
      : undefined;
  return Yup.object().shape({
    eyeOnlyGt12Questions: Yup.object()
      .nullable()
      .when('signatureBool', {
        is: true,
        then: Yup.object()
          .nullable()
          .shape({
            ...tbQuestions,
            eyeGt12q4a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q4ai: Yup.string()
              .nullable()
              .when('eyeGt12q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q4aii: Yup.string()
              .nullable()
              .when('eyeGt12q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q4aiii: Yup.string()
              .nullable()
              .when('eyeGt12q4a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q4b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q4bi: Yup.string()
              .nullable()
              .when('eyeGt12q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q4bii: Yup.string()
              .nullable()
              .when('eyeGt12q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q4biii: Yup.string()
              .nullable()
              .when('eyeGt12q4b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q5a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q5ai: Yup.string()
              .nullable()
              .when('eyeGt12q5a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q5b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q5bi: Yup.string()
              .nullable()
              .when('eyeGt12q5b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6ai: Yup.string()
              .nullable()
              .when('eyeGt12q6a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6aii: Yup.string()
              .nullable()
              .when('eyeGt12q6a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6bi: Yup.string()
              .nullable()
              .when('eyeGt12q6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6bii: Yup.string()
              .nullable()
              .when('eyeGt12q6b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6ci: Yup.string()
              .nullable()
              .when('eyeGt12q6c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6cii: Yup.string()
              .nullable()
              .when('eyeGt12q6c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6d: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6di: Yup.string()
              .nullable()
              .when('eyeGt12q6d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6dii: Yup.string()
              .nullable()
              .when('eyeGt12q6d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6e: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6ei: Yup.string()
              .nullable()
              .when('eyeGt12q6e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6eii: Yup.string()
              .nullable()
              .when('eyeGt12q6e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6f: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6fi: Yup.string()
              .nullable()
              .when('eyeGt12q6f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6fii: Yup.string()
              .nullable()
              .when('eyeGt12q6f', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6g: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6gi: Yup.string()
              .nullable()
              .when('eyeGt12q6g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6gii: Yup.string()
              .nullable()
              .when('eyeGt12q6g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6h: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6hi: Yup.string()
              .nullable()
              .when('eyeGt12q6h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6hii: Yup.string()
              .nullable()
              .when('eyeGt12q6h', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6i: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6ii: Yup.string()
              .nullable()
              .when('eyeGt12q6i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6iii: Yup.string()
              .nullable()
              .when('eyeGt12q6i', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6j: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6ji: Yup.string()
              .nullable()
              .when('eyeGt12q6j', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6jii: Yup.string()
              .nullable()
              .when('eyeGt12q6j', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6k: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6ki: Yup.string()
              .nullable()
              .when('eyeGt12q6k', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6kii: Yup.string()
              .nullable()
              .when('eyeGt12q6k', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6l: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6li: Yup.string()
              .nullable()
              .when('eyeGt12q6l', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6lii: Yup.string()
              .nullable()
              .when('eyeGt12q6l', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6m: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q6mi: Yup.string()
              .nullable()
              .when('eyeGt12q6m', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q6mii: Yup.string()
              .nullable()
              .when('eyeGt12q6m', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q8: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q8a: Yup.boolean()
              .nullable()
              .when('eyeGt12q8', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q8ai: Yup.boolean()
              .nullable()
              .when('eyeGt12q8a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q8aia: Yup.boolean()
              .nullable()
              .when('eyeGt12q8ai', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q8aiai: Yup.string()
              .nullable()
              .when('eyeGt12q8aia', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q9: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q9a: Yup.string()
              .nullable()
              .when('eyeGt12q9', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q10: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q10a: Yup.string()
              .nullable()
              .when('eyeGt12q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q10b: Yup.string()
              .nullable()
              .when('eyeGt12q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q10c: Yup.boolean()
              .nullable()
              .when('eyeGt12q10', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q10ci: Yup.string()
              .nullable()
              .when('eyeGt12q10c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q10d: Yup.string()
              .nullable()
              .when('eyeGt12q10', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q10e: Yup.boolean()
              .nullable()
              .when('eyeGt12q10d', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q10ei: Yup.string()
              .nullable()
              .when('eyeGt12q10e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q10eii: Yup.string()
              .nullable()
              .when('eyeGt12q10e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q11: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q11a: Yup.string()
              .nullable()
              .when('eyeGt12q11', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q11b: Yup.boolean()
              .nullable()
              .when('eyeGt12q11', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q11ai: Yup.string()
              .nullable()
              .when('eyeGt12q11b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q12: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q12a: Yup.string()
              .nullable()
              .when('eyeGt12q12', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q12b: Yup.string()
              .nullable()
              .when('eyeGt12q12', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q12c: Yup.boolean()
              .nullable()
              .when('eyeGt12q12', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q12bi: Yup.boolean()
              .nullable()
              .when('eyeGt12q12c', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q12bia: Yup.string()
              .nullable()
              .when('eyeGt12q12bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q12bii: Yup.string()
              .nullable()
              .when('eyeGt12q12bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q12biia: Yup.string()
              .nullable()
              .when('eyeGt12q12bi', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q13: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q13a: Yup.boolean()
              .nullable()
              .when('eyeGt12q13', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q14: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q14a: Yup.boolean()
              .nullable()
              .when('eyeGt12q14', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q15a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q15ai: Yup.string()
              .nullable()
              .when('eyeGt12q15a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q15aii: Yup.boolean()
              .nullable()
              .when('eyeGt12q15a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q16: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q16a: Yup.string()
              .nullable()
              .when('eyeGt12q16', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q16b: Yup.boolean()
              .nullable()
              .when('eyeGt12q16', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q17: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q17a: Yup.string()
              .nullable()
              .when('eyeGt12q17', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q17b: Yup.boolean()
              .nullable()
              .when('eyeGt12q17', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q18: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q18a: Yup.string()
              .nullable()
              .when('eyeGt12q18', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q19a: Yup.boolean()
              .nullable()
              .when('eyeGt12q19', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19ai: Yup.string()
              .nullable()
              .when('eyeGt12q19a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19bi: Yup.string()
              .nullable()
              .when('eyeGt12q19b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19di: Yup.string()
              .nullable()
              .when('eyeGt12q19d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19c: Yup.boolean()
              .nullable()
              .when('eyeGt12q19', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19ci: Yup.string()
              .nullable()
              .when('eyeGt12q19c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19e: Yup.boolean()
              .nullable()
              .when('eyeGt12q19', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19ei: Yup.string()
              .nullable()
              .when('eyeGt12q19e', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19g: Yup.boolean()
              .nullable()
              .when('eyeGt12q19', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19gi: Yup.string()
              .nullable()
              .when('eyeGt12q19g', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q19gii: Yup.boolean()
              .nullable()
              .when('eyeGt12q19g', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q21: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q21a: Yup.string()
              .nullable()
              .when('eyeGt12q21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q21c: Yup.string()
              .nullable()
              .when('eyeGt12q21', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q21d: Yup.boolean()
              .nullable()
              .when('eyeGt12q21', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q21di: Yup.string()
              .nullable()
              .when('eyeGt12q21d', {
                is: false,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q22a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q22ai: Yup.string()
              .nullable()
              .when('eyeGt12q22a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q22b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q22bi: Yup.string()
              .nullable()
              .when('eyeGt12q22b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q23: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q23a: Yup.string()
              .nullable()
              .when('eyeGt12q23', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q25: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q25a: Yup.string()
              .nullable()
              .when('eyeGt12q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q25b: Yup.string()
              .nullable()
              .when('eyeGt12q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q25c: Yup.string()
              .nullable()
              .when('eyeGt12q25', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q26: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q26a: Yup.string()
              .nullable()
              .when('eyeGt12q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q26b: Yup.string()
              .nullable()
              .when('eyeGt12q26', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q26c: Yup.boolean()
              .nullable()
              .when('eyeGt12q26', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q26ci: Yup.string()
              .nullable()
              .when('eyeGt12q26c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q26cii: Yup.string()
              .nullable()
              .when('eyeGt12q26c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q27: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q27a: Yup.boolean()
              .nullable()
              .when('eyeGt12q27', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q27ai: Yup.string()
              .nullable()
              .when('eyeGt12q27a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q27aii: Yup.string()
              .nullable()
              .when('eyeGt12q27a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q27aiii: Yup.boolean()
              .nullable()
              .when('eyeGt12q27a', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q27aiia: Yup.string()
              .nullable()
              .when('eyeGt12q27aiii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q28: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q28a: Yup.string()
              .nullable()
              .when('eyeGt12q28', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q28b: Yup.string()
              .nullable()
              .when('eyeGt12q28', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q29a: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q29ai: Yup.string()
              .nullable()
              .when('eyeGt12q29a', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q29b: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q29bi: Yup.string()
              .nullable()
              .when('eyeGt12q29b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q29c: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q29ci: Yup.string()
              .nullable()
              .when('eyeGt12q29c', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q30: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q30a: Yup.string()
              .nullable()
              .when('eyeGt12q30', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q30b: Yup.string()
              .nullable()
              .when('eyeGt12q30', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q32: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q32a: Yup.string()
              .nullable()
              .when('eyeGt12q32', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q32ai: Yup.string()
              .nullable()
              .when('eyeGt12q32aii', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q32b: Yup.string()
              .nullable()
              .when('eyeGt12q32', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q32c: Yup.string()
              .nullable()
              .when('eyeGt12q32', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q32d: Yup.boolean()
              .nullable()
              .when('eyeGt12q32', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q32di: Yup.string()
              .nullable()
              .when('eyeGt12q32d', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q36: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q36a: Yup.string()
              .nullable()
              .when('eyeGt12q36', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q36b: Yup.boolean()
              .nullable()
              .when('eyeGt12q36', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q36bi: Yup.string()
              .nullable()
              .when('eyeGt12q36b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q42: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q42a: Yup.string()
              .nullable()
              .when('eyeGt12q42', {
                is: true,
                then: Yup.string()
                  .nullable()
                  .when('eyeGt12q42ana', {
                    is: false,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('eyeGt12q42ana', {
                    is: undefined,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('eyeGt12q42ana', {
                    is: null,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  }),
              }),
            eyeGt12q42b: Yup.string()
              .nullable()
              .when('eyeGt12q42', {
                is: true,
                then: Yup.string()
                  .nullable()
                  .when('eyeGt12q42bna', {
                    is: false,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('eyeGt12q42bna', {
                    is: undefined,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  })
                  .when('eyeGt12q42bna', {
                    is: null,
                    then: Yup.string().nullable().required(REQUIRED_FIELD),
                  }),
              }),
            eyeGt12q42c: Yup.string()
              .nullable()
              .when('eyeGt12q42', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q42d: Yup.string()
              .nullable()
              .when('eyeGt12q42', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q43: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q43a: Yup.string()
              .nullable()
              .when('eyeGt12q43', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q43ai: Yup.boolean()
              .nullable()
              .when('eyeGt12q43', {
                is: true,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q43aia: Yup.string()
              .nullable()
              .when('eyeGt12q43ai', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q43b: Yup.string()
              .nullable()
              .when('eyeGt12q43', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q45: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q45a: Yup.string()
              .nullable()
              .when('eyeGt12q45', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q46: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q46a: Yup.string()
              .nullable()
              .when('eyeGt12q46', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q47: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q47a: Yup.string()
              .nullable()
              .when('eyeGt12q47', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q48: Yup.boolean().nullable().required(REQUIRED_FIELD),
            eyeGt12q48a: Yup.string()
              .nullable()
              .when('eyeGt12q48', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q49: Yup.number().nullable().required(REQUIRED_FIELD),
            eyeGt12q49a: Yup.string()
              .nullable()
              .when('eyeGt12q49', {
                is: 1,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q49b: Yup.boolean()
              .nullable()
              .when('eyeGt12q49', {
                is: 1,
                then: Yup.boolean().nullable().required(REQUIRED_FIELD),
              }),
            eyeGt12q49ai: Yup.string()
              .nullable()
              .when('eyeGt12q49b', {
                is: true,
                then: Yup.string().nullable().required(REQUIRED_FIELD),
              }),
          }),
      }),
  });
};
