import axios from 'src/utils/axios';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      },
    );
  };

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('/public/login', { email, password })
        .then((response) => {
          if (response.data.user) {
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  loginInWithToken = () => {
    return axios
      .get('/public/current')
      .then((response) => {
        if (response.data.user) {
          return response.data.user;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  };

  logout = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/public/logout')
        .then((response) => {
          if (response.data) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  isAuthenticated = () => {
    return axios
      .get('/public/current')
      .then((response) => {
        if (response.data.user) {
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  };

  refresh = () => {
    return axios
      .get('/public/refresh')
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return false;
      });
  };
}

const authService = new AuthService();

export default authService;
