/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState, useCallback } from 'react';
import {
  TextField as Text,
  useTheme,
  InputAdornment,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import InputMask from 'react-input-mask';
import isEmpty from 'src/utils/isEmpty';
import { useAppSelector } from 'src/store';

const displayLengthCalc = (str: string, maxLen: number, maxLine: number) => {
  if (str) {
    const maxLineLength = Math.floor(maxLen / maxLine);
    const lines = str.split('\n');
    const paddedDisplay = lines
      .map((line, idx, arr) => {
        return idx === arr.length - 1
          ? line
          : line +
              // use eszett so it doesn't conflict with actual user data characters
              'ß'.repeat(
                maxLineLength >= line.length
                  ? maxLineLength - line.length
                  : maxLineLength,
              );
      })
      .join('');
    return paddedDisplay;
  }
  return '';
};

type TempInputStyling = {
  borderRadius?: number;
  padding?: string;
  fontFamily?: string;
  fontStyle?: string;
  height?: string;
};
type TempClasses = {
  notchedOutline?: string;
  underline?: string;
  input?: string;
};

export type FormTextFieldProps = {
  value?: string | number | null;
  type?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  name?: string;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  error?: string;
  changeCallback?: (e: any) => void;
  onChange?: (e: number | null | undefined) => void;
  viewOnly?: boolean;
  writeOnly?: boolean;
  maxWidth?: string;
  helperText?: string;
  size?: 'medium' | 'small' | undefined;
  squared?: boolean;
  secured?: boolean;
  alwaysSecured?: boolean;
  noColorChange?: boolean;
  table?: boolean;
  width?: string;
  paddingLeft?: string | number;
  height?: string;
  maskChar?: string;
  step?: string;
  mask?: string;
  noPadding?: boolean;
  placeholder?: string;
  multiline?: boolean;
  zeroPadding?: boolean;
  italic?: boolean;
  rows?: number;
  rowsMax?: number;
  endProp?: any;
  min?: number;
  max?: number;
  customStyle?: any;
  maxLines?: {
    maxLength?: number;
    maxLines?: number;
  };
  regex?: RegExp;
};

const FormTextField = ({
  type: typeProp = 'text', // type for input text 'text' or 'number'
  value: valueProp, // the inital value you want it to start with
  label, // the label of the *input*
  changeCallback = () => null, // function executed on blur
  onChange: onChangeProp = () => null, // function executed on change
  noColorChange = false, // boolean if u want it to change color if no longer inital value
  endProp, // function executed on change
  width = '100%', // percentage
  maxWidth, // percentage
  customStyle,
  error: errorProp, // a string with an error message
  name: nameProp, // the name of the input
  variant: variantProp = 'outlined', // string of which variant it is (standard, filled, outlined)
  readOnly: readOnlyProp = false, // boolean for displaying normal but not allowing type (disabled is different)
  squared = false, // boolean if you want the border to be straight or roudned
  required: requiredProp = false, // boolean for checking required validation
  secured: securedProp = false, // boolean for checking if a value is suppose to be hidden
  disabled: disabledProp = false, // boolean for checking disabled,
  mask: maskProp, // boolean for checking disabled,
  helperText,
  maskChar: maskCharProp = '_', // boolean for checking disabled,
  table: tableProp = false, // show or hide the border
  writeOnly = false, // always be in write mode even if it in in view mode
  viewOnly = false, // always be in view mode even if it in in write mode
  min: minProp, // min the number can be in type='number'
  max: maxProp, // max the number can be in type='number
  step: stepProp, // specify how much of a step to take e.g 10 will go 10-20-30 .
  rows: rowsProp, // set how many rows the multiline text
  rowsMax: rowsMaxProp, // set the max rows for the multiline text
  multiline: multilineProp = false, // alow for the text to be multiple line
  maxLines: maxLinesProp,
  alwaysSecured = false,
  size = 'small',
  noPadding = false,
  paddingLeft,
  regex: regexProp,
  placeholder,
  italic = false,
  height: heightProp,
  zeroPadding = false,
}: FormTextFieldProps): JSX.Element => {
  const [localValue, setLocalValue] = useState(valueProp);
  const [isFocused, setisFocused] = useState(false);
  const [inputStyling, setInputStyling] = useState({});

  const [customClasses, setCustomClasses] = useState({});
  const [isViewMode, setIsViewMode] = useState(true); // boolean for conditionally determining if the form is in view mode
  const [isInitialValue, setIsInitialValue] = useState(true); // boolean for conditionally determining if the selected value has changed from it's initial value
  const [helperTextMessage, setHelperTextMessage] = useState(helperText);
  const [initialValueField] = useState(valueProp);
  // use redux global state
  const formMode = useAppSelector((state) => state.formPermissions.mode);

  const [toolTipOpen, setToolTipOpen] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const updateHelperText = useCallback(() => {
    setHelperTextMessage(helperText);
  }, [helperText]);

  useEffect(() => {
    updateHelperText();
  }, [updateHelperText]);

  // a function to set the state of isViewMode and isInitialValue
  const setFormPermissionValues = useCallback(async () => {
    if (formMode) {
      if (writeOnly) {
        setIsViewMode(false);
      } else {
        setIsViewMode(viewOnly || formMode === 'view');
      }
    }

    if (localValue != null && initialValueField != null) {
      setIsInitialValue(localValue === initialValueField);
    } else if (localValue != null) {
      setIsInitialValue(false);
    } else {
      setIsInitialValue(true);
    }
  }, [formMode, localValue, initialValueField, viewOnly, writeOnly]);

  useEffect(() => {
    setFormPermissionValues();
  }, [setFormPermissionValues]);

  const setCurrentValue = useCallback(async () => {
    setLocalValue(valueProp);
  }, [valueProp]);

  useEffect(() => {
    setCurrentValue();
  }, [setCurrentValue]);

  const checkMaskModes = (mask: FormTextFieldProps['mask']) => {
    switch (mask) {
      // phone preset
      case 'phone':
        return '(999) 999-9999';

      // if not preset, custom mask
      default:
        return mask || '';
    }
  };

  const stripMaskValue = (
    value: FormTextFieldProps['value'],
    mask: FormTextFieldProps['mask'],
  ) => {
    if (typeof value !== 'string') {
      return value;
    }
    switch (mask) {
      // phone preset
      // strip any character that is not a number
      case 'phone':
        return value.replace(/[^0-9]+/g, '');

      // if not preset, custom mask
      default:
        return value;
    }
  };

  // set certain styling based on options
  const setStyling = useCallback(() => {
    // set initial temp variable for styling and classes to nothing
    const tempInputStyling: TempInputStyling = {};
    const tempClasses: TempClasses = {};

    // remove the rounded borders if squared is specified
    if (squared) {
      tempInputStyling.borderRadius = 0;
    }

    if (noPadding) {
      tempInputStyling.padding = '5px 10px';
    }
    if (italic) {
      tempInputStyling.fontStyle = 'italic';
    }

    if (zeroPadding) {
      tempInputStyling.padding = '0px';
    }

    if (heightProp) {
      tempInputStyling.height = heightProp;
    }

    // if secured is true then check if they are focused on the
    // field, if they are then set the font family to a bullet point
    // font family
    if (securedProp) {
      if (!isFocused) {
        tempInputStyling.fontFamily = 'text-security-disc';
      }
    }

    // if always secured set the font family to a bullet point
    // font family
    if (alwaysSecured) {
      tempInputStyling.fontFamily = 'text-security-disc';
    }

    // set state to the temp variable and return
    setInputStyling(tempInputStyling);
    setCustomClasses(tempClasses);
    return { tempInputStyling, tempClasses };
  }, [
    securedProp,
    squared,
    isFocused,
    alwaysSecured,
    heightProp,
    zeroPadding,
    readOnlyProp,
    variantProp,
    noPadding,
  ]);

  useEffect(() => {
    setStyling();
  }, [setStyling]);

  // a function on the blur that sends it the value back
  const handleValue = () => {
    setisFocused(false);
    if (!isEmpty(localValue)) {
      return changeCallback(stripMaskValue(localValue, maskProp));
    }
    return changeCallback(null);
  };

  const handleChange = (value: any) => {
    // logic for not changing if min or max or both or specified
    // also sending the value back for a callback handler
    if (helperText === 'Maximum comments length reached') {
      setHelperTextMessage(undefined);
    }
    if (!isEmpty(value)) {
      if (maxLinesProp?.maxLength && maxLinesProp?.maxLines) {
        const { maxLength, maxLines } = maxLinesProp;
        const paddedDisplay = displayLengthCalc(value, maxLength, maxLines);
        if (paddedDisplay.length >= maxLength) {
          setHelperTextMessage('Maximum comments length reached');
          const truncatedValue = paddedDisplay
            .slice(0, maxLength)
            .replace(/ß+/g, '\n'); // use eszett so it doesn't conflict with actual user data characters
          setLocalValue(truncatedValue);
          return truncatedValue;
        }
      }
      if (regexProp) {
        const regexValue = value.replace(regexProp, '');
        setLocalValue(regexValue);
        return regexValue;
      }
      if (
        minProp !== null &&
        minProp !== undefined &&
        maxProp !== null &&
        maxProp !== undefined
      ) {
        if (Number(value) >= minProp && Number(value) <= maxProp) {
          setLocalValue(value);
          return value;
        }
        return localValue;
      }
      if (minProp !== null && minProp !== undefined) {
        if (Number(value) >= minProp) {
          setLocalValue(value);
          return value;
        }
        return localValue;
      }
      if (maxProp !== null && maxProp !== undefined) {
        if (Number(value) <= maxProp) {
          setLocalValue(value);
          return value;
        }
        return localValue;
      }
      setLocalValue(value);
      return value;
    }
    setLocalValue(undefined);
    return null;
  };
  // props that will either be on the input or mask component depending on what is set
  //         e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  const generalProps = {
    value: localValue || '',
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      onChangeProp(handleChange(e.target.value)),
    onFocus: () => setisFocused(true),
    onBlur: () => handleValue(),
    disabled: disabledProp,
  };

  // used to toggle mobile tooltip, auto closes in 5 sec
  const toggleTip = () => {
    if (localValue) {
      setToolTipOpen(!toolTipOpen);
      setTimeout(() => {
        setToolTipOpen(false);
      }, 3000);
    }
  };

  // checking to see if mask has been specified to figure out where to apply props to
  const textFieldProps = !maskProp && generalProps;
  const maskProps = maskProp && generalProps;
  // render the text field (can be used standalone or in mask)
  const renderTextField = mobileView ? (
    <Tooltip
      title={localValue || ''}
      disableFocusListener
      placement="top-start"
      open={toolTipOpen}
      sx={{
        '.MuiTooltip-tooltip': {
          width: 'fit-content',
          fontSize: '1.2rem',
        },
      }}
    >
      <div
        style={{ width: '100%' }}
        onClick={() => (isViewMode ? toggleTip() : null)}
      >
        <Text
          {...textFieldProps}
          sx={{
            MozAppearance: 'textfield',
            '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                display: 'none',
                margin: 0,
                padding: 0,
              },
            cursor:
              !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
            pointerEvents:
              !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
            color:
              isInitialValue || noColorChange || isViewMode
                ? 'inherit'
                : '#af1685',
            '& .MuiInput-root:before': {
              borderBottom: readOnlyProp ? 'none' : 'auto',
            },
            '& .MuiInput-root:after': {
              borderBottom: readOnlyProp ? 'none' : 'auto',
            },
          }}
          type={typeProp}
          error={!!errorProp}
          helperText={errorProp || helperTextMessage}
          label={label}
          placeholder={placeholder}
          size={size}
          variant={readOnlyProp ? 'standard' : variantProp}
          name={nameProp}
          InputLabelProps={{
            shrink: true,
            disabled: disabledProp,
          }}
          inputProps={{
            tabIndex:
              isViewMode || readOnlyProp || disabledProp ? -1 : undefined,
            autoComplete: 'new-password',
            max: maxProp,
            min: minProp,
            step: stepProp,
          }}
          InputProps={{
            sx: {
              '.MuiOutlinedInput-notchedOutline': {
                border:
                  !readOnlyProp && !isViewMode && requiredProp
                    ? '3px solid'
                    : tableProp
                      ? 0
                      : 'auto',
              },

              cursor:
                !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
              pointerEvents:
                !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
              color:
                isInitialValue || noColorChange || isViewMode
                  ? 'inherit'
                  : '#af1685',
              paddingTop: zeroPadding ? 0 : 'auto',
              paddingBottom: zeroPadding ? 0 : 'auto',
              padding: zeroPadding ? '0 !important' : 'auto',
              height: zeroPadding ? 'undefined' : 'auto',
              paddingLeft: zeroPadding ? 0 : 'auto',
            },
            style: inputStyling,
            tabIndex:
              isViewMode || readOnlyProp || disabledProp ? -1 : undefined,
            classes: customClasses,
            readOnly: readOnlyProp || isViewMode,
            disabled: disabledProp,
            inputProps: {
              tabIndex:
                isViewMode || readOnlyProp || disabledProp ? -1 : undefined,
              autoComplete: 'new-password',
              max: maxProp,
              min: minProp,
              step: stepProp,
            },
            endAdornment: endProp && (
              <InputAdornment position="end">{endProp}</InputAdornment>
            ),
          }}
          style={{
            width,
            maxWidth,
            height: heightProp,
            ...customStyle,
          }}
          autoComplete="new-password"
          required={requiredProp}
          multiline={multilineProp}
          rows={rowsProp || rowsMaxProp}
        />
      </div>
    </Tooltip>
  ) : (
    <Text
      {...textFieldProps}
      sx={{
        MozAppearance: 'textfield',
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
          {
            display: 'none',
            margin: 0,
            padding: 0,
          },
        cursor: !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
        pointerEvents:
          !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
        color:
          isInitialValue || noColorChange || isViewMode ? 'inherit' : '#af1685',
        '& .MuiInput-root:before': {
          borderBottom: readOnlyProp ? 'none' : 'auto',
        },
        '& .MuiInput-root:after': {
          borderBottom: readOnlyProp ? 'none' : 'auto',
        },
      }}
      type={typeProp}
      error={!!errorProp}
      helperText={errorProp || helperTextMessage}
      label={label}
      placeholder={placeholder}
      size={size}
      inputProps={{
        tabIndex: isViewMode || readOnlyProp || disabledProp ? -1 : undefined,
        autoComplete: 'new-password',
        max: maxProp,
        min: minProp,
        step: stepProp,
      }}
      variant={readOnlyProp ? 'standard' : variantProp}
      name={nameProp}
      InputLabelProps={{
        shrink: true,
        disabled: disabledProp,
      }}
      InputProps={{
        sx: {
          '.MuiOutlinedInput-notchedOutline': {
            border:
              !readOnlyProp && !isViewMode && requiredProp
                ? '3px solid'
                : tableProp
                  ? 0
                  : 'auto',
          },

          cursor: !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
          pointerEvents:
            !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
          color:
            isInitialValue || noColorChange || isViewMode
              ? 'inherit'
              : '#af1685',
          paddingTop: zeroPadding ? 0 : 'auto',
          paddingBottom: zeroPadding ? 0 : 'auto',
          padding: zeroPadding ? '0 !important' : 'auto',
          height: zeroPadding ? 'undefined' : 'auto',
          paddingLeft: zeroPadding ? 0 : 'auto',
        },
        style: inputStyling,
        tabIndex: isViewMode || readOnlyProp || disabledProp ? -1 : undefined,
        classes: customClasses,
        readOnly: readOnlyProp || isViewMode,
        disabled: disabledProp,

        endAdornment: endProp && (
          <InputAdornment position="end">{endProp}</InputAdornment>
        ),
      }}
      style={{
        width,
        maxWidth,
        height: heightProp,
        ...customStyle,
      }}
      autoComplete="new-password"
      required={requiredProp}
      multiline={multilineProp}
      rows={rowsProp || rowsMaxProp}
    />
  );

  // rendering the mask by using the textfield above
  const renderMaskTextField = () => (
    <InputMask
      {...maskProps}
      mask={checkMaskModes(maskProp)}
      maskChar={maskCharProp}
    >
      {(() => renderTextField) as any}
    </InputMask>
  );

  // figuring out which render to show
  return maskProp ? renderMaskTextField() : renderTextField;
};

export default FormTextField;
