import * as Yup from 'yup';

const validation = Yup.object().shape({
  worksheets: Yup.object().shape({
    // This validation step is disabled for now.
    // The original design for hemodilution was to immediately show an error on the postMortem dialog
    // the moment a user set or cleared the postMortem select.
    // Presumably, this would not block saving. It would just be a visual indicator to the user.
    // By design, this is not possible in the FormField yeno dropdown.
    // There is no way to show an error on a FormField outside of a validation error.
    // It is also not possible to use this validation and not break the form submit.
    // So, this code now introduces a regression into hemodilution, where the postMortem does not turn red,
    // but in exchange for breaking functionality it uses the common FormField components. :)
    //
    // pages: Yup.array().of(
    //   Yup.object().shape({
    //     postMortem: Yup.mixed().nullable().required(REQUIRED_FIELD),
    //   }),
    // ),
  }),
});

export default validation;
