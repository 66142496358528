/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_AUTH_FORMS,
  SET_CURRENT_AUTH_FORM,
} from 'src/actions/authFormActions';

const initialState = {
  authForms: [],
  currentAuthForm: {},
};

const authFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTH_FORMS: {
      const { authForms } = action.payload;

      return produce(state, (draft) => {
        draft.authForms = authForms;
      });
    }

    case SET_CURRENT_AUTH_FORM: {
      const { form } = action.payload;

      return produce(state, (draft) => {
        draft.currentAuthForm = form;
      });
    }

    default: {
      return state;
    }
  }
};

export default authFormReducer;
