import PropTypes from 'prop-types';
import { Box, Dialog, Typography } from '@mui/material';

function AlertNoteDialog({ open, onClose, notes, ...rest }) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      {...rest}
    >
      <Box p={3}>
        <Typography
          align="center"
          gutterBottom
          variant="h3"
          color="textPrimary"
        >
          Organization Notes
        </Typography>
        {notes.map((note, index) => (
          <Box
            display="flex"
            justifyContent="space-between"
            key={`alert_note_${note.id}`}
            mb={1}
          >
            <Box mr={1}>
              <Typography
                gutterBottom
                variant="caption"
                color="textPrimary"
              >
                {`${index + 1}. `}
              </Typography>
            </Box>
            <Typography
              gutterBottom
              variant="caption"
              color="textPrimary"
            >
              {note.noteText}
            </Typography>
          </Box>
        ))}
      </Box>
    </Dialog>
  );
}

AlertNoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AlertNoteDialog;
