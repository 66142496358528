import { TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { memo } from 'react';
import RenderRow from './RenderRow';

const useStyles = makeStyles(() => ({
  tableRowHover: {
    '&:hover': {
      background: '#e7e8e9',
      cursor: 'pointer',
    },
  },
}));

const ReadOnlyRow = memo(({ rowDetails, clickable, tableColumns, onClick }) => {
  const classes = useStyles();

  if (rowDetails?.active !== undefined && !rowDetails.active) return null;

  return (
    <TableRow
      className={clickable ? classes.tableRowHover : undefined}
      onClick={() => onClick(rowDetails)}
      key={rowDetails.id}
    >
      {tableColumns.map(({ fieldName, fieldType }) => (
        <RenderRow
          key={fieldName}
          fieldName={fieldName}
          fieldType={fieldType}
          rowDetails={rowDetails}
        />
      ))}
    </TableRow>
  );
});

export default ReadOnlyRow;
