import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    width: '100%',
    marginBottom: 0,
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 2,
    color: theme.palette.secondary.main,
  },
}));

function GroupText({ children }, props) {
  const classes = useStyles();

  return (
    <Typography
      color="primary"
      className={classes.root}
      variant="overline"
      style={{
        width: '100%',
      }}
      paragraph
      {...props}
    >
      {children}
    </Typography>
  );
}

GroupText.propTypes = {
  props: PropTypes.object,
  children: PropTypes.node,
};

export default GroupText;
