import PropTypes from 'prop-types';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  arrow: {
    // These are !important because MaterialUI defaults the color to a dark gray
    color: `${theme.palette.common.white} !important`,
    '& > svg': { color: `${theme.palette.common.white} !important` },
  },
  subHeader: {
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.common.white}`,
    },
    '&:last-child': {
      borderRight: `10px solid ${theme.palette.primary.main}`,
    },
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.primary.main}`,
    },
  },

  filter: {
    border: 0,
  },
  tableHeader: {
    textAlign: 'center',
    padding: 0,
    whiteSpace: 'noWrap',
    // color: theme.palette.common.white,
    border: 0,

    '& .filterHead': {
      borderRight: `1px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },

    '& .subHeader': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '&:not(:last-child)': {
      '& .subHeader': {
        borderRight: `1px solid ${theme.palette.common.white}`,
      },
    },
    '&:last-child': {
      '& .subHeader': {
        borderRight: `1px solid ${theme.palette.primary.main}`,
        marginRight: '-1px',
      },
    },
    '&:first-child': {
      '& .subHeader': {
        borderLeft: `1px solid ${theme.palette.primary.main}`,
      },
      '& .filterHead': {
        borderLeft: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
}));

function EnhancedTableHead({
  colSortOrder,
  onRequestSort,
  headCells,
  options,
}) {
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const lastSortColRaw = colSortOrder[0];
  const orderBy = lastSortColRaw
    ? lastSortColRaw.slice(0, lastSortColRaw.lastIndexOf('_'))
    : '';
  const isAscending = !lastSortColRaw?.endsWith('Desc');
  const order = isAscending ? 'asc' : 'desc';

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableHeader}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? isAscending : false}
            style={{
              maxWidth: headCell.maxWidth,
              width: headCell.width,
            }}
          >
            <Box className="subHeader">
              {options?.sort ? (
                <TableSortLabel
                  className={classes.arrow}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {isAscending === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  colSortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
};

export default EnhancedTableHead;
