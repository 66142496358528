/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CREATE_CASE,
  DUPLICATE_CASE_OPEN,
  DUPLICATE_CASE_CLOSE,
  DUPLICATE_CASE_OVERRIDE,
  GET_CASE,
  UPDATE_CASE,
  OPEN_CASE_SEARCH,
  CLOSE_CASE_SEARCH,
  GET_CASE_STATUS_LIST,
  GET_FAMILY_STATUS_LIST,
  CREATE_CASE_PARTNERS,
  GET_CASE_SUMMARY,
  CREATE_CASE_CONTACTS,
  CREATE_CASE_OUTCOMES,
  GET_OUTCOME_LIST,
  GET_TITLE_LIST,
  UPDATE_CASE_CONTACTS,
  GET_OUTCOME_TYPE_LIST,
  GET_CASE_CONTACTS,
  GET_OUTCOME_DETAIL_LIST,
  CLEAR_CASE,
  CREATE_CASE_SUMMARY,
  GET_CASE_BANNER_INFORMATION,
  CLEAR_CASE_CONTACTS,
  GET_CASE_CONTACTS_KINS,
  SET_CASE_JURISDICTION_ASSUMED,
  SET_CASE_ID,
  OPEN_RECENT_CASES,
  CLOSE_RECENT_CASES,
} from 'src/actions/caseActions';

const initialState = {
  caseItem: {
    id: null,
    callerLastName: '',
    callerFirstName: '',
    donorFirstName: '',
    donorLastName: '',
    callerPhone: null,
    dateNotified: null,
    statlinePhone: null,
    referringComment: '',
    antibiotics: [{ typeId: true, doses: 2, description: 'send' }],
    culture: [{}],
    cbc: [{}],
    temperature: [{}],
    lactate: [{}],
    antifungals: null,
    assignmentsReferrer: {
      id: '',
      status: '',
    },
    assignmentsReviewer: {
      id: '',
      status: '',
    },
    assignmentsEyeRecoverer: {
      id: '',
      status: '',
    },
    assignmentsHospitalDevelopment: {
      id: '',
      status: '',
    },
    assignmentsTransporter: {
      id: '',
      status: '',
    },
    donorHeightUnit: 'cm',
    donorWeightUnit: 'kg',
  },
  banner: {
    id: null,
  },
  caseStatusList: [],
  familyStatusList: [],
  responsibleFollowUpList: [],
  casePartners: null,
  outcomeList: [],
  outcomeTypeList: [],
  outcomeDetailList: [],
  nextStepsList: [],
  titleList: [],
  caseSearchOpen: false,
  duplicateCase: {
    warningOpen: false,
    duplicateCaseItem: {},
    override: false,
  },
  caseContacts: {
    kins: [{}],
  },
  contactsKins: [],
  jurisdictionAssumed: false,
  recentCasesOpen: false,
  recentCasesAnchorEl: null,
};

const caseReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CASE: {
      const { caseItem } = action.payload;

      return produce(state, (draft) => {
        draft.caseItem = caseItem;
        draft.duplicateCase = initialState.duplicateCase;
      });
    }

    case DUPLICATE_CASE_OPEN: {
      const { duplicateCase } = action.payload;

      return produce(state, (draft) => {
        draft.duplicateCase = {
          duplicateCaseItem: duplicateCase,
          warningOpen: true,
        };
      });
    }

    case DUPLICATE_CASE_CLOSE: {
      return produce(state, (draft) => {
        draft.duplicateCase.warningOpen =
          initialState.duplicateCase.warningOpen;
        draft.duplicateCase.duplicateCaseItem =
          initialState.duplicateCase.duplicateCaseItem;
      });
    }

    case DUPLICATE_CASE_OVERRIDE: {
      return produce(state, (draft) => {
        draft.duplicateCase.override = true;
        draft.duplicateCase.warningOpen = false;
      });
    }

    case CREATE_CASE_SUMMARY: {
      const { caseItem } = action.payload;

      return produce(state, (draft) => {
        draft.caseItem = caseItem;
      });
    }

    case SET_CASE_ID: {
      const caseId = action.payload;

      return produce(state, (draft) => {
        draft.caseItem.id = caseId;
      });
    }

    case GET_CASE: {
      const { caseItem } = action.payload;
      return produce(state, (draft) => {
        draft.caseItem = caseItem;
      });
    }
    case GET_CASE_BANNER_INFORMATION: {
      const record = action.payload;
      return produce(state, (draft) => {
        draft.banner = record;
      });
    }

    case GET_CASE_SUMMARY: {
      const { caseItem } = action.payload;
      return produce(state, (draft) => {
        draft.caseItem = caseItem;
      });
    }

    case CLEAR_CASE: {
      return produce(state, (draft) => {
        draft.caseItem = initialState.caseItem;
        draft.banner = initialState.banner;
      });
    }

    case UPDATE_CASE: {
      const { caseItem } = action.payload;

      return produce(state, (draft) => {
        draft.caseItem = caseItem;
      });
    }
    case OPEN_CASE_SEARCH: {
      return produce(state, (draft) => {
        draft.caseSearchOpen = true;
      });
    }

    case CLOSE_CASE_SEARCH: {
      return produce(state, (draft) => {
        draft.caseSearchOpen = false;
      });
    }

    case GET_CASE_STATUS_LIST: {
      const list = action.payload.lookups;
      return produce(state, (draft) => {
        draft.caseStatusList = list;
      });
    }

    case GET_FAMILY_STATUS_LIST: {
      const list = action.payload.lookups;
      return produce(state, (draft) => {
        draft.familyStatusList = list;
      });
    }

    case GET_OUTCOME_LIST: {
      const list = action.payload.lookups;

      return produce(state, (draft) => {
        draft.outcomeList = list;
      });
    }

    case GET_OUTCOME_TYPE_LIST: {
      const list = action.payload.lookups;

      return produce(state, (draft) => {
        draft.outcomeTypeList = list;
      });
    }

    case GET_OUTCOME_DETAIL_LIST: {
      const list = action.payload.lookups;

      return produce(state, (draft) => {
        draft.outcomeDetailList = list;
      });
    }

    case CREATE_CASE_PARTNERS: {
      const { casePartners } = action.payload;
      return produce(state, (draft) => {
        draft.casePartners = casePartners;
      });
    }

    case CREATE_CASE_OUTCOMES: {
      const { caseOutcomes } = action.payload;
      return produce(state, (draft) => {
        draft.caseOutcomes = caseOutcomes;
      });
    }

    case GET_TITLE_LIST: {
      const list = action.payload.lookups;
      return produce(state, (draft) => {
        draft.titleList = list;
      });
    }

    case CREATE_CASE_CONTACTS: {
      const { contact } = action.payload;
      return produce(state, (draft) => {
        draft.caseContacts = contact;
      });
    }

    case UPDATE_CASE_CONTACTS: {
      const { contact } = action.payload;
      return produce(state, (draft) => {
        if (contact) {
          draft.caseContacts = contact;
        }
      });
    }

    case GET_CASE_CONTACTS: {
      const { contact } = action.payload;
      return produce(state, (draft) => {
        if (contact) {
          draft.caseContacts = contact;
          if (!draft.caseContacts.kins) {
            draft.caseContacts.kins = [{}];
          } else if (!draft.caseContacts.kins.length) {
            draft.caseContacts.kins.push({});
          }
        }
      });
    }

    case CLEAR_CASE_CONTACTS: {
      return produce(state, (draft) => {
        draft.caseContacts = initialState.caseContacts;
      });
    }

    case GET_CASE_CONTACTS_KINS: {
      const { kins } = action.payload;
      return produce(state, (draft) => {
        draft.contactsKins = kins;
      });
    }

    case SET_CASE_JURISDICTION_ASSUMED: {
      const { jurisdictionAssumed } = action.payload;
      return produce(state, (draft) => {
        draft.caseItem.jurisdictionAssumed = jurisdictionAssumed;
      });
    }

    case OPEN_RECENT_CASES: {
      return produce(state, (draft) => {
        draft.recentCasesOpen = true;
      });
    }

    case CLOSE_RECENT_CASES: {
      return produce(state, (draft) => {
        draft.recentCasesOpen = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default caseReducer;
