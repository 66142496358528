/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_MODE,
  SET_INITIAL_FORM_VALUES,
} from 'src/actions/formPermissionsActions';

const initialState = {
  mode: 'view',
  initialFormValues: null,
};

const formPermissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODE: {
      return produce(state, (draft) => {
        draft.mode = action.payload;
      });
    }
    case SET_INITIAL_FORM_VALUES: {
      return produce(state, (draft) => {
        draft.initialFormValues = { ...action.payload };
      });
    }
    default: {
      return state;
    }
  }
};

export default formPermissionsReducer;
