/* eslint-disable react/no-array-index-key */
import { Suspense, lazy, Fragment } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MainLayout from 'src/layouts/MainLayout';
import FormLayout from 'src/layouts/FormLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { AUDIT_READ_USERS } from 'src/services/auditService';
import * as Sentry from '@sentry/react';
import OfflineView from './views/pages/OfflineView';
import CaseLayout from './layouts/CaseLayout/CaseLayout';
import DashBoardLayout from './layouts/DashBoardLayout/DashBoardLayout';
import NonCaseLayout from './layouts/NonCaseLayout/NonCaseLayout';
import { RecoveryLayout } from './layouts/RecoveryLayout';

const Error = lazy(() => import('src/views/pages/Error404View'));
const PreviewView = lazy(() => import('src/views/transmit/PreviewView'));
const TransmitLoginView = lazy(
  () => import('src/views/auth/TransmitLoginView'),
);

const caseRoutes = [
  {
    form: 'caseWorksheet',
    page: 'Worksheet',
    path: 'referral/worksheet',
    component: lazy(() => import('src/views/case/CaseWorksheetView')),
    permission: {
      readPermission: 'read:referralWorksheet',
      createPermission: 'create:referralWorksheet',
      updatePermission: 'update:referralWorksheet',
    },
  },
  {
    form: 'caseSummary',
    page: 'Summary',
    path: 'referral/summary',
    component: lazy(() => import('src/views/case/CaseSummaryView')),
    permission: {
      readPermission: 'read:referralSummary',
      createPermission: 'upsert:referralSummary',
      updatePermission: 'upsert:referralSummary',
    },
  },
  {
    form: 'caseContacts',
    page: 'Contacts',
    path: 'referral/contacts',
    component: lazy(() => import('src/views/case/CaseContacts')),
    permission: {
      readPermission: 'read:referralContact',
      createPermission: 'create:referralContact',
      updatePermission: 'update:referralContact',
    },
  },
  {
    form: 'donorScreening',
    page: 'Preliminary Donor Screening',
    path: 'referral/screening',
    component: lazy(() => import('src/views/case/DonorScreeningView')),
    permission: {
      readPermission: 'read:referralDonorScreening',
      createPermission: 'create:referralDonorScreening',
      updatePermission: 'update:referralDonorScreening',
    },
  },
  {
    form: 'casePreliminaryHemodilution',
    page: 'PreliminaryHemodilution',
    path: 'referral/preliminaryHemodilution',
    component: lazy(() => import('src/views/case/PreliminaryHemodilution')),
    permission: {
      readPermission: 'read:referralPreliminaryHemodilution',
      createPermission: 'upsert:referralPreliminaryHemodilution',
      updatePermission: 'upsert:referralPreliminaryHemodilution',
    },
  },
  {
    page: 'Donation Discussion',
    path: 'referral/discussion',
    component: lazy(
      () =>
        import('src/views/case/DonationDiscussionView/DonationDiscussionView'),
    ),
    permission: {
      readPermission: 'read:referralDonationDiscussion',
      createPermission: 'upsert:referralDonationDiscussion',
      updatePermission: 'upsert:referralDonationDiscussion',
    },
  },
  {
    page: 'Authorized Usage',
    path: 'referral/usage',
    component: lazy(
      () => import('src/views/case/AuthorizedUsageView/AuthorizedUsageView'),
    ),
    permission: {
      readPermission: 'read:authForm',
      createPermission: 'create:authForm',
      updatePermission: 'update:authForm',
    },
  },
  {
    form: 'medicalReviewHemodilution',
    page: 'Hemodilution',
    path: 'medicalReview/hemodilution',
    component: lazy(() => import('src/views/medicalReview/Hemodilution')),
    permission: {
      readPermission: 'read:medicalReviewHemodilution',
      createPermission: 'upsert:medicalReviewHemodilution',
      updatePermission: 'upsert:medicalReviewHemodilution',
    },
  },
  {
    path: 'notes',
    page: 'Notes',
    component: lazy(
      () => import('src/views/notes/CaseNotesList/CaseNotesTransmit'),
    ),
    permission: {
      readPermission: 'read:notes',
      createPermission: 'create:notes',
      updatePermission: 'update:notes',
    },
  },

  {
    path: 'tasks',
    page: 'Tasks',
    component: lazy(() => import('src/views/tasks/CaseTasks/CaseTaskTransmit')),
  },
  {
    path: 'medSocial',
    component: lazy(() => import('src/views/case/DRAIView')),
  },
  {
    form: 'medSocial',
    page: 'DRAI',
    path: 'medSocial/:draiId',
    component: lazy(() => import('src/views/case/DRAIView')),
    permission: {
      readPermission: 'read:medicalSocialDrai',
      updatePermission: 'update:medicalSocialDrai',
      createPermission: 'create:medicalSocialDrai',
    },
  },
  {
    form: 'medReview',
    page: 'Culture Results',
    path: 'medicalReview/cultureResults',
    component: lazy(() => import('src/views/medicalReview/CultureResults')),
    permission: {
      readPermission: 'read:medicalReviewCultureResult',
      createPermission: 'upsert:medicalReviewCultureResult',
      updatePermission: 'upsert:medicalReviewCultureResult',
    },
  },
  {
    form: 'medicalReviewPathology',
    page: 'Pathology',
    path: 'medicalReview/pathology',
    component: lazy(() => import('src/views/medicalReview/PathologyView')),
    permission: {
      readPermission: 'read:medicalReviewPathology',
      createPermission: 'create:medicalReviewPathology',
      updatePermission: 'update:medicalReviewPathology',
    },
  },
  {
    form: 'medicalReviewSerology',
    page: 'Serologies',
    path: 'medicalReview/serologies',
    component: lazy(() => import('src/views/medicalReview/Serologies')),
    permission: {
      readPermission: 'read:medicalReviewSerology',
      createPermission: 'create:medicalReviewSerology',
      updatePermission: 'update:medicalReviewSerology',
    },
  },
  {
    form: 'medicalReviewSummary',
    page: 'Medical Review',
    path: 'medicalReview/summary',
    component: lazy(() => import('src/views/medicalReview/Summary')),
    permission: {
      readPermission: 'read:medicalReviewSummary',
      createPermission: 'upsert:medicalReviewSummary',
      updatePermission: 'upsert:medicalReviewSummary',
    },
  },
  {
    page: 'Serologies',
    path: 'medicalReview/serologies/all',
    component: lazy(
      () => import('src/views/medicalReview/Serologies/SerologiesTransmit'),
    ),
    permission: {
      readPermission: 'read:medicalReviewSerology',
      createPermission: 'create:medicalReviewSerology',
      updatePermission: 'update:medicalReviewSerology',
    },
  },
  {
    form: 'medicalReviewSerology',
    page: 'Serologies',
    path: 'medicalReview/serologies/:serologyId',
    component: lazy(() => import('src/views/medicalReview/Serologies')),
    permission: {
      readPermission: 'read:medicalReviewSerology',
      createPermission: 'create:medicalReviewSerology',
      updatePermission: 'update:medicalReviewSerology',
    },
  },
  {
    page: 'Audit',
    path: 'audit',
    component: lazy(() => import('src/views/audit/AuditView')),
    permission: {
      readPermission: AUDIT_READ_USERS,
    },
  },

  {
    page: 'Pathology',
    path: 'medicalReview/pathology',
    component: lazy(() => import('src/views/medicalReview/PathologyView')),
    permission: {
      readPermission: 'read:medicalReviewPathology',
      createPermission: 'create:medicalReviewPathology',
      updatePermission: 'update:medicalReviewPathology',
    },
  },
  {
    page: 'CBC/Temp/Meds',
    form: 'cbcTempMeds',
    path: 'medicalReview/cbcTempMeds',
    component: lazy(() => import('src/views/medicalReview/CbcTempMeds')),
    permission: {
      readPermission: 'read:medicalReviewCbcTempMeds',
      createPermission: 'upsert:medicalReviewCbcTempMeds',
      updatePermission: 'upsert:medicalReviewCbcTempMeds',
    },
  },
  {
    page: 'Attachments',
    path: 'attachments',
    component: lazy(() => import('src/views/attachments/AttachmentsView')),
  },

  {
    path: 'transmit/log',
    page: 'Transmit Log',
    component: lazy(() => import('src/views/transmit/LogView')),
    permissions: {
      readPermission: 'read:transmitLog',
    },
  },
  {
    page: 'Transmit Data',
    form: 'transmitData',
    path: 'transmit/data',
    component: lazy(() => import('src/views/transmit/DataView')),
    permissions: {
      readPermission: 'read:transmit',
      createPermission: 'create:transmit',
      updatePermission: 'update:transmit',
    },
  },

  {
    path: 'approvals/approval',
    page: 'Approval',
    form: 'approval',
    component: lazy(() => import('src/views/approvals/Approval')),
    permission: {
      readPermission: 'read:approval',
      createPermission: 'create:approval',
      updatePermission: 'update:approval',
    },
  },
  {
    path: 'approvals/lock',
    page: 'Case Lock',
    form: 'approvalsCaseLock',
    component: lazy(() => import('src/views/approvals/CaseLockView')),
    permission: {
      readPermission: 'read:caseLock',
      createPermission: 'update:caseLock',
      updatePermission: 'update:caseLock',
    },
  },
  {
    path: 'approvals/disposition',
    page: 'Disposition',
    component: lazy(() => import('src/views/approvals/Disposition')),
    permission: {
      readPermission: 'read:disposition',
    },
  },
  {
    path: 'referral/authorizations',
    component: lazy(() => import('src/views/case/AuthFormView')),
  },
  {
    form: 'authForm',
    page: 'Authorizations',
    path: 'referral/authorizations/:authFormId',
    component: lazy(() => import('src/views/case/AuthFormView')),
    permission: {
      readPermission: 'read:authForm',
      createPermission: 'create:authForm',
      updatePermission: 'update:authForm',
    },
  },
];
const recoveryRoutes = [
  {
    form: 'recoveryDonorInformation',
    page: 'Donor Information',
    path: 'donorInformation',
    component: lazy(() => import('src/views/recovery/DonorInformation')),
    permission: {
      readPermission: 'read:recoveryDonorInformation',
      createPermission: 'upsert:recoveryDonorInformation',
      updatePermission: 'upsert:recoveryDonorInformation',
    },
  },
  {
    form: 'recoveryRecoverySite',
    page: 'Recovery Site',
    path: 'recoverySite',
    component: lazy(() => import('src/views/recovery/RecoverySite')),
    permission: {
      readPermission: 'read:recoverySite',
      createPermission: 'create:recoverySite',
      updatePermission: 'update:recoverySite',
    },
  },
  {
    form: 'recoveryShippingDetails',
    page: 'Shipping Details',
    path: 'shippingDetails',
    component: lazy(() => import('src/views/recovery/RecoveryShippingDetails')),
    permission: {
      readPermission: 'read:recoveryShippingDetail',
      createPermission: 'upsert:recoveryShippingDetail',
      updatePermission: 'upsert:recoveryShippingDetail',
    },
  },
  {
    form: 'recoveryPhysicalAssessment',
    page: 'Physical Assessment',
    path: 'physicalAssessment',
    upgradedESign: true,
    component: lazy(() => import('src/views/recovery/PhysicalAssessment')),
    permission: {
      readPermission: 'read:recoveryPhysicalAssessment',
      createPermission: 'upsert:recoveryPhysicalAssessment',
      updatePermission: 'upsert:recoveryPhysicalAssessment',
    },
  },
  {
    page: 'Tissues Recovered',
    path: 'tissue',
    component: lazy(() => import('src/views/recovery/TissueRecoveryView')),
    permission: {
      readPermission: 'read:recoveryTissue',
      createPermission: 'create:recoveryTissue',
      updatePermission: 'update:recoveryTissue',
    },
  },
  {
    page: 'Recovery Supplies',
    path: 'supplies',
    component: lazy(() => import('src/views/recovery/Supplies')),
    permission: {
      readPermission: 'read:recoveryTissue',
      createPermission: 'create:recoveryTissue',
      updatePermission: 'update:recoveryTissue',
    },
  },
  {
    form: 'recoveryEyeAssessment',
    page: 'Eye Assessment',
    path: 'eyeAssessment',
    component: lazy(() => import('src/views/recovery/EyeAssessment')),
    upgradedESign: true,
    permission: {
      readPermission: 'read:recoveryEyeAssessment',
      createPermission: 'upsert:recoveryEyeAssessment',
      updatePermission: 'upsert:recoveryEyeAssessment',
    },
  },
  {
    form: 'recoverySerology',
    page: 'Serologies',
    path: 'serologies',
    component: lazy(() => import('src/views/recovery/Serologies')),
    permission: {
      readPermission: 'read:recoverySerology',
      createPermission: 'create:recoverySerology',
      updatePermission: 'update:recoverySerology',
    },
  },
  {
    page: 'Serologies',
    path: 'serologies/all',
    component: lazy(
      () => import('src/views/recovery/Serologies/SerologiesTransmit'),
    ),
    permission: {
      readPermission: 'read:recoverySerology',
      createPermission: 'create:recoverySerology',
      updatePermission: 'update:recoverySerology',
    },
  },
  {
    form: 'recoverySerology',
    page: 'Serologies',
    path: 'serologies/:serologyId',
    component: lazy(() => import('src/views/recovery/Serologies')),
    permission: {
      readPermission: 'read:recoverySerology',
      createPermission: 'create:recoverySerology',
      updatePermission: 'update:recoverySerology',
    },
  },
];
const nonCaseRoutes = [
  {
    path: '/',
    page: 'CorneaConnect',
    component: lazy(() => import('src/views/Home')),
  },
  {
    form: 'caseWorksheet',
    page: 'Worksheet',
    path: '/case/worksheet',
    component: lazy(() => import('src/views/case/CaseWorksheetView')),
    permission: {
      readPermission: 'read:referralWorksheet',
      createPermission: 'create:referralWorksheet',
      updatePermission: 'update:referralWorksheet',
    },
  },

  {
    form: 'adminRole',
    page: 'Role Management',
    path: '/admin/roles/:roleId',
    component: lazy(() => import('src/views/admin/RoleView')),
    permission: {
      readPermission: 'read:role',
      createPermission: 'create:role',
      updatePermission: 'update:role',
    },
  },
  {
    form: 'apiKey',
    page: 'API Key Management',
    path: '/admin/apiKeys/create',
    component: lazy(() => import('src/views/admin/ApiKeyView')),
    permission: {
      readPermission: 'create:apiKey',
      createPermission: 'create:apiKey',
      updatePermission: 'create:apiKey',
    },
  },
  {
    form: 'apiKey',
    page: 'API Key Management',
    path: '/admin/apiKeys/:apiKeyId',
    component: lazy(() => import('src/views/admin/ApiKeyView')),
    permission: {
      readPermission: 'read:apiKey',
      createPermission: 'create:apiKey',
      updatePermission: 'update:apiKey',
    },
  },
  {
    form: 'adminUser',
    page: 'Create User',
    path: '/admin/users/create',
    component: lazy(() => import('src/views/admin/UserManagementView')),
    permission: {
      // this is only available to users with create
      readPermission: 'create:userManagement',
      createPermission: 'create:userManagement',
      updatePermission: 'create:userManagement',
    },
  },
  {
    form: 'selfSettings',
    page: 'Settings',
    path: '/settings',
    component: lazy(() => import('src/views/settings/UserSettingsView')),
    permission: {
      readPermission: 'read:selfSettings',
      createPermission: 'update:selfSettings',
      updatePermission: 'update:selfSettings',
    },
  },
  {
    form: 'adminUser',
    page: 'Modify User',
    path: '/admin/users/:userId',
    component: lazy(() => import('src/views/admin/UserManagementView')),
    permission: {
      readPermission: 'read:userManagement',
      createPermission: 'create:userManagement',
      updatePermission: 'update:userManagement',
    },
  },
  {
    page: 'User Management',
    path: '/admin/users',
    component: lazy(() => import('src/views/admin/UserListView')),
  },
  {
    page: 'API Key Management',
    path: '/admin/apiKeys',
    component: lazy(() => import('src/views/admin/ApiKeyListView')),
  },
  {
    page: 'Role Management',
    path: '/admin/roles',
    component: lazy(() => import('src/views/admin/RoleListView')),
  },
  {
    page: 'Access Logs',
    path: '/admin/accessLogs',
    component: lazy(() => import('src/views/admin/AccessLogListView')),
  },
  {
    path: '/notes',
    page: 'Notes',
    component: lazy(() => import('src/views/notes/NotesView')),
    permission: {
      readPermission: 'read:notes',
      createPermission: 'create:notes',
      updatePermission: 'update:notes',
    },
  },
  {
    path: '/notes/label/:customLabel/:noteId?',
    page: 'Notes',
    component: lazy(() => import('src/views/notes/NotesView')),
    permission: {
      readPermission: 'read:notes',
      createPermission: 'create:notes',
      updatePermission: 'update:notes',
    },
  },
  {
    path: '/notes/:systemLabel/:noteId?',
    page: 'Notes',
    component: lazy(() => import('src/views/notes/NotesView')),
    permission: {
      readPermission: 'read:notes',
      createPermission: 'create:notes',
      updatePermission: 'update:notes',
    },
  },
  {
    path: '/notes/all',
    page: 'Notes',
    component: lazy(() => import('src/views/notes/NotesView')),
    permission: {
      readPermission: 'read:notes',
      createPermission: 'create:notes',
      updatePermission: 'update:notes',
    },
  },
  {
    path: '/checklist',
    page: 'Checklist',
    component: lazy(
      () => import('src/views/checklist/ChecklistView/CaseChecklist'),
    ),
    permission: {
      readPermission: 'read:checklist',
      createPermission: 'create:checklist',
      updatePermission: 'update:checklist',
    },
  },
  {
    path: '/inbox',
    page: 'Inbox',
    component: lazy(() => import('src/views/inbox')),
  },
  {
    path: '/tasks',
    page: 'Tasks',
    component: lazy(() => import('src/views/tasks')),
  },
  // Not official
  {
    form: 'settings-notifications',
    page: 'Notification Settings',
    path: '/settings/notifications',
    component: lazy(() => import('src/views/settings/notifications')),
  },
  {
    form: 'lookupTables',
    page: 'Lookup Table Maintenance',
    path: '/admin/lookupTables',
    component: lazy(() => import('src/views/lookupTables')),
    permission: {
      readPermission: 'read:lookupManagement',
      createPermission: 'create:lookupManagement',
      updatePermission: 'update:lookupManagement',
    },
  },
  {
    form: 'referenceTables',
    page: 'Reference Table Maintenance',
    path: '/admin/referenceTables',
    component: lazy(() => import('src/views/referenceTables')),
    permission: {
      readPermission: 'read:referenceManagement',
      createPermission: 'create:referenceManagement',
      updatePermission: 'update:referenceManagement',
    },
  },
  {
    form: 'organizations',
    page: 'Organizations',
    path: '/admin/organizations',
    component: lazy(() => import('src/views/organizations/OrganizationTable')),
    permission: {
      readPermission: 'read:referenceManagement',
      createPermission: 'create:referenceManagement',
      updatePermission: 'update:referenceManagement',
    },
  },
  {
    form: 'organizations',
    page: 'Organizations',
    path: '/admin/organizations/:activeOrganizationId',
    component: lazy(() => import('src/views/organizations/Organization')),
    permission: {
      readPermission: 'read:referenceManagement',
      createPermission: 'create:referenceManagement',
      updatePermission: 'update:referenceManagement',
    },
  },
  {
    form: 'groups',
    page: 'Groups',
    path: '/admin/groups',
    component: lazy(() => import('src/views/groups')),
    permission: {
      readPermission: 'read:referenceManagement',
      createPermission: 'create:referenceManagement',
      updatePermission: 'update:referenceManagement',
    },
  },
  {
    form: 'groups',
    page: 'Groups',
    path: '/admin/groups/:activeGroupId',
    component: lazy(() => import('src/views/groups/Group')),
    permission: {
      readPermission: 'read:referenceManagement',
      createPermission: 'create:referenceManagement',
      updatePermission: 'update:referenceManagement',
    },
  },
  {
    page: 'Reports',
    path: '/reports',
    component: lazy(() => import('src/views/reports/ReportView')),
    permission: {
      readPermission: 'read:reports',
    },
  },
];

const dashboardRoutes = [
  {
    page: 'Active Referral Dashboard',
    path: '/dashboards/activeReferral',
    component: lazy(() => import('src/views/dashboards/activeReferral')),
  },
  {
    path: '/dashboards/activeCases',
    component: lazy(() => import('src/views/dashboards/activeDonor')),
    permission: {
      readPermission: 'read:dashboardActiveCases',
    },
  },
];

export const loginRoutes = [
  {
    page: 'Login',
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    page: 'Offline',
    path: '/offline',
    component: lazy(() => import('src/views/pages/OfflineView')),
  },
  {
    page: 'Forgot Password',
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgotPasswordView')),
  },
  {
    page: 'Reset Password',
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetPasswordView')),
  },
  {
    page: 'Reset Password',
    path: '/reset-password/:tokenString',
    component: lazy(() => import('src/views/auth/ResetPasswordView')),
  },
];

function RenderRoutes({ route }) {
  const Form = route.form ? FormLayout : Fragment;
  const formProps = route.form && {
    formId: route?.form,
    pageName: route?.page,
    permission: route?.permission,
    upgradedESign: route?.upgradedESign,
  };
  const Component = route.component;
  return (
    <>
      <Helmet>
        <title>{route?.page}</title>
      </Helmet>
      <Form {...formProps}>
        <Sentry.ErrorBoundary fallback={<OfflineView />}>
          <Suspense fallback={<LoadingScreen />}>
            <Component permission={route?.permission} />
          </Suspense>
        </Sentry.ErrorBoundary>
      </Form>
    </>
  );
}

function Routing() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route element={<AuthGuard />}>
          <Route element={<MainLayout />}>
            <Route element={<NonCaseLayout />}>
              {nonCaseRoutes.map((route, i) => (
                <Route
                  exact
                  key={route.path + i}
                  path={route.path}
                  element={<RenderRoutes route={route} />}
                />
              ))}
            </Route>
            <Route
              path="case/:caseId"
              element={<CaseLayout />}
            >
              {caseRoutes.map((route, i) => (
                <Route
                  exact
                  key={route.path + i}
                  path={route.path}
                  element={<RenderRoutes route={route} />}
                />
              ))}
              {recoveryRoutes.map((route, i) => (
                <Route
                  exact
                  key={route.path + i}
                  path={`recovery/${route.path}`}
                  element={
                    <>
                      <Helmet>
                        <title>{route.page}</title>
                      </Helmet>
                      <RecoveryLayout page={route.page}>
                        <RenderRoutes route={route} />
                      </RecoveryLayout>
                    </>
                  }
                />
              ))}
            </Route>
            <Route element={<DashBoardLayout />}>
              {dashboardRoutes.map((route, i) => (
                <Route
                  exact
                  key={route.path + i}
                  path={route.path}
                  element={<RenderRoutes route={route} />}
                />
              ))}
            </Route>
          </Route>
          <Route
            path="/transmit/preview/:caseId"
            element={<CaseLayout transmit />}
          >
            <Route element={<PreviewView />} />
          </Route>
        </Route>
        <Route element={<GuestGuard />}>
          {loginRoutes.map((route, i) => (
            <Route
              exact
              key={route.path + i}
              path={route.path}
              element={<RenderRoutes route={route} />}
            />
          ))}
        </Route>
        <Route
          element={
            <div style={{ flex: '1 1 auto' }}>
              <Outlet />
            </div>
          }
        >
          <Route
            exact
            path="/transmit/access"
            element={<TransmitLoginView />}
          />
          <Route
            exact
            path="/transmit/access/:tokenString"
            element={<TransmitLoginView />}
          />

          <Route
            exact
            path="/404"
            element={<Error />}
          />
          <Route
            path="*"
            element={<Error />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default Routing;
