import { Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import CaseSearchDialog from 'src/views/case/CaseSearchDialog';
import { Permission, Show } from 'src/components';
import TopBar from './TopBar';
import MobileFooter from './MobileFooter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.dark,
  },
  wrapper: {
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 0,
    },
  },
  contentContainer: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 auto',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  },
  content: {
    overflow: 'auto',
    height: '100%',
    width: '100%',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
}));
function MainLayout() {
  const caseSearchOpen = useSelector((state) => state.caseItem.caseSearchOpen);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Permission permit="read:cases">
        <Show when={caseSearchOpen}>
          <CaseSearchDialog />
        </Show>
      </Permission>
      <TopBar />
      <Outlet />

      <Hidden mdUp>
        <MobileFooter />
      </Hidden>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
};

export default MainLayout;
