import LRUCache from 'lru-cache';
import buildSortedUrl from './buildSortedUrl';
import isCacheLike from './isCacheLike';

const FIVE_MINUTES = 1000 * 60 * 5;
const CAPACITY = 100;

export default function cacheAdapterEnhancer(adapter, options = {}) {
  const {
    enabledByDefault = false,
    cacheFlag = 'cache',
    defaultCache = new LRUCache({ ttl: FIVE_MINUTES, max: CAPACITY }),
  } = options;

  return (config) => {
    const { url, method, params, paramsSerializer, forceUpdate } = config;
    const useCache =
      config[cacheFlag] !== undefined && config[cacheFlag] !== null
        ? config[cacheFlag]
        : enabledByDefault;

    if (method === 'get' && useCache) {
      // if had provide a specified cache, then use it instead
      const cache = isCacheLike(useCache) ? useCache : defaultCache;

      // build the index according to the url and params
      const index = buildSortedUrl(url, params, paramsSerializer);

      let responsePromise = cache.get(index);

      if (!responsePromise || forceUpdate) {
        responsePromise = (async () => {
          try {
            return await adapter(config);
          } catch (reason) {
            cache.del(index);
            throw reason;
          }
        })();

        // put the promise for the non-transformed response into cache as a placeholder
        cache.set(index, responsePromise);

        return responsePromise;
      }

      return responsePromise;
    }

    return adapter(config);
  };
}
