import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoteNew from 'src/views/notes/NotesView/NoteNew';
import CaseNotesList from 'src/views/notes/CaseNotesList';
import CaseChecklist from 'src/views/checklist/ChecklistView/CaseChecklist';
import CaseNoteDetails from 'src/views/notes/CaseNoteDetails';
import CaseTasks from 'src/views/tasks/CaseTasks';
import { Permission, Show } from 'src/components';
import {
  useGetAllTasksOnCaseQuery,
  useGetCaseNotesQuery,
  useGetTaskAuxillaryDataQuery,
} from 'src/store/serverState';
import {
  GET_CASE_NOTES,
  closeCaseNoteDetails,
  closeCaseNotesList,
  closeNewMessage,
  templateDialogOpen,
} from 'src/actions/notesActions';
import { closeCaseTasksList } from 'src/actions/caseTasksActions';
import { closeChecklist } from 'src/actions/checklistActions';

function CasePopUps({ caseId }) {
  const dispatch = useDispatch();
  const caseNotes = useGetCaseNotesQuery(caseId);
  const caseTasks = useGetAllTasksOnCaseQuery(caseId);
  const tasksAuxData = useGetTaskAuxillaryDataQuery();

  const caseTasksOpen = useSelector((state) => state.caseTasks.open);
  const newMessageOpen = useSelector((state) => state.notes.newMessageOpen);
  const checklistOpen = useSelector((state) => state.checklist.checklistOpen);
  const caseNotesListOpen = useSelector(
    (state) => state.notes.caseNotesListOpen,
  );
  const caseNoteDetailsOpen = useSelector(
    (state) => state.notes.caseNoteDetailsOpen,
  );

  useEffect(() => {
    if (!caseNotes.isFetching) {
      dispatch({
        type: GET_CASE_NOTES,
        payload: caseNotes.data,
      });
    }
  }, [caseNotes.isFetching]);

  useEffect(() => {
    dispatch(closeCaseTasksList());
    dispatch(closeCaseNoteDetails());
    dispatch(closeCaseNotesList());
    dispatch(closeNewMessage());
    dispatch(closeChecklist());
    dispatch(templateDialogOpen());
  }, [caseId]);

  return (
    <>
      <Show when={newMessageOpen}>
        <NoteNew caseId={caseId} />
      </Show>

      <Permission permit="read:tasks">
        <Show when={caseTasksOpen}>
          <CaseTasks
            caseId={caseId}
            caseTasks={caseTasks.data}
            tasksAuxData={tasksAuxData.data}
          />
        </Show>
      </Permission>

      <Permission permit="read:notes">
        <Show when={caseNotesListOpen && caseNotes.data}>
          <CaseNotesList caseNotes={caseNotes.data} />
        </Show>
        <Show when={caseNoteDetailsOpen}>
          <CaseNoteDetails />
        </Show>
      </Permission>

      <Permission permit="read:caseChecklist">
        <Show when={checklistOpen}>
          <CaseChecklist caseId={caseId} />
        </Show>
      </Permission>
    </>
  );
}

export default CasePopUps;
