import { object, mixed } from 'yup';

const validation = object().shape({
  isVerified: mixed().oneOf(
    [true, 1],
    'You have to verify your case before submitt the form',
  ),
});

export default validation;
