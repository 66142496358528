import { combineReducers } from 'redux';
import { LOGOUT } from 'src/actions/accountActions';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import notesReducer from './notesReducer';
import caseReducer from './caseReducer';
import kanbanReducer from './kanbanReducer';
import organizationReducer from './organizationReducer';
import donorReducer from './donorReducer';
import formPermissionsReducer from './formPermissionsReducer';
import draiReducer from './draiReducer';
import authFormReducer from './authFormReducer';
import recoveryReducer from './recoveryReducer';
import messageNotificationReducer from './messageNotificationReducer';
import medicalReviewReducer from './medicalReviewReducer';
import attachmentsReducer from './attachmentsReducer';
import reportReducer from './reportReducer';
import caseTasksReducer from './caseTasksReducer';
import transmitReducer from './transmitReducer';
import checklistReducer from './checklistReducer';
import configReducer from './configReducer';
import { serverState } from '../store/serverState';

const appReducer = combineReducers({
  account: accountReducer,
  config: configReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  notes: notesReducer,
  kanban: kanbanReducer,
  caseItem: caseReducer,
  donor: donorReducer,
  orgPartners: organizationReducer,
  formPermissions: formPermissionsReducer,
  drai: draiReducer,
  recovery: recoveryReducer,
  messageNotification: messageNotificationReducer,
  medicalReview: medicalReviewReducer,
  attachments: attachmentsReducer,
  report: reportReducer,
  caseTasks: caseTasksReducer,
  transmit: transmitReducer,
  checklist: checklistReducer,
  authForm: authFormReducer,
  [serverState.reducerPath]: serverState.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
