import {
  colors,
  createTheme as createMuiTheme,
  responsiveFontSizes,
  Shadows,
  PaletteMode,
} from '@mui/material';
import typography from './typography';
import { softShadows } from './shadows';

const isTransmit = window.location.pathname.startsWith('/transmit/preview/');

let theme = createMuiTheme({
  typography,
  name: 'LIGHT',
  shadows: softShadows as Shadows,
  palette: {
    mode: 'light' as PaletteMode,
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white,
    },
    primary: {
      contrastText: colors.common.white,
      main: import.meta.env.REACT_APP_MAIN_COLOR || '#3E748D',
    },
    secondary: {
      contrastText: colors.common.white,
      main: import.meta.env.REACT_APP_SECONDARY_COLOR || '#1FB3E5',
    },
    alternative: {
      main: import.meta.env.REACT_APP_ALTERNATIVE_COLOR || '#af1685',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    unavailable: {
      contrastText: colors.common.white,
      main: '#b0bec5',
    },
  },
  breakpoints: {
    values: {
      xs: isTransmit ? 0 : 0,
      sm: isTransmit ? 0 : 600,
      md: isTransmit ? 0 : 960,
      lg: isTransmit ? 0 : 1280,
      xl: isTransmit ? 0 : 1440,
    },
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        // Needed to prevent adding a global style for every input field
        disableInjectingGlobalStyles: true,
      },
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (max-width:959.95px)': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
              margin: 80,
            },
          ' &.Mui-disabled': {
            '&:before': {
              borderBottomStyle: 'none',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          letterSpacing: 'normal',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.075)',
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '5px 10px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: 40,
          minHeight: 40,
          backgroundColor: '#d8e3e8',
          '&$expanded': {
            borderBottom: '1px solid rgba(89, 100, 105, .3)',
            height: 40,
            minHeight: 40,
            backgroundColor: '#BFCACF',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '12px 25px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderBottom: 0,
          boxShadow: 'none',
          '&:not(:last-child)': {
            borderBottom: '1px solid rgba(89, 100, 105, .3)',
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 'auto',
            border: '1px solid rgba(89, 100, 105, .3)',
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          breakInside: 'avoid',
          flexGrow: 1,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '13px 16px',
          backgroundColor: '#e4e1e6',
        },
        action: {
          marginTop: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export const createTheme = theme;
