const isEmpty = (data) => {
  if (typeof data === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
      return true;
    }
    if (!data) {
      return true;
    }
    return false;
  }
  if (typeof data === 'string') {
    if (!data.trim()) {
      return true;
    }
    return false;
  }
  if (typeof data === 'undefined') {
    return true;
  }
  return false;
};

export default isEmpty;
