import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMatch } from 'react-router-dom';
import { Button, ListItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
  },
  buttonRoot: {
    borderRadius: 0,
  },
  label: {
    width: 'initial',
  },
  listPadding: {
    width: 'initial',
  },
  buttonLeaf: {
    color: theme.palette.secondary.main,
    padding: '10px 8px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    paddingRight: 10,
    paddingLeft: 10,
    marginRight: 'auto',
    width: 'initial',
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  info: Info,
  line: lineProp,
  disabledProp,
  clickHandler,
  ...rest
}) {
  const classes = useStyles();
  const matchPath = useMatch(href);

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 5 + 8 * depth;
  }

  const style = { paddingLeft };

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      disabled={disabledProp}
      {...rest}
    >
      <Button
        disabled={disabledProp}
        onClick={clickHandler}
        className={clsx(
          `depth-${depth}`,
          {
            [classes.active]: matchPath,
          },
          classes.buttonLeaf,
        )}
        classes={{
          label: classes.label,
          root: classes.buttonRoot,
        }}
        style={style}
      >
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  line: PropTypes.bool,
  title: PropTypes.string.isRequired,
  disabledProp: PropTypes.bool,
  clickHandler: PropTypes.func,
};

export default NavItem;
