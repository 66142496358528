const REFERRAL_PAGES = {
    REFERRAL_SUMMARY: 1,
    REFERRAL_WORKSHEET: 2,
    REFERRAL_DONOR_SCREENING: 3,
    REFERRAL_PRELIMINARY_HEMODILUTION: 4,
    REFERRAL_CONTACTS: 5,
    REFERRAL_DONATION_DISCUSSION: 6,
    REFERRAL_AUTH_USAGE: 7,
};
const RECOVERY_PAGES = {
    RECOVERY_SUPPLIES: 8,
    RECOVERY_DONOR_INFORMATION: 9,
    RECOVERY_RECOVERY_SITE: 10,
    RECOVERY_PHYSICAL_ASSESSMENT: 11,
    RECOVERY_EYE_ASSESSMENT: 12,
    RECOVERY_TISSUE_RECOVERY: 13,
    RECOVERY_SHIPPING_DETAILS: 14,
    RECOVERY_SEROLOGIES: 15,
};
const MEDICAL_REVIEW_PAGES = {
    MEDICAL_REVIEW_SUMMARY: 16,
    MEDICAL_REVIEW_PATHOLOGY: 17,
    MEDICAL_REVIEW_CBC_TEMP_MEDS: 18,
    MEDICAL_REVIEW_CULTURE_RESULTS: 19,
    MEDICAL_REVIEW_HEMODILUTION: 20,
    MEDICAL_REVIEW_SEROLOGIES: 21,
};
export const TRANSMIT_PAGES = {
    ...REFERRAL_PAGES,
    ...RECOVERY_PAGES,
    ...MEDICAL_REVIEW_PAGES,
    NOTES: 22,
    TASKS: 23,
    TRANSMIT_LOG: 24,
    APPROVALS_CASE_LOCK: 25,
};
export const TRANSMIT_PAGE_COUNT = Object.keys(TRANSMIT_PAGES).length + 1;
export const REFERRAL_PACKETS = Object.values(REFERRAL_PAGES).map((p) => p - 1);
export const RECOVERY_PACKETS = Object.values(RECOVERY_PAGES).map((p) => p - 1);
export const MEDICAL_PACKETS = Object.values(MEDICAL_REVIEW_PAGES).map((p) => p - 1);
