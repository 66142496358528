import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem, Divider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
  },
  buttonRoot: {
    borderRadius: 0,
  },
  label: {
    width: 'initial',
  },
  listPadding: {
    width: 'initial',
  },
  buttonLeaf: {
    color: theme.palette.primary.contrastText,
    padding: '10px 8px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    paddingRight: 10,
    paddingLeft: 10,
    marginRight: 'auto',
    width: 'initial',
  },
  active: {
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
      borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  line: lineProp,
  disabledProp,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 5 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        disabled={disabledProp}
        {...rest}
      >
        {lineProp ? <Divider /> : null}
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
          disabled={disabledProp}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
          <span className={classes.title}>{title}</span>
          {open ? (
            <ExpandLessIcon
              size="small"
              color="inherit"
            />
          ) : (
            <ExpandMoreIcon
              size="small"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
      disabled={disabledProp}
    >
      <Button
        activeClassName={classes.active}
        className={classes.buttonLeaf}
        classes={{
          label: classes.label,
          root: classes.buttonRoot,
        }}
        disabled={disabledProp}
        component={RouterLink}
        style={style}
        to={href}
      >
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
}
NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  line: PropTypes.bool,
  disabledProp: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
