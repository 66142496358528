import axios from 'src/utils/axios';

export const UPSERT_CULTURE_RESULT = '@medical-review/upsert-culture-result';
export const REMOVE_CULTURE_RESULT = '@medical-review/remove-culture-result';
export const GET_CULTURE_RESULTS = '@medical-review/get-culture-results';
export const CLEAR_CULTURE_RESULTS = '@medical-review/clear-culture-results';

export const SET_ERROR = '@recovery/set-error';
export const SET_CASE_ID = '@case/set-case-id';

export const upsertCultureResult = (caseId, values) => {
  const request = axios.post(`/public/medicalReview/cultureResult/${caseId}`, {
    ...values,
  });

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: UPSERT_CULTURE_RESULT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};

export const getCultureResults = (caseId) => {
  const request = axios.get(`/public/medicalReview/cultureResult/${caseId}`);

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: GET_CULTURE_RESULTS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CLEAR_CULTURE_RESULTS,
        });
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};

export const removePhysicalAssessment = (caseId) => {
  const request = axios.delete(`/public/recovery/physicalAssessment/${caseId}`);

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: REMOVE_CULTURE_RESULT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ERROR,
          payload: error,
        });
      });
  };
};
