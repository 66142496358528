import { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import { capitalCase } from 'change-case';
import {
  Badge,
  Box, // Button,
  // FormControlLabel,
  IconButton,
  Popover,
  SvgIcon, // Switch,
  // TextField,
  Tooltip,
  Typography,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Settings as SettingsIcon } from 'react-feather';
import { Permission } from 'src/components';
// import useSettings from 'src/hooks/useSettings';
// import { THEMES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
}));

function Settings() {
  const classes = useStyles();
  const ref = useRef(null);
  // const {
  //   settings,
  //   saveSettings
  // } = useSettings();
  const [isOpen, setOpen] = useState(false);
  // const [values, setValues] = useState({
  //   direction: settings.direction,
  //   responsiveFontSizes: settings.responsiveFontSizes,
  //   theme: settings.theme,
  // });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <Badge
          overlap="circular"
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
          invisible
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
            size="large"
          >
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          Admin Configuration
        </Typography>
        <Permission permit="read:lookupManagement">
          <Box mt={2}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/admin/lookupTables"
              underline="none"
              variant="h5"
              onClick={() => handleClose()}
            >
              Lookup Tables
            </Link>
          </Box>
        </Permission>
        <Permission permit="read:userManagementList">
          <Box mt={2}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/admin/users"
              underline="none"
              variant="h5"
              onClick={() => handleClose()}
            >
              User Management
            </Link>
          </Box>
        </Permission>
        <Permission permit="read:roleManagementList">
          <Box mt={2}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/admin/roles"
              underline="none"
              variant="h5"
              onClick={() => handleClose()}
            >
              Role Management
            </Link>
          </Box>
        </Permission>
        <Permission permit="read:apiKeyManagementList">
          <Box mt={2}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/admin/apiKeys"
              underline="none"
              variant="h5"
              onClick={() => handleClose()}
            >
              API Key Management
            </Link>
          </Box>
        </Permission>
        <Permission permit="read:accessLogs">
          <Box mt={2}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/admin/accessLogs"
              underline="none"
              variant="h5"
              onClick={() => handleClose()}
            >
              Access Logs
            </Link>
          </Box>
        </Permission>
        <Permission permit="read:referenceManagement">
          <Box mt={2}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/admin/referenceTables"
              underline="none"
              variant="h5"
              onClick={() => handleClose()}
            >
              Reference Tables
            </Link>
          </Box>
        </Permission>
        {/* Organizations fall under the same permissions as reference management */}
        <Permission permit="read:referenceManagement">
          <Box mt={2}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/admin/organizations"
              underline="none"
              variant="h5"
              onClick={() => handleClose()}
            >
              Organizations
            </Link>
          </Box>
        </Permission>
        {/* Groups fall under the same permissions as reference management */}
        <Permission permit="read:referenceManagement">
          <Box mt={2}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/admin/groups"
              underline="none"
              variant="h5"
              onClick={() => handleClose()}
            >
              Groups
            </Link>
          </Box>
        </Permission>
      </Popover>
    </>
  );
}

export default Settings;
