/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as ACTIONS from '../actions/medicalReviewActions';

const initialState = {
  error: null,
  cultureResults: null,
};

const medicalReviewReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTIONS.GET_CULTURE_RESULTS:
        draft.cultureResults = action.payload.item;
        break;
      case ACTIONS.UPSERT_CULTURE_RESULT:
        draft.cultureResults = action.payload.item;
        break;
      case ACTIONS.REMOVE_CULTURE_RESULT:
        draft.cultureResults = null;
        break;
      case ACTIONS.CLEAR_CULTURE_RESULTS:
        draft.cultureResults = null;
        break;
      case ACTIONS.SET_ERROR:
        draft.error = action.payload;
        break;
      default:
        break;
    }
  });

export default medicalReviewReducer;
