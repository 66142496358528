import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

function GuestGuard() {
  const account = useSelector((state) => state.account);

  if (account.user) {
    return <Navigate to="/" />;
  }

  return (
    <div style={{ flex: '1 1 auto' }}>
      <Outlet />
    </div>
  );
}

export default GuestGuard;
