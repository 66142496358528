/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_PARTNERS_FULL_LIST } from 'src/actions/organizationActions';

const initialState = {
  referringOrganizationId: 0,
  partnersFullList: [],
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNERS_FULL_LIST: {
      const { partners } = action.payload;
      return produce(state, (draft) => {
        draft.partnersFullList = partners;
      });
    }

    default: {
      return state;
    }
  }
};

export default organizationReducer;
