import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  SvgIcon,
  Tooltip,
  AppBar,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  XCircle as XCircleIcon,
  Trash as TrashIcon,
} from 'react-feather';

import { setMode } from 'src/actions/formPermissionsActions';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  container: {
    boxShadow: theme.shadows[1],
    background: theme.palette.common.white,
    justifyContent: 'flex-end',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    padding: '10px',
    width: 'fit-content',
    display: 'flex',
  },
  edit: {
    color: '#af1685',
    '&:hover': {
      color: '#e845bd', // 20% lighter on hover
      backgroundColor: '#f9fafb', // 2% lighter on hover
    },
  },
  delete: {
    color: '#b30000',
    '&:hover': {
      color: '#ff0000', // 20% lighter on hover
      backgroundColor: '#f9fafb', // 2% lighter on hover
    },
  },
  save: {
    color: '#28B3E5',
    '&:hover': {
      color: '#84d3f0', // 20% lighter on hover
      backgroundColor: '#f9fafb', // 2% lighter on hover
    },
  },
  viewNotes: {
    color: '#3E748D',
    '&:hover': {
      color: '#71a8c1', // 20% lighter on hover
      backgroundColor: '#f9fafb', // 2% lighter on hover
    },
  },
  iconButton: {
    padding: 0,
  },
}));

function Modes({ resetForm, handleSubmit, onlySave, canDelete, handleDelete }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const { mode } = useSelector((state) => state.formPermissions);

  const isEditMode = mode === 'edit';
  const isViewMode = mode === 'view';

  const structureDelete = () => {
    setOpenDelete(false);
    dispatch(setMode('view'));
    handleDelete();
  };

  const confirmDelete = (
    <Dialog
      open={openDelete}
      onClose={() => setOpenDelete(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to delete?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once an item is deleted, it can not be returned.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenDelete(false)}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={() => structureDelete()}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <AppBar
      elevation={0}
      color="transparent"
      position="sticky"
    >
      <Toolbar
        style={{ justifyContent: 'flex-end', minHeight: 'initial' }}
        disableGutters
        variant="dense"
      >
        <Box className={classes.container}>
          {onlySave ? (
            <Box>
              <Tooltip
                title="Save"
                aria-label="save"
              >
                <IconButton
                  onClick={() => handleSubmit()}
                  className={classes.iconButton}
                  size="large"
                >
                  <SvgIcon
                    fontSize="small"
                    className={classes.save}
                  >
                    <SaveIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <>
              {isViewMode && (
                <Box ml={0.5}>
                  <Tooltip
                    title="Edit"
                    aria-label="edit"
                  >
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => dispatch(setMode('edit'))}
                      size="large"
                    >
                      <SvgIcon
                        fontSize="small"
                        className={classes.edit}
                      >
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {isEditMode && (
                <>
                  <Box mr={mobileView ? 5 : 1}>
                    <Tooltip
                      title="Save"
                      aria-label="save"
                    >
                      <IconButton
                        onClick={() => handleSubmit()}
                        className={classes.iconButton}
                        size="large"
                      >
                        <SvgIcon
                          fontSize="small"
                          className={classes.save}
                        >
                          <SaveIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {canDelete && (
                    <Box
                      Box
                      mr={1}
                    >
                      <Tooltip
                        title="Delete"
                        aria-label="delete"
                      >
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => {
                            setOpenDelete(true);
                          }}
                          size="large"
                        >
                          <SvgIcon
                            fontSize="small"
                            className={classes.delete}
                          >
                            <TrashIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <Box>
                    <Tooltip
                      title="Cancel"
                      aria-label="cancel"
                    >
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => {
                          resetForm();
                          dispatch(setMode('view'));
                        }}
                        size="large"
                      >
                        <SvgIcon
                          fontSize="small"
                          className={classes.edit}
                        >
                          <XCircleIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Toolbar>
      {canDelete && confirmDelete}
    </AppBar>
  );
}

Modes.propTypes = {
  resetForm: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSubmit: PropTypes.func,
  onlySave: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default Modes;
