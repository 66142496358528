import {
  Grid,
  Box,
  TableCell,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { memo } from 'react';

import ReadOnlyRow from './ReadOnlyRow';

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  boxRoot: {
    overflowX: 'auto',
    width: '100%',
  },
  tableHeader: {
    textAlign: 'center',
    padding: 0,
    paddingRight: 10,
    paddingLeft: 10,
    whiteSpace: 'noWrap',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRight: `1px solid white`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
}));

const ReadOnlyTable = memo(({ tableInformation, data, onClick, clickable }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Box className={classes.boxRoot}>
        <Table className={classes.tableRoot}>
          <TableHead>
            <TableRow>
              {tableInformation.map(({ name, width }) => (
                <TableCell
                  key={name}
                  style={{
                    maxWidth: width,
                    width,
                    cursor: 'default',
                  }}
                  className={classes.tableHeader}
                >
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((item, index) => {
                return (
                  <ReadOnlyRow
                    key={item.id}
                    index={index}
                    rowDetails={item}
                    tableColumns={tableInformation}
                    onClick={onClick}
                    clickable={clickable}
                  />
                );
              })
            ) : (
              <Box>
                <Typography>No data found...</Typography>
              </Box>
            )}
          </TableBody>
        </Table>
      </Box>
    </Grid>
  );
});

ReadOnlyTable.defaultProps = {
  onClick: () => {},
};

export default ReadOnlyTable;
