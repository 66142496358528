import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

class PermissionService {
  getAll = async () => {
    try {
      const request = await axios.get(`/public/permissions/all`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const permissionService = new PermissionService();

export default permissionService;
