import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useQuery from 'src/hooks/useQuery';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
}));

function OfflineView({ errorInformation, noHome }) {
  const classes = useStyles();
  const query = useQuery();
  const { user } = useSelector((state) => state.account);
  const statusCode = errorInformation?.status || query.get('status');
  const extraDetails = statusCode
    ? errorInformation || { status: statusCode }
    : undefined;

  // if a status code has 4 digits, it is a custom error code
  // that we are generating

  // 1001 - an error from form layout happened
  // 1002 - a crash occurred
  // 1003 - all other errors

  // http status codes
  const statusInformation = {
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
  };
  let pageTitle = '';

  if (statusCode && statusInformation[statusCode]) {
    pageTitle = `${statusCode}: ${statusInformation[statusCode]}`;
  } else if (statusCode) {
    pageTitle = `${statusCode}: Error Occurred`;
  } else {
    pageTitle = '1003: Error Occurred';
  }

  return (
    <Container className={classes.root}>
      <Container>
        <Typography
          align="center"
          variant="h2"
          color="textPrimary"
        >
          {pageTitle}
        </Typography>
        <Typography
          align="center"
          variant="h5"
          color="textSecondary"
        >
          Please try again later.
        </Typography>

        <Box
          mt={6}
          display="flex"
          gap="12px"
          justifyContent="center"
        >
          {!noHome && (
            <Button
              color="secondary"
              component={RouterLink}
              to="/"
              variant="outlined"
            >
              Back to home
            </Button>
          )}
          <Button
            color="secondary"
            onClick={() => {
              const newError = Sentry.captureException(
                new Error(`${pageTitle}`),
                extraDetails
                  ? {
                      tags: extraDetails,
                    }
                  : undefined,
              );
              Sentry.showReportDialog({
                eventId: newError,
                subtitle:
                  'Tell us what happened below without including any Protected Health Information.',
                subtitle2: '',
                labelSubmit: 'Submit Report',
                user: user?.firstName
                  ? {
                      name: `${user.firstName} ${user.lastName}`,
                      email: user.email,
                    }
                  : undefined,
              });
            }}
            variant="outlined"
          >
            Give feedback
          </Button>
        </Box>
        {errorInformation?.linked ? (
          <Typography
            align="center"
            variant="subtitle2"
            style={{ fontSize: '10px', margin: '10px' }}
            color="textSecondary"
          >
            Ref: {errorInformation.linked}
          </Typography>
        ) : null}
      </Container>
    </Container>
  );
}

export default OfflineView;
