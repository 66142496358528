/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  NOTIFICATION_TYPES_LOADED,
  NOTIFICATION_SET,
  NOTIFICATIONS_REMOVED,
} from 'src/actions/messageNotificationActions';

import { isIdentical } from 'src/utils/object';

// Don't trigger a modification if the arrays are equal.
// Useful to prevent unnecessary requests.
const returnOriginalArrayIfEqual = (originalArray, newArray) => {
  return isIdentical(originalArray, newArray) ? originalArray : newArray;
};

const initialState = {
  notifications: [],
  notificationTypes: {},
};

const messageNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_TYPES_LOADED: {
      return produce(state, (draft) => {
        draft.notificationTypes = action.payload;
      });
    }
    case NOTIFICATION_SET: {
      return produce(state, (draft) => {
        draft.notifications = returnOriginalArrayIfEqual(
          state.notifications,
          action.payload,
        );
      });
    }
    case NOTIFICATIONS_REMOVED: {
      return produce(state, (draft) => {
        draft.notifications = returnOriginalArrayIfEqual(
          state.notifications,
          state.notifications.filter(
            (notification) =>
              !action.payload.every(
                (payloadNotification) =>
                  payloadNotification.id === notification.id &&
                  payloadNotification.type === notification.type,
              ),
          ),
        );
      });
    }
    default: {
      return state;
    }
  }
};

export default messageNotificationReducer;
