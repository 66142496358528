import { useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Box, // Checkbox,
  Hidden,
  Typography,
  colors,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { openCaseNoteDetails } from 'src/actions/notesActions';

function getTo(params, noteId, isCaseNoteInstance) {
  const { systemLabel, customLabel } = params;
  const baseUrl = isCaseNoteInstance ? '/notes/all' : '/notes';

  if (systemLabel) {
    return `${baseUrl}/${systemLabel}/${noteId}`;
  }

  if (customLabel) {
    return `${baseUrl}/label/${customLabel}/${noteId}`;
  }

  return baseUrl;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  unread: {
    position: 'relative',
    '&:before': {
      content: '" "',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      width: 4,
      backgroundColor: theme.palette.error.main,
    },
    '& $name, & $subject': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
  filterActive: {
    color: colors.amber[400],
  },
  content: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  details: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
  },
  name: {
    [theme.breakpoints.up('md')]: {
      minWidth: 180,
      flexBasis: 180,
    },
  },
  subject: {
    maxWidth: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  message: {
    maxWidth: 400,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 'auto',
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.white,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 2,
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  date: {
    whiteSpace: 'nowrap',
  },
}));

function NoteItem({
  className,
  note,
  onDeselect,
  onSelect,
  selected,
  isCaseNoteInstance,
  ...rest
}) {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();

  // please leave this here, to be added in when we add multi-select
  // const handleCheckboxChange = (event) => (event.target.checked ? onSelect() : onDeselect());

  const to = getTo(params, note.id, isCaseNoteInstance);

  const extraProps = isCaseNoteInstance
    ? {
        onClick: () => dispatch(openCaseNoteDetails(note.id)),
      }
    : { to, component: RouterLink };

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.unread]: !note.isUnread,
          [classes.selected]: selected,
        },
        className,
      )}
      {...rest}
    >
      {/* <Hidden smDown>   // please leave this here, to be added in when we add multi-select
        <Box
          mr={1}
          display="flex"
          alignItems="center"
        >
          <Checkbox
            checked={selected}
            onChange={handleCheckboxChange}
          />
        </Box>
      </Hidden> */}
      <Box
        minWidth="1px"
        display="flex"
        flexGrow={1}
        className={classes.content}
        {...extraProps}
      >
        <Box
          minWidth="1px"
          ml={1}
          className={classes.details}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.subject}
          >
            {note.noteType || 'Misc Note'}
          </Typography>
          <Hidden mdDown>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.message}
            >
              <Box
                component="span"
                ml={2}
              />
              {note.noteText ? note.noteText.replace(/(<([^>]+)>)/gi, '') : ''}
            </Typography>
          </Hidden>
          <Typography
            className={classes.date}
            color="textSecondary"
            variant="caption"
          >
            {moment(note.createdDate).format('MM/DD/YYYY HH:mm')}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

NoteItem.propTypes = {
  className: PropTypes.string,
  note: PropTypes.object.isRequired,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  isCaseNoteInstance: PropTypes.bool,
};

export default NoteItem;
