/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_LABELS,
  GET_CHECKLIST,
  OPEN_CHECKLIST,
  CLOSE_CHECKLIST,
  SET_CHECKLIST,
  RELOAD_CHECKLIST,
} from 'src/actions/checklistActions';

const initialState = {
  currentValues: [],
  checklistOpen: false,
  newChecklist: true,
  modified: false,
};

const checklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LABELS: {
      const labels = action.payload;
      return produce(state, (draft) => {
        draft.currentValues = labels.map((val) => ({
          id: val.id,
          name: val.name,
          value: null,
          active: val.active,
        }));
      });
    }

    case GET_CHECKLIST: {
      const checklist = action.payload;
      return produce(state, (draft) => {
        if (checklist && checklist.length) {
          draft.modified = false;
          draft.newChecklist = false;
          checklist.forEach((val) => {
            const newItem =
              draft.currentValues[
                state.currentValues.findIndex(
                  (stateVal) => stateVal.id === val.checklistItemId,
                )
              ];
            newItem.value = val.value;
            newItem.caseChecklistToUserUpdatedBy =
              val.caseChecklistToUserUpdatedBy;
            newItem.caseChecklistToUserCreatedBy =
              val.caseChecklistToUserCreatedBy;
            newItem.updatedDate = val.updatedDate;
          });
        }
      });
    }

    case OPEN_CHECKLIST: {
      return produce(state, (draft) => {
        draft.checklistOpen = true;
      });
    }

    case CLOSE_CHECKLIST: {
      return produce(state, (draft) => {
        draft.checklistOpen = false;
      });
    }

    case SET_CHECKLIST: {
      const checkItem = action.payload;

      return produce(state, (draft) => {
        if (checkItem) {
          draft.modified = true;
          const newItem =
            draft.currentValues[
              state.currentValues.findIndex((val) => val.id === checkItem.id)
            ];

          newItem.value = checkItem.value;
          newItem.caseChecklistToUserUpdatedBy = {
            firstName: '',
            lastName: '',
          };
          newItem.caseChecklistToUserCreatedBy = {
            firstName: '',
            lastName: '',
          };
          newItem.updatedDate = new Date();
        }
      });
    }

    case RELOAD_CHECKLIST: {
      // disabling for now because of field values flashing on load
      // please leave code for possible future fix
      const checklist = action.payload;
      return produce(state, (draft) => {
        if (checklist && checklist.length) {
          checklist.forEach((val) => {
            const newItem =
              draft.currentValues[
                state.currentValues.findIndex(
                  (stateVal) => stateVal.id === val.checklistItemId,
                )
              ];
            newItem.value = val.value;
            newItem.caseChecklistToUserUpdatedBy =
              val.caseChecklistToUserUpdatedBy;
            newItem.updatedDate = val.updatedDate;
          });
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default checklistReducer;
