import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public/attachments';

class AttachmentsService {
  getAttachments = async (caseId, showDeleted) => {
    try {
      const request = await axios.get(
        `${baseURL}/${caseId}${showDeleted ? '?deleted=true' : ''}`,
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getAttachment = async (caseId, attachmentId) => {
    try {
      const request = await axios.get(
        `${baseURL}/${caseId}/single/${attachmentId}`,
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  preview = async (caseId, attachmentId) => {
    try {
      const request = await axios.get(
        `${baseURL}/${caseId}/preview/${attachmentId}`,
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  upload = (caseId, cancelToken, file, onUploadProgress) => {
    const formData = new FormData();

    formData.append('upload', file.upload);
    formData.append('title', file.title);
    formData.append('type', file.type);

    return axios.post(`/public/attachments/${caseId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      cancelToken,
    });
  };

  softDelete = async (caseId, attachmentId) => {
    try {
      const request = await axios.delete(
        `${baseURL}/${caseId}/${attachmentId}`,
      );
      if (request.status === 200) {
        return {
          success: true,
        };
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  update = async (caseId, attachmentId, payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/${caseId}/${attachmentId}`,
        payload,
      );
      if (request.status === 200) {
        return {
          status: true,
        };
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  recover = async (caseId, attachmentId) => {
    try {
      const request = await axios.put(
        `${baseURL}/${caseId}/recover/${attachmentId}`,
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}

export default new AttachmentsService();
