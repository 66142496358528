import * as Yup from 'yup';
import { REQUIRED_FIELD } from 'src/constants';

const validation = Yup.object().shape({
  callerFirstName: Yup.string().nullable().required(REQUIRED_FIELD),
  callerLastName: Yup.string().nullable().required(REQUIRED_FIELD),
  callerPhone: Yup.string()
    .nullable()
    .required(REQUIRED_FIELD)
    .length(10, 'Phone number must be 10 digits'),
  statlinePhone: Yup.string()
    .nullable()
    .length(10, 'Phone number must be 10 digits'),
  dateNotified: Yup.date().nullable().required(REQUIRED_FIELD),
  referringOrganizationId: Yup.number().nullable().required(REQUIRED_FIELD),
  donorFirstName: Yup.string().nullable().required(REQUIRED_FIELD),
  donorLastName: Yup.string().nullable().required(REQUIRED_FIELD),
  donorBirthDate: Yup.date().nullable().required(REQUIRED_FIELD),
  nameEye: Yup.string()
    .nullable()
    .when('recoveringEye', {
      is: true,
      then: Yup.string().nullable().required(REQUIRED_FIELD),
    }),
  nameTissue: Yup.string()
    .nullable()
    .when('recoveringTissue', {
      is: true,
      then: Yup.string().nullable().required(REQUIRED_FIELD),
    }),
  nameOrgan: Yup.string()
    .nullable()
    .when('recoveringOrgan', {
      is: true,
      then: Yup.string().nullable().required(REQUIRED_FIELD),
    }),
});

export default validation;
