import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public/apiKey';

class ApiKeyService {
  getApiKey = async (apiKeyId) => {
    try {
      const request = await axios.get(
        `${baseURL}${apiKeyId != null ? `/${apiKeyId}` : ''}`,
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getAll = async () => {
    try {
      const request = await axios.get(`/public/apiKeys`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getBlank = async () => {
    try {
      const request = await axios.get(`/public/apiKey`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createApiKey = async (payload) => {
    try {
      const request = await axios.post(`${baseURL}`, payload);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateApiKey = async (apiKeyId, payload) => {
    try {
      const request = await axios.put(`${baseURL}/${apiKeyId}`, payload);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  deleteApiKey = async (apiKeyId) => {
    try {
      const request = await axios.delete(`${baseURL}/${apiKeyId}`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const apiKeyService = new ApiKeyService();

export default apiKeyService;
