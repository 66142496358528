import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';
import { clone, isIdentical } from 'src/utils/object';

const baseURL = '/public/approvals';

const updateOrPostApproval = async (caseId, call, payload) => {
  try {
    const ocularTissues = payload.ocularTissues.filter(
      (ocularTissue, index) => {
        return !isIdentical(
          ocularTissue,
          payload.initialState.ocularTissues[index],
        );
      },
    );
    if (ocularTissues.length === 0) {
      return payload;
    }
    const request = await axios[call](`${baseURL}/${caseId}/approval`, {
      // payload
      ocularTissues,
    });
    if (request.status === 200) {
      return request.data.record;
    }
    throw new Error(request.data.message);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

class ApprovalsService {
  getApproval = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/approval`);
      if (request.status === 200) {
        return {
          ...request.data,
          initialState: clone(request.data),
        };
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateApproval = async (caseId, payload) =>
    updateOrPostApproval(caseId, 'put', payload);

  createApproval = async (caseId, payload) =>
    updateOrPostApproval(caseId, 'post', payload);
}

export default new ApprovalsService();
