// BaseUrl used by the axios wrapper as well as websockets
let possibleUrl = '';

switch (import.meta.env.REACT_APP_ENVIRONMENT) {
  case 'dev':
    possibleUrl = 'https://api-dev.corneaconnect.com/api';
    break;
  case 'uat':
    possibleUrl = 'https://api-uat.corneaconnect.com/api';
    break;
  case 'tst':
    possibleUrl = 'https://api-tst.corneaconnect.com/api';
    break;
  case 'prd':
    possibleUrl = 'https://api.corneaconnect.com/api';
    break;
  case 'testing':
    possibleUrl = `${window.location.origin}/api`;
    break;
  default:
    const localUrl = new URL(window.location.origin);
    localUrl.port = '2017';
    possibleUrl = `${localUrl.origin}/api`;
    break;
}

const url = possibleUrl;
export default url;
