import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Hidden, IconButton, Input, Paper, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Trash } from 'react-feather';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import { deleteNote } from 'src/actions/notesActions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    height: 68,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
}));

function Toolbar({ className, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { systemLabel, customLabel, noteId } = useParams();

  const handleBack = () => {
    if (systemLabel) {
      return navigate(`/notes/${systemLabel}`);
    }

    if (customLabel) {
      return navigate(`/notes/label/${customLabel}`);
    }

    return navigate('/notes/inbox');
  };

  const handleDelete = async () => {
    await dispatch(deleteNote(noteId));
    navigate('/notes/all');
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Tooltip title="Back">
        <IconButton
          onClick={handleBack}
          size="large"
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Box flexGrow={1} />
      <Hidden lgDown>
        <Paper
          component={Box}
          display="flex"
          alignItems="center"
          variant="outlined"
          py={0.5}
          px={1}
          mr={2}
        >
          <SearchIcon color="action" />
          <Input
            className={classes.searchInput}
            disableUnderline
            placeholder="Search note"
          />
        </Paper>
      </Hidden>
      <Tooltip title="Delete">
        <IconButton
          onClick={handleDelete}
          size="large"
        >
          <Trash fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Previous note">
        <IconButton size="large">
          <KeyboardArrowLeftIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Next note">
        <IconButton size="large">
          <KeyboardArrowRightIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
