const prefix = (text) => `@messageNotification/${text}`;

export const NOTIFICATION_TYPES_LOADED = prefix`types-loaded`;
export const NOTIFICATION_SET = prefix`notification-set`;
export const NOTIFICATIONS_REMOVED = prefix`notification-removed`;

export function setNotificationTypes(types) {
  return {
    type: NOTIFICATION_TYPES_LOADED,
    payload: types,
  };
}

export function clearNotifications() {
  return {
    type: NOTIFICATION_SET,
    payload: [],
  };
}

export function setNotifications(notifications) {
  return {
    type: NOTIFICATION_SET,
    payload: notifications,
  };
}

export function removeNotifications(notificationsToRemove) {
  return {
    type: NOTIFICATIONS_REMOVED,
    payload: notificationsToRemove,
  };
}
