import { Outlet } from 'react-router-dom';
import { Hidden } from '@mui/material';
import ThirdTopBar from '../MainLayout/ThirdTopBar';
import SecondaryTopBar from '../MainLayout/SecondaryTopBar';

function DashBoardLayout() {
  return (
    <>
      <Hidden lgDown>
        <SecondaryTopBar />
      </Hidden>
      <Hidden lgDown>
        <ThirdTopBar rtl />
      </Hidden>
      <div style={{ overflow: 'auto', height: '100%' }}>
        <Outlet />
      </div>
    </>
  );
}

export default DashBoardLayout;
