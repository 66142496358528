import { useEffect } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import useEventCallback from 'src/hooks/useEventCallback';
import { BroadcastChannel } from 'broadcast-channel';
import { logout } from 'src/actions/accountActions';
import { useDispatch } from 'react-redux';
import baseUrl from 'src/utils/baseUrl';

const BroadcastContext = createContext(undefined);

function BroadcastProvider(props) {
  let channel;
  const createChannel = () => {
    channel = new BroadcastChannel('corneaConnectSession', {
      idb: {
        onclose: () => {
          // the onclose event is just the IndexedDB closing.
          // you should also close the channel before creating
          // a new one.
          channel.close();
          createChannel();
        },
      },
    });
  };
  createChannel();

  const { children } = props;
  const dispatch = useDispatch();

  const handleMessage = (msg) => {
    const { command } = msg;
    switch (command) {
      case 'logout': {
        dispatch(logout(msg?.reason));
        break;
      }
      case 'login': {
        window.location.reload();
        break;
      }
      case 'refreshSession': {
        setTimeout(() => {
          navigator.sendBeacon(`${baseUrl}/public/refreshToken`);
        }, 1000);
        break;
      }
      default:
        break;
    }
  };
  useEffect(() => {
    channel.addEventListener('message', handleMessage);

    return () => channel.removeEventListener('message', handleMessage);
  }, []);

  const logoutTabs = useEventCallback((reason) => {
    return channel.postMessage({
      command: 'logout',
      reason,
    });
  });
  const loginTabs = useEventCallback(() => {
    return channel.postMessage({
      command: 'login',
    });
  });

  const refreshSessionsTabs = useEventCallback((reason) => {
    return channel.postMessage({
      command: 'refreshSession',
      reason,
    });
  });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    logoutTabs,
    loginTabs,
    refreshSessionsTabs,
  };
  return (
    <BroadcastContext.Provider value={contextValue}>
      {children}
    </BroadcastContext.Provider>
  );
}

const useBroadcastContext = (selector) =>
  useContextSelector(BroadcastContext, selector);
const useLogoutTabs = () => useBroadcastContext((ctx) => ctx.logoutTabs);
const useLoginTabs = () => useBroadcastContext((ctx) => ctx.loginTabs);
const useRefreshSessionTabs = () =>
  useBroadcastContext((ctx) => ctx.refreshSessionsTabs);

export {
  BroadcastProvider,
  useBroadcastContext,
  useLogoutTabs,
  useLoginTabs,
  useRefreshSessionTabs,
};
