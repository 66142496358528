import { useRef, useCallback } from 'react';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

function useEventCallback(fn) {
  const ref = useRef(fn);
  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args) => ref.current.apply(0, args), []);
}

export default useEventCallback;
