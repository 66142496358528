import axios from 'src/utils/axios';

export const CREATE_DONOR = '@donor/create-donor';
export const UPDATE_DONOR = '@donor/update-donor';
export const GET_DONOR = '@donor/get-donor';
export const GET_ETHNICITY_LIST = '@donor/get-ethnicity-list';
export const GET_GENDER_LIST = '@donor/get-gender-list';
export const GET_DONOR_TYPES_LIST = '@donor/get-donor-types-list';
export const GET_REFERRAL_TYPES_LIST = '@donor/get-referral-types-list';
export const GET_RACE_TYPES = '@donor/get-race-types';
export const OPEN_DOD_DIALOG = '@donor/open-dod-dialog';
export const CLOSE_DOD_DIALOG = '@donor/close-dod-dialog';
export const DOD_OVERRIDE_TRUE = '@donor/set-dod-override-true';
export const DOD_OVERRIDE_FALSE = '@donor/set-dod-override-false';

export function createDonor(create) {
  const request = axios.post('/public/donors', { ...create });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: CREATE_DONOR,
        payload: response.data,
      }),
    );
  };
}

export function updateDonor(update) {
  const request = axios.put(`/public/donors/${update.donorId}`, {
    ...update.values,
  });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: UPDATE_DONOR,
        payload: response.data,
      }),
    );
  };
}

export function getDonor(donorId) {
  const request = axios.get(`/public/donors/${donorId}`);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_DONOR,
        payload: response.data,
      }),
    );
  };
}

export function getEthnicities() {
  const request = axios.get('/public/lookups/ethnicity');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_ETHNICITY_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getGenders() {
  const request = axios.get('/public/lookups/gender');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_GENDER_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getDonorTypes() {
  const request = axios.get('/public/lookups/donorType');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_DONOR_TYPES_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getReferralTypes() {
  const request = axios.get('/public/lookups/referralType');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_REFERRAL_TYPES_LIST,
        payload: response.data,
      }),
    );
  };
}

export function getRaceTypes() {
  const request = axios.get('/public/lookups/race');

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_RACE_TYPES,
        payload: response.data,
      }),
    );
  };
}

export function openDodDialog() {
  return {
    type: OPEN_DOD_DIALOG,
  };
}
export function closeDodDialog() {
  return {
    type: CLOSE_DOD_DIALOG,
  };
}

export function setDodOverrideTrue() {
  return {
    type: DOD_OVERRIDE_TRUE,
  };
}
export function setDodOverrideFalse() {
  return {
    type: DOD_OVERRIDE_FALSE,
  };
}
