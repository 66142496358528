// import axios from 'src/utils/axios';

// export const GET_NOTIFICATIONS = '@notifications/get-notifications';

// export function getNotifications() {
//   const request = axios.get('/api/notifications');

//   return (dispatch) => {
//     request.then((response) => dispatch({
//       type: GET_NOTIFICATIONS,
//       payload: response.data
//     }));
//   };
// }

// TODO: will add back the above code later

export const GET_NOTIFICATIONS = '@notifications/get-notifications';

export function getNotifications() {
  return (dispatch) => {
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: {
        notifications: [
          {
            id: '5e8883f1b51cc1956a5a1ec0',
            title: 'New task received',
            description: 'You have 5 new tasks',
            type: 'new_task',
            createdAt: '2 hours ago',
          },
          {
            id: '5e8883f7ed1486d665d8be1e',
            title: 'New message received',
            description: 'You have 2 unread messages',
            type: 'new_message',
            createdAt: '1 day ago',
          },
        ],
      },
    });
  };
}
