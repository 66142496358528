/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CREATE_DONOR,
  GET_DONOR,
  UPDATE_DONOR,
  GET_ETHNICITY_LIST,
  GET_GENDER_LIST,
  GET_DONOR_TYPES_LIST,
  GET_REFERRAL_TYPES_LIST,
  GET_RACE_TYPES,
  OPEN_DOD_DIALOG,
  CLOSE_DOD_DIALOG,
  DOD_OVERRIDE_TRUE,
  DOD_OVERRIDE_FALSE,
} from 'src/actions/donorActions';

const initialState = {
  donorItem: null,
  ethnicityList: [],
  genderList: [],
  donorTypesList: [],
  referralTypesList: [],
  raceTypes: [],
  dodDialog: {
    isOpen: false,
    dialogOverride: false,
  },
};

const donorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DONOR: {
      const { donorItem } = action.payload;

      return produce(state, (draft) => {
        draft.donorItem = donorItem;
      });
    }

    case GET_DONOR: {
      const { donorItem } = action.payload;
      return produce(state, (draft) => {
        draft.donorItem = donorItem;
      });
    }

    case UPDATE_DONOR: {
      const { donorItem } = action.payload;

      return produce(state, (draft) => {
        draft.donorItem = donorItem;
      });
    }

    case GET_ETHNICITY_LIST: {
      const list = action.payload.lookups;
      return produce(state, (draft) => {
        draft.ethnicityList = list;
      });
    }

    case GET_GENDER_LIST: {
      const list = action.payload.lookups;
      return produce(state, (draft) => {
        draft.genderList = list;
      });
    }

    case GET_DONOR_TYPES_LIST: {
      const list = action.payload.lookups;
      return produce(state, (draft) => {
        draft.donorTypesList = list;
      });
    }

    case GET_REFERRAL_TYPES_LIST: {
      const list = action.payload.lookups;
      return produce(state, (draft) => {
        draft.referralTypesList = list;
      });
    }

    case GET_RACE_TYPES: {
      const list = action.payload.lookups;
      return produce(state, (draft) => {
        draft.raceTypes = list;
      });
    }

    case OPEN_DOD_DIALOG: {
      return produce(state, (draft) => {
        draft.dodDialog.isOpen = true;
      });
    }

    case CLOSE_DOD_DIALOG: {
      return produce(state, (draft) => {
        draft.dodDialog.isOpen = initialState.dodDialog.isOpen;
      });
    }

    case DOD_OVERRIDE_TRUE: {
      return produce(state, (draft) => {
        draft.dodDialog.dialogOverride = true;
      });
    }
    case DOD_OVERRIDE_FALSE: {
      return produce(state, (draft) => {
        draft.dodDialog.dialogOverride = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default donorReducer;
