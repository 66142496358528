import PropTypes from 'prop-types';
import useBeforeUnload from '../hooks/useBeforeUnload';

function BeforeUnload(props) {
  useBeforeUnload(props.onBeforeUnload);
  return props.children;
}

BeforeUnload.defaultProps = {
  children: null,
};

BeforeUnload.propTypes = {
  children: PropTypes.any,
  onBeforeUnload: PropTypes.func.isRequired,
};

export default BeforeUnload;
