/* eslint-disable no-unsafe-optional-chaining */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { FieldArray } from 'formik';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Box,
  IconButton,
  Typography,
  Tooltip,
  Button,
  useMediaQuery,
} from '@mui/material';
import {
  MinusCircle as MinusCircleIcon,
  PlusCircle as PlusCircleIcon,
} from 'react-feather';
import { stripNull, removeFromArray, addItemToArray } from 'src/utils/object';
import { useSelector } from 'react-redux';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AddCircle } from '@mui/icons-material';
import EnhancedTableHead from './EnhancedTableHead';
import FormField from '../FormField';
import CustomField from '../CustomField';
import CustomTableMobileRow from './CustomTableMobileRow';
import Show from 'src/components/Show';

const invalidDate = 'Invalid Date';

// Probably should make a common component for this.
const debounce = (delay, fn) => {
  let nextCallArgs = [];
  let isWaiting = false;
  let invokedSinceWaiting = false;
  return (...args) => {
    if (isWaiting) {
      nextCallArgs = args;
      invokedSinceWaiting = true;
    } else {
      isWaiting = true;
      invokedSinceWaiting = false;
      setTimeout(() => {
        isWaiting = false;
        if (invokedSinceWaiting) {
          fn(...nextCallArgs);
          invokedSinceWaiting = false;
        }
      }, delay);
      fn(...args);
    }
  };
};

const useStyles = makeStyles((theme) => ({
  floatRight: {
    float: 'right',
  },
  root: {
    '& .MuiTable-root': {
      borderCollapse: 'unset',
    },
    '& .MuiTableCell-root': {
      // borderBottom: 'inherit',
    },
  },
  removeButton: {
    color: theme.palette.alternative.main,
  },
  filterInput: {
    '& .MuiFormLabel-root': {
      marginTop: '9px',
    },
  },

  actionContent: {
    padding: 0,
    whiteSpace: 'noWrap',
    borderBottom: 0,
    width: '80px', // hard code it in there because of table layout
  },
  tableContent: {
    padding: 0,
    // whiteSpace: 'noWrap',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

function EnhancedTable({ headCells, columns, options, data }) {
  const [forceRender, setForceRender] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [colSortOrder, setColSortOrder] = useState([]);
  const [pendingFilters, setPendingFilters] = useState({});
  const [filters, setFilters] = useState({});
  const classes = useStyles();
  const { mode: formMode } = useSelector((state) => state.formPermissions);
  const viewMode = formMode === 'view' || options?.global?.viewOnly;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const itemsPerPage = options?.itemsPerPage || data?.length;
  const mobileMode = isSmall && options.allowMobile;
  const handleRequestSort = (event, property) => {
    const wasDescending = colSortOrder.find(
      (col) => col === `${property}_Desc`,
    );

    setColSortOrder(
      addItemToArray(
        removeFromArray(colSortOrder, `${property}_Asc`, `${property}_Desc`),
        `${property}_${wasDescending ? 'Asc' : 'Desc'}`,
      ),
    );
  };

  const updateFilter = (columnName, value) => {
    setPendingFilters(
      stripNull({
        ...filters,
        [columnName]: value || null,
      }),
    );
  };

  // Debounce setting the filters, ever-so-slightly increasing the render speed.
  useEffect(
    debounce(800, () => {
      setFilters(pendingFilters);
    }),
    [pendingFilters, setFilters],
  );

  const renderField = (column, index) => {
    if (column?.renderCell) {
      return column.renderCell(index);
    }
    if (options?.form) {
      const customDisabled =
        typeof column?.disabled === 'function'
          ? column.disabled(index)
          : column.disabled;

      const customMinDate =
        typeof column?.minDate === 'function' && column.minDate(index);

      const customMaxDate =
        typeof column?.maxDate === 'function' && column.maxDate(index);

      const customRequired =
        typeof column?.required === 'function'
          ? column.required(index)
          : column.required;

      return (
        <Show when={!forceRender}>
          <FormField
            {...column}
            disabled={customDisabled || false}
            minDate={customMinDate || column.minDates}
            maxDate={customMaxDate || column.maxDates}
            cbChange={(e) => {
              return typeof column?.cbChange === 'function'
                ? column?.cbChange(e, index)
                : null;
            }}
            name={`${options?.form}.${index}.${column.id}`}
            table
            label={null}
            {...options?.global}
            required={customRequired}
          />
        </Show>
      );
    }

    return (
      <CustomField
        {...column}
        {...options?.global}
        value={column.nonFormValue}
        table
        label={null}
      />
    );
  };

  const tableRender = (arrayHelpers) => {
    const allTableData = data?.length
      ? data
          .map((rowData, rowIndex) => [rowData, rowIndex])
          .filter(([rowData, rowIndex]) => {
            const filterEntries = Object.entries(filters);
            return (
              filterEntries.length === 0 ||
              filterEntries.every(([column, filterValue]) => {
                const colObj = columns.find((c) => c.id === column);
                return colObj.getFilterValue
                  ? `${colObj.getFilterValue(rowIndex, column)}`
                      .toLowerCase()
                      .includes(`${filterValue}`.toLowerCase())
                  : `${rowData[column]}`
                      .toLowerCase()
                      .includes(`${filterValue}`.toLowerCase());
              })
            );
          })
          .sort(([a], [b]) => {
            for (let i = 0; i < colSortOrder.length; i++) {
              const col = colSortOrder[i].slice(
                0,
                colSortOrder[i].lastIndexOf('_'),
              );
              const isAscending = colSortOrder[i].endsWith('Asc');

              // can define custom comparators here, if we choose to do that

              let diff = 0;
              if (+a[col] === a[col] && +b[col] === b[col]) {
                diff = a[col] - b[col];
              } else if (
                new Date(a[col]).toString() !== invalidDate &&
                new Date(b[col]).toString() !== invalidDate
              ) {
                diff = new Date(a[col]).getTime() - new Date(b[col]).getTime();
              } else {
                diff = a[col] > b[col] ? 1 : a[col] < b[col] ? -1 : 0; // eslint-disable-line no-nested-ternary
              }
              if (diff) return diff * (isAscending ? 1 : -1);
            }
            return 0;
          })
          .filter(
            ([baseRowObject, index]) =>
              !options?.rows || !options?.rows(baseRowObject, index).hide,
          )
      : [];

    const shownTableData = options?.itemsPerPage
      ? allTableData.filter((_, index) => {
          return (
            pageIndex * itemsPerPage <= index &&
            index < (pageIndex + 1) * itemsPerPage
          );
        })
      : allTableData;

    const totalPages = Math.ceil(allTableData.length / itemsPerPage);

    const startItemNumber = Math.min(
      pageIndex * itemsPerPage + 1,
      allTableData.length,
    );
    const endItemNumber = Math.min(
      (pageIndex + 1) * itemsPerPage,
      allTableData.length,
    );

    return (
      <Box
        border={mobileMode ? '1px solid black' : undefined}
        width={options?.width}
      >
        {options?.itemsPerPage ? (
          <Box
            display="flex"
            alignItems="center"
            className={classes.floatRight}
          >
            <Tooltip title="Next page">
              <IconButton
                onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}
                size="large"
              >
                <KeyboardArrowLeftIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Typography
              noWrap
              variant="body2"
              color="textSecondary"
            >
              {`${startItemNumber} - ${endItemNumber} of ${allTableData.length}`}
            </Typography>
            <Tooltip title="Previous page">
              <IconButton
                onClick={() =>
                  setPageIndex(Math.min(pageIndex + 1, totalPages - 1))
                }
                size="large"
              >
                <KeyboardArrowRightIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
        {mobileMode ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              padding="5px 15px"
              style={{
                backgroundColor: '#e4e1e6',
              }}
              alignItems="center"
              borderBottom="1px solid black"
            >
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>
                {options?.title}
              </Typography>
              {options?.form &&
              options?.actions &&
              !viewMode &&
              options?.actions?.addRow instanceof Function ? (
                <Button
                  style={{ backgroundColor: '#1FB3E5', marginRight: '30px' }}
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    const result = options?.actions?.addRow
                      ? options?.actions?.addRow(allTableData?.length + 1)
                      : null;
                    if (options?.form) {
                      arrayHelpers.insert(allTableData?.length + 1, {
                        ...(result || options.initialFormData || {}),
                      });
                    }
                    e.stopPropagation();
                    return result;
                  }}
                >
                  <AddCircle style={{ marginRight: '5px' }} /> Add
                </Button>
              ) : null}
            </Box>
            <Box>
              {allTableData.map(([baseRowObject, index]) => {
                const rowObject = options?.rows
                  ? options?.rows(baseRowObject, index)
                  : { className: '' };

                return (
                  <CustomTableMobileRow
                    index={index}
                    rowObject={rowObject}
                    columns={columns}
                    baseRowObject={baseRowObject}
                    viewMode={viewMode}
                    headCells={headCells}
                    options={options}
                    arrayHelpers={arrayHelpers}
                  />
                );
              })}
            </Box>{' '}
          </>
        ) : (
          <Grid
            item
            lg={12}
            md={12}
          >
            <Table>
              <EnhancedTableHead
                colSortOrder={colSortOrder}
                onRequestSort={handleRequestSort}
                headCells={headCells}
                options={options}
              />
              <TableBody>
                {(options?.filter
                  ? [
                      <TableRow
                        key="table_filter_row"
                        className={classes.tableRowStyle}
                      >
                        {columns.map((column, index1) => {
                          return (
                            <TableCell
                              key={`table_cell_${column.id}`}
                              style={{
                                maxWidth: headCells[index1]?.maxWidth,
                                width: headCells[index1]?.width,
                              }}
                              className={`${classes.tableContent} ${classes.filterInput}`}
                            >
                              {
                                // Do not show filter options for checkboxes.
                                // The logic hasn't been implemented and the UI is kind of weird.
                                // If there's demand for it, implement it here.
                                column.inputType === 'checkbox' ? null : (
                                  <CustomField
                                    table
                                    inputType={column.inputType || 'text'}
                                    type={column.type}
                                    label="Filter"
                                    value={pendingFilters[column.id] || ''}
                                    onChange={(value) => {
                                      updateFilter(column.id, value);
                                    }}
                                    cbChange={(value) => {
                                      updateFilter(column.id, value);
                                    }}
                                    noColorChange
                                    writeOnly
                                  />
                                )
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>,
                    ]
                  : []
                ).concat(
                  shownTableData.map(([baseRowObject, index]) => {
                    const rowObject = options?.rows
                      ? options?.rows(baseRowObject, index)
                      : { className: '' };
                    return (
                      <TableRow
                        className={classes.tableRowStyle}
                        // eslint-disable-next-line
                        key={`table_row_${index}`}
                      >
                        {columns.map((column, columnIndex) => (
                          <TableCell
                            key={`table_cell_${column.id}_${index}`}
                            style={{
                              maxWidth: headCells[columnIndex]?.maxWidth,
                              width: headCells[columnIndex]?.width,
                            }}
                            {...rowObject}
                            {...column}
                            className={`${classes.tableContent} ${
                              rowObject.className
                            } ${column.className || ''}`}
                          >
                            {renderField(
                              {
                                ...column,
                                nonFormValue: baseRowObject[column.id],
                                ...(rowObject.columnProps?.[column.id] || {}),
                              },
                              index,
                            )}
                          </TableCell>
                        ))}
                        {(options?.form && options?.actions && !viewMode) ||
                        options?.actions?.addRow instanceof Function ||
                        options?.actions?.removeRow instanceof Function ? (
                          <TableCell className={classes.actionContent}>
                            <Box
                              justifyContent="flex-end"
                              display="flex"
                              width="fit-content"
                            >
                              {/* only render remove button if there is more then one in the array */}
                              {!viewMode &&
                                data.length > 1 &&
                                (rowObject.permanent == null ||
                                  !rowObject.permanent) && (
                                  <IconButton
                                    style={{ padding: 8 }}
                                    type="button"
                                    className={classes.removeButton}
                                    onClick={(e) => {
                                      const result = options?.actions?.removeRow
                                        ? options?.actions?.removeRow(index)
                                        : null;

                                      if (options?.form) {
                                        arrayHelpers.remove(index);
                                      }
                                      setForceRender(true);
                                      setTimeout(() => {
                                        setForceRender(false);
                                      }, 1);
                                      e.stopPropagation();
                                      return result;
                                    }}
                                    size="large"
                                  >
                                    <MinusCircleIcon />
                                  </IconButton>
                                )}

                              {/* button to add more values */}
                              {!viewMode && data.length === index + 1 && (
                                <IconButton
                                  style={{ padding: 8 }}
                                  type="button"
                                  color="secondary"
                                  onClick={(e) => {
                                    const result = options?.actions?.addRow
                                      ? options?.actions?.addRow(index + 1)
                                      : null;
                                    if (options?.form) {
                                      arrayHelpers.insert(index + 1, {
                                        ...(result ||
                                          options.initialFormData ||
                                          {}),
                                      });
                                    }
                                    e.stopPropagation();
                                    return result;
                                  }}
                                  size="large"
                                >
                                  <PlusCircleIcon />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  }),
                )}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Box>
    );
  };

  return options?.form ? (
    <Box>
      <FieldArray
        name={options.form}
        render={(arrayHelpers) => tableRender(arrayHelpers)}
      />
    </Box>
  ) : (
    <Box>{tableRender()}</Box>
  );
}
EnhancedTable.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.any,
  options: PropTypes.object,
};

export default EnhancedTable;
