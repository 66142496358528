import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public/partners';

class PartnerService {
  getPartnerByFlag = async (flag) => {
    try {
      const request = await axios.get(`${baseURL}/${flag}`);
      if (request.status === 200) {
        return request.data.partners;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getPartnerByOrg = async ({ organizationId, partnerTypeId }) => {
    try {
      const addUrl = [];
      if (organizationId) {
        addUrl.push(`organizationId=${organizationId}`);
      }
      if (partnerTypeId) {
        addUrl.push(`partnerTypeId=${partnerTypeId}`);
      }
      const additionalUrl = addUrl?.length ? `?${addUrl.join('&')}` : '';
      const request = await axios.get(
        `/public/organizationPartners${additionalUrl}`,
      );

      if (request.status === 200) {
        return request?.data?.partners?.map((orgPartner) => orgPartner.partner);
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getDefaultPartners = async (organizationId) => {
    try {
      const request = await axios.get(
        `/public/organizationPartners/${organizationId}`,
      );
      if (request.status === 200) {
        return request?.data.orgPartners;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const partnerService = new PartnerService();

export default partnerService;
