import { useEffect, useState, useCallback, memo } from 'react';
import {
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Tooltip,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import moment, { unitOfTime } from 'moment-timezone';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from 'src/store';
import { REQUIRED_FIELD } from 'src/constants';
import { DateTimeFormat, DateFormat } from './helpers';

type TempInputStyling = {
  borderRadius?: number;
  padding?: string;
  fontFamily?: string;
};
type TempClasses = {
  notchedOutline?: string;
  underline?: string;
  input?: string;
};

type OffSetTimeZone = { label: string; value: string };

type MinMaxDates = {
  typeName?: string;
  datetime?: string;
};

type AfterCustomDatetime = {
  typeName?: string;
  datetime?: string;
  noToAdd: number;
  typeToAdd?: unitOfTime.DurationConstructor;
};

type BeforeCustomDatetime = {
  typeName?: string;
  datetime?: string;
  noToSubtract: number;
  typeToSubtract?: unitOfTime.DurationConstructor;
};

type CallBackResp = {
  value: string | null | undefined;
  error: string | false;
};

const DEFAULT_CHANGECALLBACK = () => {};
const DEFAULT_SETTIMEZONE = () => {};
export type DateTimeProps = {
  type: 'date' | 'dateTime';
  value?: string;
  futureDisabled?: boolean;
  pastDisabled?: boolean;
  label?: string;
  changeCallback?: (e: CallBackResp) => void;
  width?: string;
  maxWidth?: string;
  zeroPadding?: boolean;
  noColorChange?: boolean;
  error?: string | null;
  name?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  readOnly?: boolean;
  squared?: boolean;
  required?: boolean;
  secured?: boolean;
  disabled?: boolean;
  table?: boolean;
  maxDate?: string | moment.Moment;
  minDate?: string | moment.Moment;
  maxCustomDate?: MinMaxDates;
  minCustomDate?: MinMaxDates;
  beforeDeath?: string | Date;
  afterDeath?: string | Date;
  afterCustomDatetime?: AfterCustomDatetime; // Allows us to specify to specify how much time, type of time and message to display
  beforeCustomDatetime?: BeforeCustomDatetime; // Allows us to specify to specify how much time, type of time and message to display
  timezone?: string | null;
  setTimezone?: (e: string | undefined) => void;
  writeOnly?: boolean;
  viewOnly?: boolean;
  showTimezone?: boolean;
  defaultLocal?: boolean;
  noInitialValue?: boolean;
  autoFillValidate?: boolean;
  expiredSupply?: boolean;
};

function FormDateTime({
  type = 'dateTime', // type for date picker 'date' or 'dateTime'
  value: valueProp, // the inital value you want it to start with
  futureDisabled = true, // boolean to determine if users can select future date or not.
  pastDisabled, // boolean to determine if users can select future date or not.f
  label, // the label of the *input*
  changeCallback = DEFAULT_CHANGECALLBACK, // function executed on change
  width = '100%', // percentage
  maxWidth, // percentage
  zeroPadding,
  noColorChange = false, // boolean if u want it to change color if no longer inital value
  error: errorProp, // a string with an error message
  name: nameProp, // the name of the input
  variant: variantProp = 'outlined', // string of which variant it is (standard, filled, outlined)
  readOnly: readOnlyProp = false, // boolean for displaying normal but not allowing type (disabled is different)
  squared = false, // boolean if you want the border to be straight or roudned
  required: requiredProp = false, // boolean for checking required validation
  secured: securedProp = false, // boolean for checking if a value is suppose to be hidden
  disabled: disabledProp = false, // boolean for checking disabled,
  table: tableProp = false, // show or hide the border
  maxDate: maxProp, // set the max date value
  minDate: minProp, // set the min date value
  maxCustomDate: maxCustomDateProp,
  minCustomDate: minCustomDateProp,
  beforeDeath: beforeDeathProp, // checks if value is before date/time of death
  afterDeath: afterDeathProp, // checks if value is 72 hrs after date/time of death
  afterCustomDatetime: afterCustomDatetimeProp, // Allows us to specify to specify how much time, type of time and message to display
  beforeCustomDatetime: beforeCustomDatetimeProp, // Allows us to specify to specify how much time, type of time and message to display
  timezone: timezoneProp, // set the timezone
  setTimezone: setTimezoneProp = DEFAULT_SETTIMEZONE, // set the timezone
  writeOnly = false, // always be in write mode even if it in in view mode
  viewOnly = false, // always be in view mode even if it in in write mode
  showTimezone: showTimezoneProp = false,
  defaultLocal = false,
  noInitialValue = false, // passed to conditionally rendered fields or fields that populate based on other fields to properly clear out value on cancel
  autoFillValidate: autoFillValidateProp = false,
  expiredSupply = false,
}: DateTimeProps) {
  const formMode = useAppSelector((state) => state.formPermissions.mode);
  const timezone = useAppSelector((state) => state.account?.user?.timezone);
  const dateFormat = type === 'date' ? 'MM/DD/YYYY' : 'MM/DD/YYYY HH:mm';
  const timezoneDefined = timezoneProp || timezone || '';
  const getCorrectedValue = () => {
    if (showTimezoneProp && errorProp === 'Please select a timezone.') {
      return moment(valueProp).tz(timezoneDefined).format(dateFormat);
    }
    if (!showTimezoneProp && errorProp) {
      return moment(valueProp).local().format(dateFormat);
    }

    if (valueProp) {
      if (showTimezoneProp) {
        return moment(valueProp).tz(timezoneDefined).format(dateFormat);
      }
      return moment(valueProp).local().format(dateFormat);
    }
    return null;
  };
  const [localValue, setLocalValue] = useState(getCorrectedValue());
  const [isFocused, setisFocused] = useState(false);
  const [inputStyling, setInputStyling] = useState({});
  const [errorMessage, setErrorMessage] = useState(errorProp);
  const [anchorEl, setAnchorEl] = useState<
    Element | (() => Element) | null | undefined
  >(null);
  const [customClasses, setCustomClasses] = useState({});
  const [timezoneOptions, setTimezoneOptions] = useState<
    OffSetTimeZone[] | undefined
  >();
  const [isViewMode, setIsViewMode] = useState(true); // boolean for conditionally determining if the form is in view mode
  const [isInitialValue, setIsInitialValue] = useState(true); // boolean for conditionally determining if the selected value has changed from it's initial value
  const [initialValueField] = useState(getCorrectedValue());

  // mobile tooltip state and media query
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xl'));

  const updateError = useCallback(() => {
    if (formMode === 'view') {
      setErrorMessage(null);
      return;
    }
    setErrorMessage(errorProp);
  }, [errorProp, formMode]);

  useEffect(() => {
    updateError();
  }, [updateError]);

  // a function to set the state of isViewMode and isInitialValue
  const setFormPermissionValues = useCallback(async () => {
    if (formMode) {
      if (writeOnly) {
        setIsViewMode(false);
      } else {
        setIsViewMode(viewOnly || formMode === 'view');
      }
    }

    if (localValue != null && initialValueField != null) {
      setIsInitialValue(
        moment(localValue).local().format(dateFormat) ===
          moment(initialValueField).local().format(dateFormat),
      );
    } else if (localValue != null) {
      setIsInitialValue(false);
    } else {
      setIsInitialValue(true);
    }
  }, [
    formMode,
    localValue,
    initialValueField,
    dateFormat,
    writeOnly,
    viewOnly,
  ]);

  useEffect(() => {
    setFormPermissionValues();
  }, [setFormPermissionValues]);

  const handleClickListItem = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent, value: string) => {
    if (setTimezoneProp) {
      setTimezoneProp(value);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setCurrentValue = useCallback(async () => {
    // fields with no initial value (like conditionally rendered) clear out fine as is
    if (noInitialValue) {
      setLocalValue(getCorrectedValue());
      return null;
    }
    // for other fields, need to see if initial value is false before resetting local value to clear out fields properly
    if (!isInitialValue) setLocalValue(getCorrectedValue());
    return null;
    // eslint-disable-next-line
  }, [valueProp, dateFormat, errorProp]);

  useEffect(() => {
    setCurrentValue();
  }, [setCurrentValue]);

  // set certain styling based on options
  const setStyling = useCallback(() => {
    // set initial temp variable for styling and classes to nothing
    const tempInputStyling: TempInputStyling = {};
    const tempClasses: TempClasses = {};

    // remove the rounded borders if squared is specified
    if (squared) {
      tempInputStyling.borderRadius = 0;
    }

    // if secured is true then check if they are focused on the
    // field, if they are then set the font family to a bullet point
    // font family
    if (securedProp) {
      if (!isFocused) {
        tempInputStyling.fontFamily = 'text-security-disc';
      }
    }

    // set state to the temp variable and return
    setInputStyling(tempInputStyling);
    setCustomClasses(tempClasses);
    return { tempInputStyling, tempClasses };
  }, [securedProp, squared, isFocused, readOnlyProp, zeroPadding, variantProp]);

  useEffect(() => {
    setStyling();
  }, [setStyling]);

  // a function to check for errors and also send the value in the callback
  const sendValue = async (controlledDate: string | null | undefined) => {
    // set controlled date to state value
    if (controlledDate) setLocalValue(controlledDate);

    // check if controlled date was sent over , if so use that
    // over the local value
    const dateValue = controlledDate || localValue;

    if (dateValue && defaultLocal && !timezoneProp && setTimezoneProp) {
      setTimezoneProp(timezone);
    }
    // create a temp varable so it is easy to edit a message
    let tempErrorMessage = '';

    setisFocused(false);

    // check to see if date entered is valid
    if (dateValue && moment(dateValue, dateFormat, true).isValid() === true) {
      // check if max date is valid and if it is before the max date

      if (
        futureDisabled &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isAfter(moment().tz(timezoneDefined).format(dateFormat))
      ) {
        tempErrorMessage = 'The date entered can’t be in the future.';
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        maxProp &&
        moment(maxProp).isValid() &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isAfter(moment(maxProp).tz(timezoneDefined).format(dateFormat))
      ) {
        tempErrorMessage = `The date entered can’t be after ${moment(maxProp)
          .tz(timezoneDefined)
          .format(dateFormat)}.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        pastDisabled &&
        !isInitialValue &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isBefore(moment().tz(timezoneDefined).format(dateFormat))
      ) {
        tempErrorMessage = 'The date entered can’t be in the past.';
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        expiredSupply &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isBefore(moment().tz(timezoneDefined).format(dateFormat))
      ) {
        tempErrorMessage = 'The date entered is expired.';
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        minProp &&
        moment(minProp).isValid() &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isBefore(moment(minProp).tz(timezoneDefined).format(dateFormat))
      ) {
        tempErrorMessage = `The date entered can’t be before ${moment(minProp)
          .tz(timezoneDefined)
          .format(dateFormat)}.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        beforeDeathProp &&
        moment(beforeDeathProp).isValid() &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isBefore(
          moment(beforeDeathProp).tz(timezoneDefined).format(dateFormat),
        )
      ) {
        tempErrorMessage = `The date entered can’t be before date/time of death.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        afterDeathProp &&
        moment(afterDeathProp).isValid() &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isAfter(
          moment(afterDeathProp)
            .add(72, 'hours')
            .tz(timezoneDefined)
            .format(dateFormat),
        )
      ) {
        tempErrorMessage = `The date entered can’t be 72 hours after date/time of death.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        afterCustomDatetimeProp &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isAfter(
          moment(afterCustomDatetimeProp.datetime)
            .add(
              afterCustomDatetimeProp.noToAdd,
              afterCustomDatetimeProp.typeToAdd,
            )
            .tz(timezoneDefined)
            .format(dateFormat),
        )
      ) {
        tempErrorMessage = `The date entered can’t be ${Math.abs(
          afterCustomDatetimeProp.noToAdd,
        )} ${afterCustomDatetimeProp.typeToAdd} after date/time of ${
          afterCustomDatetimeProp.typeName
        }.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        beforeCustomDatetimeProp &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isBefore(
          moment(beforeCustomDatetimeProp.datetime)
            .subtract(
              beforeCustomDatetimeProp.noToSubtract,
              beforeCustomDatetimeProp.typeToSubtract,
            )
            .tz(timezoneDefined)
            .format(dateFormat),
        )
      ) {
        tempErrorMessage = `The date entered can’t be ${Math.abs(
          beforeCustomDatetimeProp.noToSubtract,
        )} ${beforeCustomDatetimeProp.typeToSubtract} before date/time of ${
          beforeCustomDatetimeProp.typeName
        }.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        maxCustomDateProp &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isAfter(
          moment(maxCustomDateProp.datetime)
            .tz(timezoneDefined)
            .format(dateFormat),
        )
      ) {
        tempErrorMessage = `The date entered can’t be after date/time of ${maxCustomDateProp.typeName}.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        minCustomDateProp &&
        moment(
          moment(dateValue).tz(timezoneDefined).local().format(dateFormat),
        ).isBefore(
          moment(minCustomDateProp.datetime)
            .tz(timezoneDefined)
            .format(dateFormat),
        )
      ) {
        tempErrorMessage = `The date entered can’t be before date/time of ${minCustomDateProp.typeName}.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      if (
        showTimezoneProp &&
        !timezoneProp &&
        !(isViewMode || readOnlyProp || disabledProp)
      ) {
        tempErrorMessage = `Please select a timezone.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({
          value: moment(dateValue).tz(timezoneDefined).utc().format(),
          error: tempErrorMessage,
        });
      }

      // if no more errors exist clear the errors
      if (showTimezoneProp && timezoneProp) {
        // eslint-disable-next-line
        // get the date with the old and new timezone
        const currentDate = moment(dateValue);
        const newDate = moment(currentDate).tz(timezoneProp);

        // get utc offsets of new and old time
        const currentOffset = currentDate.utcOffset();
        const newOffset = newDate.utcOffset();

        // get the hour difference between the offsets
        const diffInMinutes = (currentOffset - newOffset) / 60;

        // add that hour difference to the date and change the timezone
        const correctedDate = moment(currentDate)
          .add(diffInMinutes, 'hours')
          .tz(timezoneProp)
          .format();

        // set back to the component
        setErrorMessage('');
        return changeCallback({
          value: moment(correctedDate).tz(timezoneProp).utc().format(),
          error: false,
        });
      }

      setErrorMessage('');
      return changeCallback({
        value: moment(dateValue).utc().format(),
        error: false,
      });
    }
    if (!dateValue || dateValue === '') {
      if (timezoneProp && showTimezoneProp) {
        tempErrorMessage = `Required date because of timezone.`;
        setErrorMessage(tempErrorMessage);
        return changeCallback({ value: dateValue, error: tempErrorMessage });
      }

      tempErrorMessage = requiredProp ? REQUIRED_FIELD : '';
      setErrorMessage(requiredProp ? REQUIRED_FIELD : '');

      setErrorMessage('');
      return changeCallback({
        value: null,
        error: requiredProp ? REQUIRED_FIELD : false,
      });
    }
    if (dateValue != null || dateValue !== '') {
      tempErrorMessage = `The ${
        type === 'date' ? 'date' : 'date/time'
      } entered is invalid.`;
      setErrorMessage(tempErrorMessage);
      return changeCallback({ value: dateValue, error: tempErrorMessage });
    }
    if (errorProp) {
      tempErrorMessage = errorProp;
      setErrorMessage(tempErrorMessage);
      return changeCallback({ value: dateValue, error: tempErrorMessage });
    }

    setErrorMessage('');
    return changeCallback({
      value: null,
      error: requiredProp ? REQUIRED_FIELD : false,
    });
  };

  const checkAgain = useCallback(() => {
    if (valueProp) {
      sendValue(null);
    }
    // ignored, will make sendvalue a callback later
    // eslint-disable-next-line
  }, [
    minCustomDateProp?.datetime,
    maxCustomDateProp?.datetime,
    minCustomDateProp?.typeName,
    maxCustomDateProp?.typeName,
    beforeCustomDatetimeProp?.datetime,
    beforeCustomDatetimeProp?.noToSubtract,
    beforeCustomDatetimeProp?.typeToSubtract,
    beforeCustomDatetimeProp?.typeName,
    afterCustomDatetimeProp?.datetime,
    afterCustomDatetimeProp?.noToAdd,
    afterCustomDatetimeProp?.typeToAdd,
    afterCustomDatetimeProp?.typeName,
    minProp,
    maxProp,
    futureDisabled,
    timezoneProp,
    showTimezoneProp,
    pastDisabled,
    dateFormat,
    isViewMode,
    disabledProp,
    readOnlyProp,
    expiredSupply,
  ]);

  useEffect(() => {
    checkAgain();
  }, [checkAgain]);

  // a function that checks if 't' has been pressed and if it
  // it pressed then set the value to the current date and send it
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event?.keyCode === 84) {
      event.preventDefault();

      setLocalValue(moment().tz(timezoneDefined).format(dateFormat));
      // checks validation and sends back to the callback
      // needs to send over value so that it doesn't rely based on state
      // changing super fast also added a very short timeout so that localValue
      // will be set instantly
      sendValue(moment().tz(timezoneDefined).format(dateFormat));
    }
  };

  const populateTimezones = useCallback(() => {
    const timezones = ['US/Eastern', 'US/Pacific', 'US/Mountain', 'US/Central'];

    // start empty array of timezones
    const offsetTmz: OffSetTimeZone[] = [];

    // check each timezone
    timezones.forEach((time) => {
      const timeZoneOption = {
        label: `${moment(moment(localValue).isValid() ? localValue : undefined)
          .tz(time)
          .zoneAbbr()} (${
          moment(moment(localValue).isValid() ? localValue : undefined)
            .tz(time)
            .utcOffset() / 60
        })`,
        value: time,
      };

      // push it into the array of timezones
      offsetTmz.push(timeZoneOption);
    });

    setTimezoneOptions(offsetTmz);
  }, [localValue]);

  useEffect(() => {
    populateTimezones();
  }, [populateTimezones]);

  // a function for setting the local value and clearing if it is an empty string
  const handleChange = ({
    value,
    formattedValue,
  }: {
    value: string;
    formattedValue: string;
  }) => {
    if (formattedValue === '') {
      setLocalValue(null);
      sendValue(null);
    } else {
      setLocalValue(formattedValue);
      if (autoFillValidateProp) {
        sendValue(getCorrectedValue());
      }
    }
  };

  // used to toggle mobile tooltip, auto closes in 5 sec
  const toggleTip = () => {
    if (localValue) {
      setToolTipOpen(!toolTipOpen);
      setTimeout(() => {
        setToolTipOpen(false);
      }, 3000);
    }
  };

  const timeZoneAbbreviation = timezoneProp
    ? moment(moment(localValue).isValid() ? localValue : undefined)
        .tz(timezoneDefined)
        .zoneAbbr()
    : '';

  if (mobileView) {
    return (
      <Tooltip
        title={`${localValue || '  '} ${timeZoneAbbreviation}`}
        disableFocusListener
        placement="top-start"
        open={toolTipOpen}
        sx={{
          '.MuiTooltip-tooltip': {
            width: 'fit-content',
            fontSize: '1.2rem',
          },
        }}
      >
        <div
          style={{ width: width || '100%' }}
          onClick={() => (isViewMode ? toggleTip() : null)}
        >
          <NumberFormat
            value={localValue || ''}
            name={nameProp}
            onFocus={() => {
              setisFocused(true);
            }}
            onBlur={() => sendValue(localValue)}
            readOnly={readOnlyProp || isViewMode}
            disabled={disabledProp}
            onValueChange={(value, sourceInfo) => {
              handleChange({
                value: value.value,
                formattedValue: value.formattedValue,
              });
            }}
            format={type === 'date' ? DateFormat : DateTimeFormat}
            variant={readOnlyProp ? 'standard' : variantProp}
            InputProps={{
              endAdornment: showTimezoneProp && (
                <InputAdornment position="end">
                  <Typography
                    style={{
                      color:
                        !localValue ||
                        timezoneProp ||
                        isViewMode ||
                        readOnlyProp ||
                        disabledProp
                          ? 'inherit'
                          : 'red',
                      cursor:
                        !(isViewMode || readOnlyProp || disabledProp) &&
                        timezoneOptions?.length
                          ? 'pointer'
                          : 'inherit',
                    }}
                    onClick={handleClickListItem}
                  >
                    {timezoneProp
                      ? moment(
                          moment(localValue).isValid() ? localValue : undefined,
                        )
                          .tz(timezoneDefined)
                          .zoneAbbr()
                      : '____'}
                  </Typography>
                  {!(isViewMode || readOnlyProp || disabledProp) &&
                    timezoneOptions?.length && (
                      <Menu
                        id="lock-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {timezoneOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            selected={
                              option.value ===
                              moment(
                                moment(localValue).isValid()
                                  ? localValue
                                  : undefined,
                              )
                                .tz(timezoneDefined)
                                .zoneAbbr()
                            }
                            onClick={(event) =>
                              handleMenuItemClick(event, option.value)
                            }
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                </InputAdornment>
              ),
              tabIndex:
                isViewMode || readOnlyProp || disabledProp ? -1 : undefined,
              style: inputStyling,
              classes: customClasses,

              sx: {
                cursor:
                  !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
                pointerEvents:
                  !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
                color:
                  isInitialValue || noColorChange || isViewMode
                    ? 'inherit'
                    : '#af1685',
                paddingBottom: zeroPadding ? 0 : 'auto',
                padding: zeroPadding ? '0 !important' : 'auto',
                height: zeroPadding ? 'undefined' : 'auto',
                paddingLeft: zeroPadding ? 0 : 'auto',
                '& .MuiInput-root:before': {
                  borderBottom: readOnlyProp ? 'none' : 'auto',
                },
                '& .MuiInput-root:after': {
                  borderBottom: readOnlyProp ? 'none' : 'auto',
                },
              },
              readOnly: readOnlyProp || isViewMode,
              inputProps: {
                autoComplete: 'new-password',
                tabIndex:
                  isViewMode || readOnlyProp || disabledProp ? -1 : undefined,
              },
            }}
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                border:
                  !readOnlyProp && !isViewMode && requiredProp
                    ? '3px solid'
                    : tableProp
                      ? 0
                      : 'auto',
              },
              '& .MuiInput-root:before': {
                borderBottom: readOnlyProp ? 'none' : 'auto',
              },
              '& .MuiInput-root:after': {
                borderBottom: readOnlyProp ? 'none' : 'auto',
              },
            }}
            allowEmptyFormatting
            onKeyDown={handleKeyDown}
            error={isViewMode ? false : !!errorMessage}
            helperText={isViewMode ? null : errorMessage}
            label={label}
            size="small"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required={requiredProp}
            autoComplete="new-password"
            customInput={TextField}
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <NumberFormat
      style={{
        width,
        maxWidth,
        cursor: !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
        pointerEvents:
          !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
        color:
          isInitialValue || noColorChange || isViewMode ? 'inherit' : '#af1685',
      }}
      value={localValue || ''}
      name={nameProp}
      onFocus={() => {
        setisFocused(true);
      }}
      onBlur={() => sendValue(localValue)}
      readOnly={readOnlyProp || isViewMode}
      disabled={disabledProp}
      onValueChange={(value, sourceInfo) => {
        handleChange({
          value: value.value,
          formattedValue: value.formattedValue,
        });
      }}
      format={type === 'date' ? DateFormat : DateTimeFormat}
      variant={readOnlyProp ? 'standard' : variantProp}
      InputProps={{
        endAdornment: showTimezoneProp && (
          <InputAdornment position="end">
            <Typography
              style={{
                color:
                  !localValue ||
                  timezoneProp ||
                  isViewMode ||
                  readOnlyProp ||
                  disabledProp
                    ? 'inherit'
                    : 'red',
                cursor:
                  !(isViewMode || readOnlyProp || disabledProp) &&
                  timezoneOptions?.length
                    ? 'pointer'
                    : 'inherit',
              }}
              onClick={handleClickListItem}
            >
              {timezoneProp
                ? moment(moment(localValue).isValid() ? localValue : undefined)
                    .tz(timezoneDefined)
                    .zoneAbbr()
                : '____'}
            </Typography>
            {!(isViewMode || readOnlyProp || disabledProp) &&
              timezoneOptions?.length && (
                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {timezoneOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      selected={
                        option.value ===
                        moment(
                          moment(localValue).isValid() ? localValue : undefined,
                        )
                          .tz(timezoneDefined)
                          .zoneAbbr()
                      }
                      onClick={(event) =>
                        handleMenuItemClick(event, option.value)
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              )}
          </InputAdornment>
        ),
        tabIndex: isViewMode || readOnlyProp || disabledProp ? -1 : undefined,
        style: inputStyling,
        classes: customClasses,
        sx: {
          cursor: !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
          pointerEvents:
            !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
          color:
            isInitialValue || noColorChange || isViewMode
              ? 'inherit'
              : '#af1685',
          paddingBottom: zeroPadding ? 0 : 'auto',
          padding: zeroPadding ? '0 !important' : 'auto',
          height: zeroPadding ? 'undefined' : 'auto',
          paddingLeft: zeroPadding ? 0 : 'auto',
          '& .MuiInput-root:before': {
            borderBottom: readOnlyProp ? 'none' : 'auto',
          },
          '& .MuiInput-root:after': {
            borderBottom: readOnlyProp ? 'none' : 'auto',
          },
        },
        readOnly: readOnlyProp || isViewMode,
        inputProps: {
          autoComplete: 'new-password',
          tabIndex: isViewMode || readOnlyProp || disabledProp ? -1 : undefined,
        },
      }}
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          border:
            !readOnlyProp && !isViewMode && requiredProp
              ? '3px solid'
              : tableProp
                ? 0
                : 'auto',
        },
        '& .MuiInput-root:before': {
          borderBottom: readOnlyProp ? 'none' : 'auto',
        },
        '& .MuiInput-root:after': {
          borderBottom: readOnlyProp ? 'none' : 'auto',
        },
      }}
      allowEmptyFormatting
      onKeyDown={handleKeyDown}
      error={isViewMode ? false : !!errorMessage}
      helperText={isViewMode ? null : errorMessage}
      label={label}
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      required={requiredProp}
      autoComplete="new-password"
      customInput={TextField}
    />
  );
}

export default memo(FormDateTime);
