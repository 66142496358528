// socket url used for web sockets
let possibleUrl = '';

switch (import.meta.env.REACT_APP_ENVIRONMENT) {
  case 'dev':
    possibleUrl = 'https://api-dev.corneaconnect.com';
    break;
  case 'uat':
    possibleUrl = 'https://api-uat.corneaconnect.com';
    break;
  case 'tst':
    possibleUrl = 'https://api-tst.corneaconnect.com';
    break;
  case 'prd':
    possibleUrl = 'https://api.corneaconnect.com';
    break;
  case 'test':
    break;
  default:
    const localUrl = new URL(window.location.origin);
    localUrl.port = '2017';
    possibleUrl = localUrl.origin;
    break;
}

const url = possibleUrl;
export default url;
