import * as Yup from 'yup';

const validation = Yup.object().shape({
  kins: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        mainPhone: Yup.string()
          .nullable()
          .length(10, 'Phone number must be 10 digits'),
        otherPhone: Yup.string()
          .nullable()
          .length(10, 'Phone number must be 10 digits'),
        address: Yup.object().shape({
          postalCode: Yup.string()
            .nullable()
            .test(
              'postalCode',
              'Zip code must be 5 or 9 digits',
              (value) => !value || value?.length === 5 || value?.length === 9,
            ),
        }),
      }),
    ),
});

export default validation;
