import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

class AccessLogService {
  logAccessEvent = async (caseId, resource) => {
    try {
      const request = await axios.post('/public/accessLogs', {
        caseId,
        accessDate: new Date(),
        resource,
        description: null,
      });
      if (request.status === 200) {
        return request.data.logItem;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}

const accessLogService = new AccessLogService();

export default accessLogService;
