/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  OPEN_CASE_TASKS_LIST,
  CLOSE_CASE_TASKS_LIST,
} from 'src/actions/caseTasksActions';

const initialState = {
  open: false,
};

const caseTasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CASE_TASKS_LIST: {
      return produce(state, (draft) => {
        draft.open = true;
      });
    }
    case CLOSE_CASE_TASKS_LIST: {
      return produce(state, (draft) => {
        draft.open = false;
      });
    }
    default: {
      return state;
    }
  }
};

export default caseTasksReducer;
