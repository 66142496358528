import useOnline from 'src/hooks/useOnline';
import OfflineDialog from './OfflineDialog';

function OnlineDetection() {
  const isOnline = useOnline();

  if (!isOnline) {
    return <OfflineDialog />;
  }

  return null;
}

export default OnlineDetection;
