import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip, SvgIcon, Box, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  button: {
    width: '50px',
    height: '50px',
    minWidth: 'unset',
    color: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));

function LinkButton({ to, icon, toolTip, hidden, fontSize, ...rest }) {
  const classes = useStyles();

  if (hidden) {
    return null;
  }

  return (
    <Tooltip title={toolTip}>
      <Box {...rest}>
        <Button
          component={Link}
          to={to}
          className={classes.button}
        >
          <SvgIcon fontSize={fontSize || 'small'}>{icon}</SvgIcon>
        </Button>
      </Box>
    </Tooltip>
  );
}

LinkButton.propTypes = {
  to: PropTypes.string,
  toolTip: PropTypes.string,
  icon: PropTypes.node,
  hidden: PropTypes.bool,
};

export default LinkButton;
