import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard() {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    if (account.error) {
      return <Navigate to={`/login?error_code=${account.error}`} />;
    }

    return <Navigate to="/login" />;
  }

  return <Outlet />;
}

AuthGuard.propTypes = {
  children: PropTypes.any,
};

export default AuthGuard;
